import React from 'react';
import cx from 'classnames';

import Select, { Props } from 'react-select';

import { InputFloatingLabel } from '~/components/InputFloatingLabel';

import styles from './styles.module.css';

export type DropdownOption = {
  label: string;
  value: string;
};

export interface DropdownProps extends Props<DropdownOption> {
  className?: string;
  options: DropdownOption[];
}

export const Dropdown = React.forwardRef<any, DropdownProps>(
  ({ className, options, placeholder, ...props }, ref) => {
    const active = React.useMemo(
      () => !!props.defaultValue || !!props.value,
      [props.defaultValue, props.value],
    );
    const labelId = React.useRef<string>(Math.random().toString());

    return (
      <InputFloatingLabel
        id={labelId.current}
        active={active}
        label={placeholder}
      >
        <Select
          ref={ref}
          classNamePrefix="react-select"
          className={cx(styles.select, 'text-sm', className)}
          placeholder=""
          styles={{
            control: (styles) => ({
              ...styles,
              ...{
                maxHeight: 32,
                height: 32,
                minHeight: 32,
              },
            }),
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: 'var(--theme-primary)',
              primary25: 'rgba(var(--theme-primary-rgb), 0.5)',
              primary50: 'rgba(var(--theme-primary-rgb), 0.5)',
              primary75: 'rgba(var(--theme-primary-rgb), 0.75)',
            },
          })}
          options={options}
          {...props}
        />
      </InputFloatingLabel>
    );
  },
);

export default Dropdown;
