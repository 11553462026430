import Chooser from '~/components/Chooser';

import Col from '~/components/Col';
import { WidgetInputType } from '~/widgets/commonTypes';

import { ChildCol } from '~/models/SNBuilderType';
import { WrapperHideWidget } from '~/widgets/WrapperHideWidget';

export type ColWidgetProps = {
  data: ChildCol;
  widgetFieldProps: WidgetInputType;
};

export const ColWidget = ({ data, widgetFieldProps }: ColWidgetProps) => {
  return (
    <WrapperHideWidget {...data} {...widgetFieldProps}>
      <Col size={data.size} {...data.props}>
        {data.children?.map((child, idx) => (
          <Chooser
            widgetFieldProps={widgetFieldProps}
            key={`child-col-${idx}`}
            data={child}
          />
        ))}
      </Col>
    </WrapperHideWidget>
  );
};
