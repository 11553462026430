import React from 'react';
import cx from 'classnames';
import If from '~/components/If';
import { Icon } from '@iconify/react';

export type ValueWithLabelProps = {
  label?: string;
  value: string | boolean | number | React.ReactNode;
  alignment?: 'left' | 'right';
  className?: string;
  clipboard?: boolean;
};

export const ValueWithLabel = ({
  label,
  value,
  className,
  alignment,
  clipboard,
}: ValueWithLabelProps) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(value as string);
    alert(`Value copied: ${value}`);
  };

  return (
    <div
      className={cx(
        'flex flex-col', //
        { 'items-start': alignment === 'left' },
        { 'items-end': alignment === 'right' },
        className,
      )}
    >
      <label
        className={cx(
          'text-xs text-gray-800', // Fonts
        )}
      >
        {label}
      </label>
      <span
        className={cx(
          'text-base text-black', // Fonts
        )}
      >
        {value}

        <If condition={clipboard}>
          <button
            title="Copy to clipboard"
            className="ml-2"
            type="button"
            onClick={handleCopy}
          >
            <Icon icon="akar-icons:copy" />
          </button>
        </If>
      </span>
    </div>
  );
};

ValueWithLabel.defaultProps = {
  alignment: 'left',
} as ValueWithLabelProps;
