const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatCurrency = (value: number | string) => {
  if (value === '') {
    return value;
  }

  try {
    const parsedValue = typeof value === 'string' ? parseInt(value) : value;

    return formatter.format(parsedValue);
  } catch (error) {
    return '';
  }
};
