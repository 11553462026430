import React from 'react';

import cx from 'classnames';
import PlotUi, { PlotParams } from 'react-plotly.js';
import { useRefDimensions } from '~/hooks/useRefDefinitions';
import If from '../If';
import { Loader } from '../Loader';

export type Plot3DProps = {
  data?: any[];
  layout?: PlotParams['layout'];
  className?: string;
  width?: number;
  height?: number;
  isLoading?: boolean;
};

const DEFAULT_HEIGHT = 800;

export const parseData3DPlot = (
  result: { [key: string]: string | number | null }[] = [],
  x_title: string,
  y_title: string,
  z_title: string,

  titleKey?: string,
) => {
  const x: any[] = [];
  const y: any[] = [];
  const z: any[] = [];
  let text = '';

  result.forEach((item: any, idx) => {
    x.push(item[x_title]);
    y.push(item[y_title]);
    z.push(item[z_title]);
    // @ts-ignore
    text = item[titleKey];
  });

  // console.log('RETURN', [{ x, y, z, text }]);
  return [{ x, y, z, text }];
};

const DATA_DEFAULT_PROPS: any = {
  type: 'scatter3d',
  mode: 'markers',
  contours: {
    x: { show: true },
    y: { show: true },
  },
  marker: {
    autocolorscale: false,
    colorscale: 'heatmap',
  },
  lighting: {
    specular: 0.3,
    roughness: 0.6,
    fresnel: 2,
    diffuse: 2,
  },
  showscale: false,
  opacity: 1,
  connectgaps: true,
  zsmooth: 'best',
  zhoverformat: '.2%',
  yhoverformat: '%m/%d/%y',
};

export const Plot3D = ({
  data,
  layout,
  className,
  width,
  height,
  isLoading,
  ...props
}: Plot3DProps) => {
  const { ref, width: fullWidth } = useRefDimensions({});

  const layoutDefault: PlotParams['layout'] = {
    scene: {
      aspectmode: 'manual',
      aspectratio: {
        x: 2.8,
        y: 1.8,
        z: 1.4,
      },
      xaxis: {
        title: { text: 'Strike or %Moneyness', standoff: 40 },
        ticks: 'outside',
        nticks: 20,
      },
      yaxis: {
        title: { text: 'Tenor', standoff: 40 },
        ticks: 'outside',
        nticks: 10,
      },
      zaxis: {
        title: 'Implied Volatility',
        tickformat: '.0%',
        ticks: 'outside',
        nticks: 20,
      },
      camera: {
        center: {
          x: 0,
          y: 0,
          z: 0,
        },
        eye: {
          x: 0.1,
          y: -3.4,
          z: 0.9,
        },
        up: {
          x: 0,
          y: 0,
          z: 1,
        },
      },
    },
    autosize: true,
    width: width || fullWidth,
    height: height || DEFAULT_HEIGHT,
    margin: { l: 5, r: 5, b: 5, t: 5 },
    showlegend: false,
  };

  let x = (data || []).map((dataItem) => ({
    ...DATA_DEFAULT_PROPS,
    ...dataItem,
    marker: {
      color: dataItem['z'],
      autocolorscale: false,
      colorscale: [
        ['0.0', 'rgb(0,158,92)'],
        ['0.111111111111', 'rgb(0,158,92)'],
        ['0.222222222222', 'rgb(0,158,92)'],
        ['0.333333333333', 'rgb(255,207,0)'],
        ['0.444444444444', 'rgb(255,207,0)'],
        ['0.555555555556', 'rgb(255,207,0)'],
        ['0.666666666667', 'rgb(255,207,0)'],
        ['0.777777777778', 'rgb(255,38,0)'],
        ['0.888888888889', 'rgb(255,38,0)'],
        ['1.0', 'rgb(255,38,0)'],
      ],
      line: {
        color: 'rgb(0, 0, 0)',
        width: 2,
      },
      size: 5,
    },
  }));

  // let dataPlot = x; //{ ...x, markers: { color: x[0]['z'] } };
  // let dataPlot2 = x;
  // let y = JSON.parse(JSON.stringify(x).replace('scatter3d', 'mesh3d').replace());
  // const dataPlot = x.concat(y);

  const dataPlot = x;
  // console.log('DATAPLOT', dataPlot, typeof dataPlot);

  return (
    <div className={cx('relative', className)} ref={ref}>
      <If condition={isLoading}>
        <div className="absolute left-8">
          <Loader />
        </div>
      </If>
      <PlotUi
        layout={{ ...layoutDefault, ...layout }}
        data={dataPlot}
        {...props}
      />
    </div>
  );
};
