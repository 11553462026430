import React from 'react';
import cx from 'classnames';
import { useForm } from 'react-hook-form';

import { Button } from '~/components';
import { useAuth } from '~/providers/AuthProvider/useAuth';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import { BASE_DOMAIN_URL } from '~/config/variables';

import styles from './styles.module.css';
import { DisentFooter } from './DisentFooter';
import { DisentLogo } from './DisentLogo';
import If from '~/components/If';
import { fetchApiByMethod } from '~/hooks/useFnProps/utils';
import { findLastIndex } from 'lodash';

type FormValues = {
  username: string;
  password: string;
};

type RegistrationFormValues = {
  first_name: string;
  last_name: string;
  company: string;
  job_title: string;
  phone: string;
  email: string;
  message: string;
};

export const FormLogin = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<FormValues>({});
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { signIn } = useAuth();

  const onSubmit = async ({ username, password }: FormValues) => {
    const redirectPath = searchParams?.get('redirectTo');

    await signIn?.(username, password, () => navigate(redirectPath || '/sn-builder'));
  };


  
  const filledOut = !!watch('password') && !!watch('username');



  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset className="text-xl mb-12">
        <If condition={watch('username')}>
          <label className="text-sm text-gray-600" htmlFor="username">
            Username
          </label>
        </If>

        <input
          {...register('username', { required: true })}
          className={cx(styles.input)}
          name="username"
          placeholder="Enter your Username"
          id="username"
        />
      </fieldset>

      <fieldset className="text-xl">
        <If condition={watch('password')}>
          <label className="text-sm text-gray-600" htmlFor="password">
            Password
          </label>
        </If>

        <input
          {...register('password', { required: true })}
          name="password"
          className={cx(styles.input)}
          placeholder="Enter your Password"
          id="password"
          type="password"
        />
      </fieldset>

      <div className="flex justify-end mt-4">
        <a className={cx(styles.forgotPassword)} href="/">
          Forgot your password?
        </a>
      </div>

      <Button
        disabled={!filledOut || isSubmitting}
        className="mt-10 self-end w-full py-2 font-black"
        type="submit"
      >
        {isSubmitting ? 'LOGGING...' : 'LOGIN'}
      </Button>
    </form>
  );
};

export const FormRegister = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<RegistrationFormValues>({});

  const [searchParams] = useSearchParams();
  
  const [regResponse, setRegResponse] = React.useState<RegState>("not submitted");

  const onRegistrationSubmit = async ( formVals: RegistrationFormValues) => {
   //find call snpricer_register
    
    console.log("registrationsubmit")
    
    const resp = await fetchApiByMethod("post","/snpricer_register", {bodyParams: formVals} )
    
    if (resp.data?.error){setRegResponse("response error")}
    else{setRegResponse("response good")}

    console.log(resp.data)
  };

  const regButtonLabel = (isSubmitting:boolean,regResponse:RegState) =>{

    if (isSubmitting){
      return "REGISTERING..."
    }

    else if (regResponse === "response good"){
      return "REGISTRATION SUBMITTED."
    }

    else if (regResponse === "response error") {
      return "REGISTRATION FAILED."
    }

    else {
      return "REGISTER"
    }

  }

  const filledOut = !!watch('first_name') && !!watch('last_name') && !!watch('email');
  return (


    <form id = "register-form" action = "/snpricer_register" method = "post" onSubmit={handleSubmit(onRegistrationSubmit)}>
      
      <div className = {cx(styles.sidebyside)}>
      
      <fieldset className="text-xl mb-12">
  

        <input
          {...register('first_name', { required: true })}
          className={cx(styles.input)}
          name="first_name"
          placeholder="First Name (Required)"
          id="first_name"
        />
      </fieldset>
      
      <fieldset className="text-xl mb-12">

        <input
          {...register('last_name', { required: true })}
          className={cx(styles.input)}
          name="last_name"
          placeholder="Last Name (Required)"
          id="last_name"
        />

        
      </fieldset>
      </div>

      <div className = {cx(styles.sidebyside)}> 
      <fieldset className="text-xl mb-12">

        <input
          {...register('company', { required: false })}
          className={cx(styles.input)}
          name="company"
          placeholder="Company"
          id="company"
        />
      </fieldset>

      <fieldset className="text-xl mb-12">

        <input
          {...register('job_title', { required: false })}
          className={cx(styles.input)}
          name="job_title"
          placeholder="Job Title"
          id="job_title"
        />
      </fieldset>
      
      </div>
      <div className = {cx(styles.sidebyside)}>
      <fieldset className="text-xl mb-12">


        <input
          {...register('phone', { required: false })}
          className={cx(styles.input)}
          name="phone"
          placeholder="Phone"
          id="phone"
        />
      </fieldset>

      <fieldset className="text-xl mb-12">


        <input
          {...register('email', { required: true })}
          className={cx(styles.input)}
          name="email"
          placeholder="Email (Required)"
          id="email"
        />
      </fieldset>
      </div>
      <fieldset className="text-xl mb-12">


        <input
          {...register('message', { required: false })}
          className={cx(styles.input)}
          name="message"
          placeholder="Additional Message"
          id="message"
        />
      </fieldset>

      <Button
        disabled={!filledOut || isSubmitting|| regResponse==="response good"}
        className="mt-10 self-end w-full py-2 font-black"
        type="submit"
      >
        {regButtonLabel(isSubmitting,regResponse)}
      </Button>
    </form>
  );
};

type TabId = 'login' | 'register';

type RegState = 'not submitted'|"response good"|"response error";

type TabsProps = {
  className?: string;
  active: TabId;
  onChange: (tab: TabId) => void;
};

const Tabs = ({ className, active, onChange }: TabsProps) => {
  return (
    <div className={cx(styles.tabs, className)}>
      <button
        className={cx(styles.tab, {
          [styles['tab--active']]: active === 'login',
        })}
        onClick={() => onChange('login')}
      >
        <h3>LOGIN</h3>
      </button>
      <button
        className={cx(styles.tab, {
          [styles['tab--active']]: active === 'register',
        })}
        onClick={() => onChange('register')}
      >
        <h3>REGISTER</h3>
      </button>
    </div>
  );
};

export const LoginPage = () => {
  const [active, setActive] = React.useState<TabId>('login');

  const [regResponse, setregResponse] = React.useState<RegState>("not submitted");

  return (
    <div className="flex flex-col min-h-screen w-full justify-evenly items-center bg-black font-poppins">
      <div className={cx(styles.loginCard)}>
        <DisentLogo className={styles.logo} />

        <Tabs
          className="mb-5"
          active={active}
          onChange={(tab) => setActive(tab)}
        />
        <If condition={active === "login"}>
          <FormLogin />
        </If>
        <If condition={active === "register"}>
          <FormRegister />
        </If>
      </div>

      <DisentFooter className="mt-12" />
    </div>
  );
};
