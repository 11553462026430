import { PlotParams } from 'react-plotly.js';

export type StateProps = {
  xs?: string[] | undefined;
  ys?: string[] | undefined;
  ys2: string[] | undefined;
  axisKeys?: { [key: string]: string[] };
  visibility?: 'visible' | 'hidden' | 'collapse';
  coupon_ys: any[];
  extraY: any[];
  initial_flag?: boolean;
  lineColor: string;
  queuedFetch: any;
  fetchOutstandingCt: number;
  fetchTime?: number | Date;
  inListener?: boolean;
  url?: string;
  plotData?: PlotParams['data'];
  plotLayout?: PlotParams['layout'];
};

export const INITIAL_STATE: StateProps = {
  xs: [],
  ys: [],
  ys2: [],
  coupon_ys: [],
  extraY: [],
  initial_flag: false,
  lineColor: 'rgb(0, 123, 255)',
  visibility: 'hidden',
  queuedFetch: null,
  fetchOutstandingCt: 0,
  // fetchTime: null,
  inListener: false,
};
