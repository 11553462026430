import React from 'react';
import cx from 'classnames';
import { format } from 'date-fns';

type DisentFooterProps = {
  className?: string;
};

export const DisentFooter = ({ className }: DisentFooterProps) => {
  return (
    <div className={cx('flex flex-col items-center', className)}>
      <svg
        width='135'
        height='44'
        viewBox='0 0 135 44'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M19.1002 21.3886C16.8528 19.0145 14.2982 17.8302 11.4526 17.8302C8.17039 17.8302 5.44333 19.0748 3.27678 21.5695C1.09406 24.0477 0 27.1291 0 30.8246C0 34.6077 1.09406 37.7604 3.27678 40.288C5.47567 42.7662 8.24045 43.9999 11.5765 43.9999C14.379 43.9999 16.8851 42.8978 19.1002 40.6937V43.2871H24.7483V0.328857H19.1002V21.3886V21.3886ZM17.5265 36.7022C16.2438 38.1606 14.5623 38.8899 12.4765 38.8899C10.5256 38.8899 8.90874 38.1332 7.62605 36.6254C6.3757 35.1341 5.75053 33.1986 5.75053 30.8246C5.75053 28.604 6.39187 26.7398 7.67456 25.2266C8.97341 23.7352 10.5902 22.9895 12.5251 22.9895C14.573 22.9895 16.2438 23.7297 17.5265 25.2046C18.8091 26.7124 19.4505 28.6314 19.4505 30.9507C19.4505 33.2754 18.8091 35.1944 17.5265 36.7022Z'
          fill='white'
        />
        <path
          d='M55.8022 27.6501C53.6357 26.7181 52.5524 25.775 52.5524 24.8265C52.5524 24.2837 52.7356 23.8395 53.1021 23.4886C53.4686 23.1432 53.9105 22.9678 54.4279 22.9678C55.5112 22.9678 56.422 23.7299 57.155 25.2541L61.8061 22.7375C60.2916 19.4642 57.9742 17.8303 54.8537 17.8303C52.7356 17.8303 50.9356 18.5321 49.4535 19.9412C48.0037 21.3503 47.2762 23.1268 47.2762 25.2815C47.2762 26.91 47.7019 28.2423 48.5534 29.2731C49.2217 30.0845 50.3697 30.9015 52.0027 31.7129C52.5847 32.0035 53.1614 32.2832 53.7273 32.5518C54.2932 32.826 54.8591 33.1056 55.425 33.3907C56.8909 34.2734 57.6239 35.222 57.6239 36.2418C57.6239 38.0073 56.6753 38.8845 54.7729 38.8845C53.8243 38.8845 53.0051 38.561 52.3207 37.9196C51.9703 37.5961 51.4637 36.8449 50.7955 35.6551L45.9935 37.9196C47.6911 41.9714 50.5853 44 54.6705 44C57.0526 44 59.0844 43.2379 60.7713 41.7137C62.5067 40.1017 63.369 38.1005 63.369 35.71C63.369 33.8458 62.8516 32.3599 61.8168 31.2579C60.7929 30.1558 58.7826 28.9551 55.8022 27.6501V27.6501Z'
          fill='white'
        />
        <path
          d='M82.9973 37.8702C82.0811 38.5501 80.9224 38.89 79.5212 38.89C77.7211 38.89 76.2444 38.2979 75.0964 37.1081C73.9485 35.9019 73.2963 34.2844 73.1454 32.2503H90.5964L90.6234 30.9015C90.6234 26.8167 89.5886 23.6202 87.5245 21.3119C85.4388 18.9872 82.6093 17.8303 79.0253 17.8303C75.4737 17.8303 72.6227 19.0256 70.4777 21.4161C68.3435 23.8396 67.2763 27.0141 67.2763 30.9289C67.2763 34.882 68.3758 38.0511 70.5747 40.4417C72.7736 42.8158 75.6893 44 79.3271 44C80.5936 44 81.7685 43.863 82.8518 43.5943C83.9351 43.3202 84.9429 42.8925 85.8753 42.3113C86.8077 41.7247 87.6861 40.979 88.4999 40.0743C89.3138 39.1696 90.0898 38.0786 90.8228 36.8065L86.0747 34.109C84.9267 35.9402 83.8974 37.1958 82.9973 37.8702V37.8702ZM74.3257 25.6653C74.676 25.1061 75.0964 24.6291 75.5869 24.2288C76.0773 23.8286 76.6216 23.527 77.2252 23.3132C77.8235 23.0994 78.4648 22.9952 79.1493 22.9952C82.1512 22.9952 84.0429 24.5194 84.8244 27.5734H73.5227C73.7059 26.8606 73.9754 26.2246 74.3257 25.6653V25.6653Z'
          fill='white'
        />
        <path
          d='M109.05 17.8303C106.582 17.8303 104.366 18.8227 102.399 20.8075V18.5212H96.7512V43.2873H102.399V31.3346C102.399 28.2478 102.782 26.0875 103.547 24.8484C104.329 23.5928 105.655 22.9678 107.525 22.9678C109.239 22.9678 110.424 23.4448 111.076 24.3933C111.745 25.3254 112.079 27.0141 112.079 29.454V43.2873H117.727V28.1052C117.727 24.6126 117.042 22.096 115.679 20.5498C114.089 18.7405 111.885 17.8303 109.05 17.8303V17.8303Z'
          fill='white'
        />
        <path
          d='M130.624 18.5157V9.43604H124.997V18.5157H122.599V23.856H124.997V43.2873H130.624V23.856H135V18.5157H130.624V18.5157Z'
          fill='white'
        />
        <path
          d='M37.9686 0C31.9486 0 27.055 4.97844 27.055 11.1028C27.055 16.1416 30.3695 20.4017 34.8966 21.756V44H40.5555V21.8876C45.3306 20.7033 48.8822 16.317 48.8822 11.1028C48.8822 4.98393 43.9832 0 37.9686 0V0ZM37.9686 16.4486C35.0691 16.4486 32.7139 14.0526 32.7139 11.1028C32.7139 8.15302 35.0691 5.75701 37.9686 5.75701C40.8681 5.75701 43.2233 8.15302 43.2233 11.1028C43.2233 14.0526 40.8627 16.4486 37.9686 16.4486Z'
          fill='white'
        />
      </svg>

      <p className='text-white mt-6'>
        © {format(new Date(), 'yyyy')} Disent Corp. All rights reserved.
      </p>
    </div>
  );
};
