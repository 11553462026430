/* eslint-disable eqeqeq */
import { off } from 'process';
import { TypeElement } from '~/components/Chooser/getComponent';
import { Condition, ConditionOperators } from './types';

const getValueByKey = (
  key: string,
  currentStore: {
    value: {
      [key: string]: { typeElement: TypeElement; currentValue: any };
    };
  },
) => {
  const { typeElement, currentValue } = currentStore?.value?.[key] || {};



  if (!currentValue) {
    return currentValue;
  }

  // TODO: Make to others elements

  switch (typeElement) {
    case 'button-bar':
    case 'input':
      return currentValue;
    case 'dropdown':
      return currentValue?.value;
      //this corresponds to tab
    case undefined:
      if (typeof(currentValue)!=="undefined")
        return currentValue;
      else return null;

    default:
      return null;
  }
};

const validationByOperador = (
  value1: any,
  operator: ConditionOperators,
  value2: any,
) => {
  try {
    switch (operator) {
      case '!=':
        return value1 != value2;
      case '==':
        return value1 == value2;
      case '<':
        return value1 < value2;
      case '<=':
        return value1 <= value2;
      case '>':
        return value1 > value2;
      case '>=':
        return value1 >= value2;
      default:
        return false;
    }
  } catch (error) {
    return false;
  }
};

export const useConditionsToShow = ({
  currentStore,
  conditionsToShow,
}: {
  currentStore: any;
  conditionsToShow?: Condition[];
}): boolean => {
  if (!conditionsToShow?.length) {
    return true;
  }

  const results: boolean[] = [];

  conditionsToShow?.forEach((condition) => {
    const valueFound = getValueByKey(condition.key, currentStore);

    if (condition.value !== undefined) {
      results.push(
        validationByOperador(valueFound, condition.operator, condition.value),
      );
    } else if (condition.keyValue) {
      const keyValueFound = getValueByKey(condition.keyValue, currentStore);

      results.push(
        validationByOperador(keyValueFound, condition.operator, valueFound),
      );
    }
  });

  return results.filter(Boolean).length === results.length;
};
