import React from 'react';
import cx from 'classnames';
import { AgGridColumn, AgGridReact, AgGridColumnProps } from 'ag-grid-react';
import {
  ValueFormatterParams,
  // ValueParserParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';

import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { If } from '~/components/If';
import { Loader } from '~/components/Loader';

export type RowData = {
  [key: string]: any;
};

export type ColumnDef = Pick<
  AgGridColumnProps,
  | 'headerName'
  | 'field'
  | 'editable'
  | 'onCellValueChanged'
  | 'filter'
  | 'sortable'
  | 'floatingFilter'
  | 'minWidth'
  | 'flex'
> & {
  columnFormatter?: string;

};

export type EventCellValueChanged = {
  oldValue: string | number;
  newValue: string | number;
  data: { [key: string]: string | number };
  colDef: {
    field: string;
    headerName: string;
    sortable?: boolean;
    filter?: boolean;
    editable?: boolean;
  };
  node: {
    childIndex: number;
    firstChild: boolean;
    lastChild: boolean;
  };
};

export type GridProps = {
  className?: string;
  height?: string | number;
  width?: string | number;
  data?: RowData[];
  columnDefs: ColumnDef[];
  isLoading?: boolean;
  onCellValueChanged?: (event: EventCellValueChanged) => void;
  minColDefWidth?: number;
  autoSizeColumnsBasedLoadedData?: boolean;
};

type AvailableFormats = 'usd' | 'percent';

const FORMATS: { [key in AvailableFormats]: string } = {
  usd: 'USD',
  percent: 'Decimal percent',
};

export const Grid = ({
  className,
  height,
  width,
  columnDefs,
  data,
  isLoading,
  onCellValueChanged,
  minColDefWidth = 120,
  autoSizeColumnsBasedLoadedData = true,
}: GridProps) => {
  const gridRef = React.createRef<any>();

  const isFormat = (columnFormat: string, format: AvailableFormats) => {
    return columnFormat?.toLowerCase() === FORMATS[format].toLowerCase();
  };

  const getColAndFormat = (params: any) => {
    const columnFormatter = (params.colDef as any).columnFormatter;

    const format = params.data[columnFormatter];

    return { format, columnFormatter, colField: params.colDef.field };
  };

  function formatNumber(number: number) {
    return number
      .toFixed?.(2)
      .toString?.()
      .replace?.(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  function valueFormatter(params: ValueFormatterParams) {
    const { format } = getColAndFormat(params);

    if (isFormat(format, 'usd')) {
      return '$' + formatNumber(params.value);
    }

    if (isFormat(format, 'percent')) {
      return `${params.value.toFixed(2)}%`;
    }

    return params.value;
  }

  // function valueParser(params: ValueParserParams) {
  //   const { format } = getColAndFormat(params);

  //   if (isFormat(format, 'usd')) {
  //     if (typeof params.newValue === 'number') {
  //       return params.newValue;
  //     }

  //     return Number(
  //       params.newValue?.replaceAll?.(',', '').replaceAll?.('$', '')
  //     );
  //   }

  //   if (isFormat(format, 'percent')) {
  //     if (typeof params.newValue === 'number') {
  //       return params.newValue / 100;
  //     }

  //     return parseFloat(params.newValue?.replaceAll?.('%', '')) / 100;
  //   }

  //   return params.newValue;
  // }

  const valueGetter = (params: ValueGetterParams) => {
    const { format, colField } = getColAndFormat(params);

    const value = params.data[colField];

    if (isFormat(format, 'percent')) {
      return parseFloat((parseFloat(value) * 100).toFixed(2));
    }

    return value;
  };

  const valueSetter = (params: ValueSetterParams) => {
    const { newValue } = params;

    if (params.oldValue === newValue) {
      return false;
    }

    const { format, colField } = getColAndFormat(params);

    if (isFormat(format, 'usd')) {
      if (typeof newValue === 'number') {
        params.data[colField] = newValue;
        return true;
      }

      params.data[colField] = Number(
        newValue?.replaceAll?.(',', '').replaceAll?.('$', ''),
      );

      return true;
    }

    if (isFormat(format, 'percent')) {
      if (typeof newValue === 'number') {
        params.data[colField] = newValue / 100;
        return true;
      }

      params.data[colField] = parseFloat(newValue?.replaceAll?.('%', '')) / 100;

      return true;
    }

    params.data[colField] = newValue;
    return true;
  };

  const autoSizeAll = React.useCallback(
    (skipHeader = false) => {
      if (!gridRef.current) {
        return;
      }

      const allColumnIds: any[] = [];

      gridRef.current.columnApi.getAllColumns().forEach((column: any) => {
        allColumnIds.push(column.getId());
      });
      gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    },
    [gridRef],
  );

  React.useEffect(() => {
    if (autoSizeColumnsBasedLoadedData && data?.length) {
      setTimeout(() => autoSizeAll(false), 100);

    }
  }, [data, autoSizeColumnsBasedLoadedData, autoSizeAll]);

  return (
    <div
      className={cx('ag-theme-alpine relative', className)}
      style={{ height, width }}
    >
      <If condition={isLoading}>
        <div
          style={{ height, width }}
          className="absolute flex items-center justify-center z-10 pb-10"
        >
          <Loader size={120} />
        </div>
      </If>
      <AgGridReact
        ref={gridRef}
        enableRangeSelection
        rowData={data}
        rowHeight={24}
        // defaultColDef={{ flex: 1 }}
        rowStyle={{
          fontSize: '1em',
          fontFamily: ['Consolas', 'Andale Mono', 'monospace'],
        }}
      >
        {columnDefs.map((column, idx) => (
          <AgGridColumn
            key={`col-${idx}-${column.field}`}
            sortable
            filter="agTextColumnFilter"
            floatingFilter
            suppressMovable={false}
            lockPosition={false}
            valueFormatter={valueFormatter}
            // valueParser={valueParser}
            valueSetter={valueSetter}
            valueGetter={valueGetter}
            resizable
            onCellValueChanged={onCellValueChanged}
            {...column}
          />
        ))}
      </AgGridReact>
    </div>
  );
};

Grid.defaultProps = {
  height: 400,
  width: '100%',
};
