import React from 'react';
import cx from 'classnames';

import {
  Tabs as TabsUi,
  TabsProps as TabsUiProps,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import If from '~/components/If';
import { Loader } from '~/components/Loader';

type TabProps = {
  title: string;
  id: string;
  content: React.ReactNode;
  isLoading?: boolean;
};

export type TabsProps = Pick<
  TabsUiProps,
  'onSelect' | 'defaultIndex' | 'selectedIndex'
> & {
  tabs: TabProps[];
  className?: string;
  tabIdSelected?: string;
};

export const Tabs = ({
  tabs,
  className,
  tabIdSelected,
  ...props
}: TabsProps) => {
  return (
    <TabsUi forceRenderTabPanel className={cx('w-full', className)} {...props}>
      <TabList>
        {tabs.map((tab, tabIdx) => {
          return (
            <Tab key={`tab-${tab.title}-${tabIdx}`}>
              {tab.title}
              <If condition={tab.isLoading}>
                <Loader className="inline ml-3" />
              </If>
            </Tab>
          );
        })}
      </TabList>

      {tabs.map((tab, tabIdx) => {
        return (
          <TabPanel key={`tab-panel-${tab.title}-${tabIdx}`}>
            {tab.content}
          </TabPanel>
        );
      })}
    </TabsUi>
  );
};

Tabs.defaultProps = {
  tabs: [],
} as TabsProps;
