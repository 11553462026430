import React from 'react';

import { Button } from '~/components';

import { ChildButton } from 'src/models/SNBuilderType';
import type { WidgetInputType } from '~/widgets/commonTypes';

import { useFnProps } from '~/hooks/useFnProps';
import { WrapperHideWidget } from '~/widgets/WrapperHideWidget';

type ButtonWidgetProps = {
  data: ChildButton;
  widgetFieldProps: WidgetInputType;
};

type StateProps = {
  disabled?: boolean;
};

const ButtonWidget = ({ data, widgetFieldProps }: ButtonWidgetProps) => {
  const field = React.useMemo(() => data.field, [data.field]);

  const [state, setState] = React.useState<StateProps>({
    disabled: field.disabledDefault,
  });

  const { currentStore, sendValue } = widgetFieldProps;

  const { executeOn } = useFnProps({
    currentStore,
    sendValue,
    data,
    setState,
    state,
  });

  React.useEffect(() => {
    executeOn('onMount');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    executeOn('onClick');
  };

  return (
    <WrapperHideWidget {...widgetFieldProps} {...data}>
      <Button
        type="button"
        disabled={state.disabled}
        onClick={handleClick}
        {...data.props}
      >
        {field.defaultValue}
      </Button>
    </WrapperHideWidget>
  );
};

export default ButtonWidget;
