import Routes from '~/routes';
import { HeaderProvider } from './providers/HeaderProvider';

const App: React.FC = () => {
  return (
    <>
      <HeaderProvider>
        <Routes />
      </HeaderProvider>
    </>
  );
};

export default App;
