// import path from 'path';
// import dotenv from 'dotenv';

// let envPath = `.env.${process.env.NODE_ENV}`;

// dotenv.config({
//   path: path.resolve(process.cwd(), envPath),
// });

type TypeEnvs = 'local' | 'production' | 'staging';

// export const NODE_ENV: TypeEnvs = 'staging';
export const NODE_ENV: TypeEnvs = 'production';

type EnvKey = { [key in TypeEnvs]: string };

const BASE_URLS: EnvKey = {
  local: 'http://localhost:8000',
  // local: 'http://10.0.100.32:8000',
  production: '/',
  staging: 'https://snpricer-dev2.disent.com',
};

const API_KEYS: EnvKey = {
  local: 'VnN48GFs.qc4n9cGmqdu41mWxGKOS3uzloU4gxDBo',
  production: 'AUl0LgTa.k7Qk6pLqbDgmo5KubcgONOQ0i6D2j2TE',
  staging: 'VnN48GFs.qc4n9cGmqdu41mWxGKOS3uzloU4gxDBo',
};

const BASE_DOMAIN_URLS: EnvKey = {
  local: '',
  production: '',
  staging: '',
};

const BASE_URL = BASE_URLS[NODE_ENV];

const BASE_DOMAIN_URL = BASE_DOMAIN_URLS[NODE_ENV];

const API_KEY =
  window.location.origin === 'https://snpricer.disent.com'
    ? API_KEYS.production
    : API_KEYS.local;

console.log(
  'REACT_APP_API_ENDPOINT::',
  process.env.NODE_ENV,
  BASE_URL,
  API_KEY,
  BASE_DOMAIN_URL,
);

export { BASE_URL, API_KEY, BASE_DOMAIN_URL };
