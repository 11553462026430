import React from 'react';

import { format } from 'date-fns';
import { ElementType } from '~/models/SNBuilderType';
import { compareLists, compareObjects } from '~/utils/compare';

type ListenedParamsState = {
  [key: string]: any;
};

export type UseListenedParamsProps = {
  currentStore: {
    value: any;
  };
  listenedKeys?: string[];
};

export const useListenedParams = ({
  currentStore,
  listenedKeys,
}: UseListenedParamsProps): { listenedParams: ListenedParamsState } => {
  const [listenedParams, setListenedParams] =
    React.useState<ListenedParamsState>({});

  const currentValue = React.useMemo(
    () => currentStore.value,
    [currentStore.value],
  );

  React.useEffect(() => {
    const keysInStore = (currentValue ? Object.keys(currentValue) : []).filter(
      (key) => listenedKeys?.includes(key),
    );

    for (let index = 0; index < keysInStore.length; index++) {
      const key = keysInStore[index];
      const objInStore = currentValue[key];

      if (!objInStore || objInStore.disabled) {
        continue;
      }

      let paramValue: any = null;

      switch (objInStore.typeElement as ElementType) {
        case 'date':
          try {
            if (objInStore.currentValue) {
              paramValue = parseInt(
                format(objInStore.currentValue, 'yyyyMMdd'),
              );
            }
          } catch (error) {
            console.error('useListenedParams::date::objInStore::', objInStore);
          }
          break;
        case 'dropdown':
          paramValue = objInStore?.currentValue?.value;
          break;
        case 'dropdown-multi':
          paramValue = objInStore?.currentValue
            ?.map((option: { value: any }) => option.value)
            .join(',');
          break;
        case 'card-grid-values':
          const values: any[] = objInStore?.currentValue || [];
          let cardGridValuesParams: any = {};

          values
            .filter((item: any) => !!item.value)
            .forEach((item) => {
              cardGridValuesParams[item.key] = item.value;
            });

          const hasValues = !!Object.keys(cardGridValuesParams).length;

          if (!hasValues) {
            continue;
          }

          setListenedParams((prev) => {
            const currentKeyInRef: any = {};

            Object.keys(prev)
              .filter((keyInRef) =>
                cardGridValuesParams.hasOwnProperty(keyInRef),
              )
              .forEach((keyInRef) => {
                currentKeyInRef[keyInRef] = prev[keyInRef];
              });

            const isEquals = compareObjects(
              currentKeyInRef,
              cardGridValuesParams,
            );

            if (!isEquals) {
              return {
                ...cardGridValuesParams,
              };
            } else {
              return prev;
            }
          });

          continue;
        default:
          paramValue = objInStore.currentValue;
          break;
      }

      const isArray = Array.isArray(paramValue);

      setListenedParams((prev) => {
        const isEquals = isArray
          ? compareLists((prev[key] || []) as any, paramValue)
          : !!prev[key] && paramValue === prev[key];

        if (!isEquals) {
          if (
            !Boolean(paramValue) &&
            !['checkbox', 'dropdown'].includes(objInStore.typeElement)
          ) {
            if (prev?.hasOwnProperty?.(key)) {
              delete prev[key];

              return { ...prev };
            }

            return prev;
          }

          return {
            ...prev,
            [key]: paramValue,
          };
        } else {
          return prev;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue, listenedKeys]);

  return { listenedParams };
};
