import React from 'react';
import Swal from 'sweetalert2';

import { Child, FunctionType } from '~/models/SNBuilderType';
// import { ElementType } from '~/models/SNBuilderType';

import { dateUtils } from '~/utils/date';
import { getByPath } from '~/utils/path';
import { formatCurrency } from '~/utils/formatter';

import { api } from '~/api/api';
import { batchRequest } from '~/api/request';
import { executeFn } from '~/utils/fnBuilder';

import { NODE_ENV } from '~/config/variables';

import {
  getValuesInStore,
  removeKeysFromObjByValue,
  fetchApiByMethod,
  formatDecimalPercentValue,
  getPayloadIsLoadingFromKeys,
  parseResultToCardGridValues,
  setTitle,
  handleError,
  formatDropdownDateItem,
  getTimeByShortcut,
  getSamePayloadToDifferentKeys,
} from './utils';
import { TypeElement } from '~/components/Chooser/getComponent';
import { useEffectsHeader } from '~/providers/HeaderProvider';

import {
  UseFnResult,
  KeyValue,
  MethodType,
  PayloadStoreByTypedKeysParams,
  PayloadStoreByTypedKeysResult,
} from './types';
import {ITypeOpts} from "@testing-library/user-event";

export type GenericFetcherTypeValues = {
  queryParamsKeys?: string[];
  queryStaticParams?: any;

  bodyParamsKeys?: string[];
  bodyStaticParams?: any;

  mappedQueryKeys?: KeyValue<string>;
  mappedBodyKeys?: KeyValue<string>;

  removeKeysIfNotTrue?: string[];
  keysIsLoading?: string[];
  disableWhileLoading?: boolean;
  method?: MethodType;
  url: string;
};


export type FetcherBatchRequestProps = Omit<
  GenericFetcherTypeValues,
  'url' | 'method'
> & {
  batchType: 'Pricer'|'backtest';
};

export type UseFnProps = {
  currentStore: {
    value: { [key: string]: any };
  };
  setState?: React.Dispatch<React.SetStateAction<any>>;
  state?: any;
  data: Child;
  sendValue: (payload: KeyValue) => void;

  executeDelay?: number;
};

// export type UseFnResult = Omit<UseFnProps, 'currentStore' | 'executeDelay'> & {
//   fetchAndSetValues: (values: GenericFetcherTypeValues) => Promise<any>;
//   fetchBatchRequest: (values: FetcherBatchRequestProps) => Promise<any>;
//   dateUtils: {
//     addDays: typeof dateUtils.addDays;
//     addMonths: typeof dateUtils.addMonths;
//     addYears: typeof dateUtils.addYears;
//     format: typeof dateUtils.format;
//     convertToDate: typeof dateUtils.convertToDate;
//   };
//   getByPath: typeof getByPath;
//   store: {
//     [key: string]: {
//       currentValue: any;
//       choices?: { label: string; value: string }[];
//       typeElement?: ElementType;
//     };
//   };

//   downloadExcelFile: (fileId: string) => Promise<any>;
//   parseResultToCardGridValues: (
//     result: { [key: string]: string },
//     removeEmptyKeys?: boolean,
//   ) => any[];
//   executeOn: (lifeCycle: FunctionType['lifeCycle'], response?: any) => void;
//   setTitle: (title?: string) => void;
//   Swal: typeof Swal;
//   response?: any;
//   newValue?: any;
//   newList?: any[];
//   formatDecimalPercentValue: (value: number) => string;
//   formatCurrency: typeof formatCurrency;
//   getPayloadStoreByTypedKeys: (
//     value: PayloadStoreByTypedKeysParams,
//   ) => PayloadStoreByTypedKeysResult | null;
//   getDefaultValuesByKeys: (keys: string[], extraPayload?: any) => any;
//   formatDropdownDateItem: typeof formatDropdownDateItem;
//   setTitleOnHeader: (title: string) => void;
//   setDescriptionOnHeader: (description: string) => void;
//   setHeader: (title: string, desc: string) => void;
//   getTimeByShortcut: typeof getTimeByShortcut;
// };

export const useFnProps = ({
  currentStore,
  setState,
  state,
  data,
  sendValue,
  executeDelay,
}: UseFnProps): UseFnResult => {
  const fnProps = React.useMemo(() => {
    return {
      state,
      data,
      store: currentStore.value,
    };
  }, [currentStore, state, data]);

  const {
    setHeader,
    handleTitle: setTitleOnHeader,
    handleDescription: setDescriptionOnHeader,
  } = useEffectsHeader();

  const setDisable = (disabled: boolean) =>
    setState?.((prev: any) => ({ ...prev, disabled }));

  const fetchAndSetValues = async ({
    queryParamsKeys,
    queryStaticParams,

    bodyParamsKeys,
    bodyStaticParams,

    removeKeysIfNotTrue,

    mappedBodyKeys,
    mappedQueryKeys,
    disableWhileLoading,
    keysIsLoading,
    method,
    url,
  }: GenericFetcherTypeValues) => {
    try {
      if (disableWhileLoading) {
        setDisable(true);
      }

      if (keysIsLoading?.length) {
        sendValue(getPayloadIsLoadingFromKeys(keysIsLoading, true));
      }

      const queryParams = {
        ...getValuesInStore(
          queryParamsKeys,
          currentStore.value,
          mappedQueryKeys,
        ),
        ...queryStaticParams,
      };

      const bodyParams = {
        ...getValuesInStore(bodyParamsKeys, currentStore.value, mappedBodyKeys),
        ...bodyStaticParams,
      };

      removeKeysFromObjByValue(queryParams, undefined);
      removeKeysFromObjByValue(queryParams, false, removeKeysIfNotTrue);
      removeKeysFromObjByValue(bodyParams, undefined);
      removeKeysFromObjByValue(bodyParams, false, removeKeysIfNotTrue);

      const resp = await fetchApiByMethod(method || 'get', url, {
        bodyParams,
        queryParams,
      });

      if (NODE_ENV !== 'production') {
        console.log('fetchAndSetValues::resp.data', resp.data);
        console.log('fetchAndSetValues::currentStore', currentStore);
        console.log(
          'fetchAndSetValues::queryParamsKeys',
          queryParamsKeys,
          queryParams,
        );
        console.log(
          'fetchAndSetValues::bodyParamsKeys',
          bodyParamsKeys,
          bodyParams,
        );
      }

      if (keysIsLoading?.length) {
        sendValue(getPayloadIsLoadingFromKeys(keysIsLoading, false));
      }

      if (disableWhileLoading) {
        setDisable(false);
      }

      return resp?.data;
    } catch (error: any) {
      console.error('fetchAndSetValues:::error:', { error });

      handleError(error);

      throw error;
    } finally {
      if (keysIsLoading?.length) {
        sendValue(getPayloadIsLoadingFromKeys(keysIsLoading, false));
      }

      if (disableWhileLoading) {
        setDisable(false);
      }
    }
  };

  const fetchBatchRequest = async ({
    queryParamsKeys,
    queryStaticParams,

    bodyParamsKeys,
    bodyStaticParams,

    removeKeysIfNotTrue,

    mappedBodyKeys,
    mappedQueryKeys,
    disableWhileLoading,
    keysIsLoading,
    batchType,
  }: FetcherBatchRequestProps) => {
    try {
      if (disableWhileLoading) {
        setDisable(true);
      }
      if (keysIsLoading?.length) {
        sendValue(getPayloadIsLoadingFromKeys(keysIsLoading, true));
      }

      const queryParams = {
        ...getValuesInStore(
          queryParamsKeys,
          currentStore.value,
          mappedQueryKeys,
        ),
        ...queryStaticParams,
      };

      const bodyParams = {
        ...getValuesInStore(bodyParamsKeys, currentStore.value, mappedBodyKeys),
        ...bodyStaticParams,
      };
      

      removeKeysFromObjByValue(queryParams, undefined);
      removeKeysFromObjByValue(queryParams, false, removeKeysIfNotTrue);
      removeKeysFromObjByValue(bodyParams, undefined);
      removeKeysFromObjByValue(bodyParams, false, removeKeysIfNotTrue);


      const resp = await batchRequest({
        batch_type: batchType,
        params: queryParams,
        body: bodyParams,
      });
      
      if (NODE_ENV !== 'production') {
        console.log('fetchBatchRequest::resp.data', resp);
        console.log('fetchBatchRequest::currentStore', currentStore);
        console.log(
          'fetchBatchRequest::queryParamsKeys',
          queryParamsKeys,
          queryParams,
        );
        console.log(
          'fetchBatchRequest::bodyParamsKeys',
          bodyParamsKeys,
          bodyParams,
        );
      }

      return resp;
    } catch (error) {
      console.error('fetchBatchRequest::error::', { error });

      handleError(error);

      throw error;
    } finally {
      if (keysIsLoading?.length) {
        sendValue(getPayloadIsLoadingFromKeys(keysIsLoading, false));
      }

      if (disableWhileLoading) {
        setDisable(false);
      }
    }
  };

  const getPayloadStoreByTypedKeys = ({
    mappedKeys,
    key,
    value,
  }: PayloadStoreByTypedKeysParams): PayloadStoreByTypedKeysResult | null => {
    try {
      let payload: PayloadStoreByTypedKeysResult = {};

      if (mappedKeys?.dateKeys?.includes(key)) {
        const parsedValue = dateUtils.convertToDate(value);

        if (parsedValue) {
          payload = {
            currentValue: parsedValue,
            typeElement: 'date',
          };
        }
      } else if (mappedKeys?.dropdownKeys?.includes(key)) {
        const foundOption = currentStore.value?.[key]?.choices?.find(
          (item: any) => item.value === value,
        );

        const option = {
          value,
          label: value,
        };

        payload = {
          currentValue: foundOption || option,
          typeElement: 'dropdown',
        };
      } else if (mappedKeys?.dropdownMultiKeys?.includes(key)) {
        const options = value.split?.(',').map((item: any) => {
          return {
            value: item,
            label: item,
          };
        });

        payload = {
          currentValue: options,
          typeElement: 'dropdown-multi',
        };
      } else if (mappedKeys?.numberKeys?.includes(key)) {
        payload = {
          currentValue: Number(value),
          typeElement: 'input',
          type: 'number',
        };
      } else if (mappedKeys?.textKeys?.includes(key)) {
        payload = {
          currentValue: value,
          typeElement: 'input',
        };
      } else if (mappedKeys?.booleanKeys?.includes(key)) {
        let parsedValue;

        if (typeof value === 'boolean') {
          parsedValue = value;
        } else if (typeof value === 'string') {
          if (value.toLowerCase() === 'false') {
            parsedValue = false;
          } else if (value.toLowerCase() === 'true') {
            parsedValue = true;
          }
        }

        if (parsedValue) {
          payload = {
            currentValue: parsedValue,
            typeElement: 'checkbox',
          };
        }
      }

      if (!Object.keys(payload).length) {
        return null;
      }

      return payload;
    } catch (error) {
      console.error('getPayloadStoreByTypedKeys::error::', error);
      return null;
    }
  };

  const getDefaultValuesByKeys = (keys: string[], extraPayload?: any) => {
    let payload: any = {};

    keys.forEach((key) => {
      const keyInStore = currentStore?.value?.[key] as {
        typeElement: TypeElement;
      };

      if (keyInStore?.typeElement) {
        switch (keyInStore.typeElement) {
          case 'input':
          case 'dropdown':
          case 'dropdown-multi':
          case 'grid':
          case 'date':
            payload[key] = {
              ...extraPayload,
              currentValue: null,
            };
            break;
          case 'switch':
            payload[key] = {
              ...extraPayload,
              currentValue: undefined,
            };
            break;
          case 'checkbox':
            payload[key] = {
              ...extraPayload,
              currentValue: false,
            };
            break;
          default:
            break;
        }
      }
    });

    if (!Object.keys(payload).length) {
      return null;
    }

    return payload;
  };

  const downloadExcelFile = async (fileId: string) => {
    try {
      const resp = await api.get(`/api/files/filedownload?location=${fileId}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', fileId);
      document.body.appendChild(link);

      link.click();
    } catch (error) {
      console.error('downloadExcelFile::error::', error);
    }
  };

  const getPriceURIKeys = () => {
    const queryParamsKeys = [
      'DataStoreKey',
      'Trade',
      'Maturity',
      'Coupon',
      'Callable',
      'Underlying_Tickers',
      'Upside',
      'Downside',
      'Downside_Type',
      'DPS_Moneyness_Band',
      'MC_Rnd_Seed',
      'Num_Paths',
      'MaturityPayoffOverride',
      'DigSprWidth',
      'DigSprBarrShift',
      'Cash_Type',
      'Cash_Qty',
    ];

    const queryStaticParams: any = {
      Enable_ConstantRate_MBO_Spreading:
        currentStore.value['Activate_Mid/Bid/Offer_Mode']?.currentValue,
      Underlying_Currencies: 'USD',
    };

    // Upside
    if (currentStore.value?.['Upside']?.currentValue) {
      queryStaticParams['Upside_Cap'] = Boolean(
        currentStore.value?.['Upside_Cap_Factor']?.currentValue,
      );

      if (currentStore.value?.['Upside_Floor_Factor']?.currentValue) {
        queryStaticParams['Upside_Floor'] = true;
      }

      queryParamsKeys.push(
        'Upside_Leverage_Factor',
        'Upside_Attachment_Pct',
        'Upside_Cap_Factor',
        'Upside_Floor_Factor',
        'Digital_Attachment_Pct',
      );
    }

    // Coupon
    if (currentStore.value?.['Coupon']?.currentValue) {
      queryParamsKeys.push(
        'Coupon_rate_1',
        'Coupon_barrier_1',
        'Payment_frequency',
        'Coupon_pay_dates',
        'Snowball',
        'Coupon_Type',
      );
    }

    // Callable
    if (currentStore.value?.['Callable']?.currentValue) {
      if (currentStore.value?.['Call_Type']?.currentValue?.value === 'auto') {
        queryParamsKeys.push('Autocall_factor_1');
      }

      queryParamsKeys.push('Call_Frequency', 'Call_Pay_Date', 'Call_Type');
    }

    // Downside
    if (currentStore.value?.['Downside']?.currentValue) {
      queryParamsKeys.push('Downside_Pct', 'Downside_Barrier_Style');
    }

    return { queryParamsKeys, queryStaticParams };
  };

  const executeOn = (lifeCycle: FunctionType['lifeCycle'], payload?: any) => {
    executeFn(
      data.functions || [],
      {
        ...fnProps,
        sendValue,
        dateUtils,
        setState,
        fetchAndSetValues,
        getByPath,
        fetchBatchRequest,
        setTitle,
        Swal,
        downloadExcelFile,
        parseResultToCardGridValues,
        formatDecimalPercentValue,
        formatCurrency,
        getPayloadStoreByTypedKeys,
        getDefaultValuesByKeys,
        formatDropdownDateItem,
        setTitleOnHeader,
        setDescriptionOnHeader,
        setHeader,
        getTimeByShortcut,
        getPriceURIKeys,
        getSamePayloadToDifferentKeys,
        ...payload,
      },
      lifeCycle,
    );
  };

  return {
    ...fnProps,
    sendValue,
    dateUtils,
    setState,
    fetchAndSetValues,
    getByPath,
    fetchBatchRequest,
    executeOn,
    setTitle,
    Swal,
    downloadExcelFile,
    parseResultToCardGridValues,
    formatDecimalPercentValue,
    formatCurrency,
    getPayloadStoreByTypedKeys,
    getDefaultValuesByKeys,
    formatDropdownDateItem,
    setTitleOnHeader,
    setDescriptionOnHeader,
    setHeader,
    getPriceURIKeys,
    getTimeByShortcut,
    getSamePayloadToDifferentKeys,
  };
};

export default useFnProps;
