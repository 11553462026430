import React from 'react';

type KeyAndValue = { [key: string]: any };

type StoreType = {
  value: KeyAndValue;
};

export const useProviderFactory = () => {
  const [store, setStore] = React.useState<StoreType>({ value: {} });

  const setValue = React.useCallback(
    (payload: KeyAndValue) => {
      setStore((prevState) => {
        try {
          const newValueStore: any = {};

          const keys = Object.keys({ ...payload, ...prevState?.value });

          keys?.forEach((key) => {
            newValueStore[key] = {
              ...prevState?.value?.[key],
              ...(payload?.[key] || {}),
            };
          });

          return { ...prevState, value: newValueStore };
        } catch (error) {
          console.error(
            'error::useProviderFactory::setValue::payload::',
            payload,
            'prevState::',
            prevState,
          );
          return prevState;
        }
      });
    },
    [setStore],
  );

  return {
    state: { store },
    effects: { setValue },
  };
};
