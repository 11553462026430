import React from 'react';

import ReactTooltip, { TooltipProps as TooltipPropsUi } from 'react-tooltip';

export type TooltipProps = Pick<TooltipPropsUi, 'place' | 'effect'> & {
  content?: string | React.ReactNode;
};

const generateId = () => {
  return [Math.random(), '-', Math.random()].join();
};

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  ...props
}) => {
  const tooltipId = React.useRef(generateId());

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        'data-tip': true,
        'data-for': tooltipId.current,
      } as any);
    }
    return child;
  });

  if (!content) {
    return <>{children}</>;
  }

  return (
    <>
      {childrenWithProps}
      <ReactTooltip
        {...props}
        className="max-w-sm"
        multiline={true}
        id={tooltipId.current}
      >
        {content}
      </ReactTooltip>
    </>
  );
};

Tooltip.defaultProps = {
  place: 'top',
  effect: 'solid',
} as TooltipProps;

export default Tooltip;
