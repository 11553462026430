import { sleep } from '~/utils/tools';
import { api } from './api';

const DELAY = 500; // 0.5 seconds
const TIME_OUT = 1000 * 60 * 3; //  3 minutes

const MESSAGES = {
  timeout:
    'Your request cannot be completed. Please contact vendor for assistance.',
};

export type ResponseState<DataType, ErrorType> = {
  data: DataType | null;
  error?: ErrorType | null;
};

type ResponseStartBatchRequest = {
  Message: string;
  batch_id: string;
  task_ids: string[];
};

type ResponseGetTaskResult<R = any> = {
  message: string;
  status: 'Running' | 'Done';
  task_result?:
    | R & {
        Error?: any;
      };
};

async function onGetTaskResult<ResponseSuccess = any, ResponseError = any>(
  taskId: string,
  timeout: number,
): Promise<ResponseState<ResponseSuccess, ResponseError>> {
  try {
    console.log("here comes the data")
    const { data } = await api.get<ResponseGetTaskResult<ResponseSuccess>>(
      `/api/batch/taskresult?task_id=${taskId}`,
    );
    
    if (data.status !== 'Done') {
      const currentTime = new Date().getTime();

      if (currentTime >= timeout) {
        return {
          data: null,
          error: { Exception: MESSAGES.timeout } as any,
        };
      }

      await sleep(DELAY);
      return onGetTaskResult<ResponseSuccess, ResponseError>(taskId, timeout);
    }

    if (!data.task_result) {
      return {
        data: null,
      };
    }

    const hasError = !!data?.task_result.Error;
    console.log(data)
    return {
      
      data: hasError ? null : data.task_result || null,
      error: hasError && data.task_result.Error,
    };
  } catch (error) {
    return {
      data: null,
      error: error as ResponseError,
    };
  }
}

export type BatchRequestType = "Pricer"|'backtest';




const getTypeUrl = (url?: string): BatchRequestType => {
  switch (url) {
    case '/pricer':
      return "Pricer";
    case '/backtest':
      return "backtest";

    default:
      return "Pricer";
  }
};

export async function batchRequest<ResponseSuccess = any, ResponseError = any>({
  url,
  batch_type,
  params,
  body,

}: {
  batch_type: BatchRequestType;
  url?: string;
  params: {
    [key: string]: string | number | boolean;
  };
  body?: any;

}): Promise<ResponseState<ResponseSuccess, ResponseError>> {
  const startRequest = new Date().getTime();

  try {
    const keys = Object.keys(params || {});
    let x = "";
    let res_final: any = {};
    let uriValue = '';
    let batch_req_dict: any ={}
    if (batch_type === "Pricer"){ 
    keys.forEach((key, idx) => {
    uriValue = `${uriValue}${idx === 0 ? '' : '&'}${key}=${params[key]}`; 
    })  
    batch_req_dict = {
        Callback: false,
        Requests: [
          {
            Type: batch_type,
            
            URI: encodeURI(uriValue),
          },
        ],
        ...body,
      } ;}


    if (batch_type === "backtest"){


    let barrier_level_indicator: Boolean = (params.barrierLevel!==undefined);
    let knockout_level_indicator: Boolean = (params.knockoutLevel!==undefined);
    let call_level_indicator: Boolean = (params.callLevel !== undefined);
    let bump = Number(params["bump"]);


    let base_level = params["knockoutLevel"]||params["barrierLevel"]||params["callLevel"]||params["couponBarrier"];

    
    let urlPos = '';
    let urlNeg = '';
    keys.forEach((key, idx) => {if(key!=="bump"&&key!=="knockoutLevel"&&key!=="barrierLevel"&&key!=="callLevel"&&key!=="couponBarrier"){
      urlPos = `${urlPos}${idx === 0 ? '' : '&'}${key}=${params[key]}`; 
    }})
    
    if (barrier_level_indicator){urlPos = urlPos+`&barrierLevel=${Number(base_level)+bump}`}
    else if (knockout_level_indicator){urlPos = urlPos+`&knockoutLevel=${Number(base_level)+bump}`}
    else if(call_level_indicator){urlPos = urlPos+`&callLevel=${Number(base_level)+bump}`}
    else{urlPos = urlPos+`&couponBarrier=${Number(base_level)+bump}`};

    keys.forEach((key, idx) => {if(key!=="bump"&&key!=="knockoutLevel"&&key!=="barrierLevel"&&key!=="callLevel"&&key!=="couponBarrier"){
      urlNeg = `${urlNeg}${idx === 0 ? '' : '&'}${key}=${params[key]}`; 
    }})
    
    if (barrier_level_indicator){urlNeg = urlNeg+`&barrierLevel=${Number(base_level)-bump}`}
    else if (knockout_level_indicator){urlNeg = urlNeg+`&knockoutLevel=${Number(base_level)-bump}`}
    else if (call_level_indicator){urlNeg = urlNeg+`&callLevel=${Number(base_level)-bump}`}
    else {urlNeg = urlNeg+`&couponBarrier=${Number(base_level)-bump}`};
    keys.forEach((key, idx) => {if(key!=="bump"){
      uriValue = `${uriValue}${idx === 0 ? '' : '&'}${key}=${params[key]}`; 
    }})  

    let allSimpleRes = [encodeURI(uriValue), encodeURI(urlNeg), encodeURI(urlPos)]

    batch_req_dict["Requests"] = [];
    for (var idx in allSimpleRes) {
      batch_req_dict["Requests"].push({Type: "backtest", URI: allSimpleRes[idx]});
    }
    batch_req_dict["Callback"] = "False";

    }

    if (batch_type === "Pricer"){
    const { data } = await api.post<ResponseStartBatchRequest>(
      '/api/batch/request',
      batch_req_dict);


    const result = await onGetTaskResult<ResponseSuccess, ResponseError>(
      data.task_ids[0],
      startRequest + TIME_OUT,
    );

    if (result.error) {
      throw result.error;
    }
  
    return result;} 
    
    else if (batch_type ==="backtest"){

      let res_final: any = {}

      const { data } = await api.post<ResponseStartBatchRequest>(
        '/api/batch/request',
        batch_req_dict);

      
  
      const result1 = await onGetTaskResult<ResponseSuccess, ResponseError>(
        data.task_ids[0],
        startRequest + TIME_OUT,
      );

  
      if (result1.error) {
        throw result1.error;
      }
    
      res_final[data.task_ids[0]] = result1;
      

      const result2 = await onGetTaskResult<ResponseSuccess, ResponseError>(
        data.task_ids[1],
        startRequest + TIME_OUT,
      );
  
      if (result2.error) {
        throw result1.error;
      }
    
      res_final[data.task_ids[1]] = result2;



      const result3 = await onGetTaskResult<ResponseSuccess, ResponseError>(
        data.task_ids[2],
        startRequest + TIME_OUT,
      );
  
      if (result1.error) {
        throw result1.error;
      }
    
      res_final[data.task_ids[2]] = result3;

      return res_final;

  } 
  
  return res_final;} 
   
  catch (error) {
    throw error;
  };


}


export async function fetchBatchRequestOPT<ResponseSuccess = any, ResponseError = any>({
  url,
  batchRequestType,
  params,
  body,
    x
}: {
  batchRequestType: BatchRequestType;
  url?: string;
  params: {
    [key: string]: string | number | boolean;
  };
  body?: any;
  x?:any;
}): Promise<ResponseState<ResponseSuccess, ResponseError>> {
  const startRequest = new Date().getTime();

  try {
    const keys = Object.keys(params || {});
    let uriValue = '';

    keys.forEach((key, idx) => {
      uriValue = `${uriValue}${idx === 0 ? '' : '&'}${key}=${params[key]}`;
    });

    const VaryParam = (x === undefined) ? {
        "Vary":[
            {
              "Field": "Underlying_Tickers",
              "Range":
                    params.Underlying_Tickers.toString().split(','),
              "Choose": 2
            }
            ],
        "Mode": "Reduced"
    } : null;
    const { data } = await api.post<ResponseStartBatchRequest>(
      '/api/batch/request',
      {
        Callback: false,
        Requests: [
          {
            Type: getTypeUrl(url) || batchRequestType,
            URI: encodeURI(uriValue),
          },
        ],
        ...body,
        VaryParam,
        x
      });


    const result = await onGetTaskResult<ResponseSuccess, ResponseError>(
      data.task_ids[0],
      startRequest + TIME_OUT,
    );

    if (result.error) {
      throw result.error;
    }

    return result;
  } catch (error) {
    throw error;
  }
}
