import React from 'react';
import { Link } from 'react-router-dom';

export const NotFound: React.FC = () => {
  return (
    <div className='flex flex-col items-center justify-center w-full h-screen'>
      <h1 className='text-5xl'>Page Not Found</h1>

      <Link className='mt-5' to='/'>
        Go back home
      </Link>
    </div>
  );
};
