import React from 'react';

import { ValueWithLabel } from '~/components/ValueWithLabel';
import { ChildValueWithLabel } from '~/models/SNBuilderType';
import { WidgetInputType } from '~/widgets/commonTypes';
import { WrapperHideWidget } from '~/widgets/WrapperHideWidget';

export type ValueWithLabelWidgetProps = {
  data: ChildValueWithLabel;
} & WidgetInputType;

export const ValueWithLabelWidget = ({
  data,
  ...widgetFieldProps
}: ValueWithLabelWidgetProps) => {
  const { currentStore } = widgetFieldProps;
  const field = React.useMemo(() => data.field, [data.field]);

  const currentFieldInStore = React.useMemo(
    () => currentStore.value[field.name],
    [currentStore.value, field],
  );

  return (
    <WrapperHideWidget {...widgetFieldProps}>
      <ValueWithLabel
        value={currentFieldInStore?.currentValue}
        label={field.label}
        alignment={data.alignment}
        clipboard={data.clipboard}
        {...data.props}
      />
    </WrapperHideWidget>
  );
};
