import React from 'react';
import {
  fetchLogin,
  fetchToken,
  fetchRefreshToken,
  fetchLogout,
} from '~/api/auth/auth';
import Cookies from 'js-cookie';

import Swal from 'sweetalert2';

import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  SESSION_ID_KEY,
  USERNAME_KEY,
} from '~/constants/keys';

import { BASE_DOMAIN_URL } from '~/config/variables';

type AuthContextType = {
  isAuthenticated?: boolean;
  isLoading?: boolean;
  signIn: (
    username: string,
    password: string,
    callback?: () => void,
  ) => Promise<void>;
  checkAuth: () => void;
  logout: () => void;
};

export const AuthContext = React.createContext<AuthContextType>({
  signIn: async () => {},
  checkAuth: () => null,
  logout: () => null,
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const goToLogin = () => {
    if (!window.location.pathname.includes('/login')) {
      const redirectTo = window.location.pathname;
      window.location.href = `${window.location.origin}${BASE_DOMAIN_URL}/login?redirectTo=${redirectTo}`;
    }
  };

  const checkAuth = async () => {
    setIsLoading(true);

    try {
      const refreshData = await fetchRefreshToken();

      if (refreshData.refresh || refreshData.access) {
        setIsAuthenticated(true);

        setTimeout(() => setIsLoading(false), 500);

        return;
      } else {
        setIsAuthenticated(false);
        setIsLoading(false);
        fetchLogout();
        goToLogin();
      }
    } catch (error) {
      setIsAuthenticated(false);
      setIsLoading(false);
      fetchLogout();
      goToLogin();
    }
  };

  const signIn = async (
    username: string,
    password: string,
    callback?: () => void,
  ) => {
    try {
      const loginData = await fetchLogin(username, password);
      // console.log('loginData::', loginData);

      Cookies.set(SESSION_ID_KEY, loginData.session_id);
      Cookies.set(USERNAME_KEY, loginData.username);

      const tokenData = await fetchToken(username, password);
      // console.log('loginData::', tokenData);

      Cookies.set(ACCESS_TOKEN_KEY, tokenData.access);
      Cookies.set(REFRESH_TOKEN_KEY, tokenData.refresh);

      setIsAuthenticated(true);
      setIsLoading(false);
      callback?.();
    } catch (error) {
      console.error('loginData::error::', error);
      Swal.fire(
        'Error!!!',
        'Something is wrong. User not found. Please, confirm your username and password.',
        'error',
      );
    }
  };

  const logout = () => {
    fetchLogout();
    goToLogin();
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, signIn, isLoading, checkAuth, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
}
