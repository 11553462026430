import cx from 'classnames';
import styles from './styles.module.css';

export type InputFloatingLabelProps = {
  active?: boolean;
  label?: string | React.ReactNode;
  id: string;
  classNameContainer?: string;
};

export const InputFloatingLabel: React.FC<InputFloatingLabelProps> = ({
  id,
  label,
  active,
  children,
  classNameContainer,
}) => {
  return (
    <div id={id} className={cx(styles.containerInput, classNameContainer)}>
      {children}
      <label className={cx({ [styles.active]: active })} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};
