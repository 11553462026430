import React, { InputHTMLAttributes } from 'react';

import cx from 'classnames';
import { Icon } from '@iconify/react';

import { If } from '~/components/If';
import { InputFloatingLabel } from '~/components/InputFloatingLabel';

import { getInputStyles } from './utils';

import styles from './styles.module.css';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  classNameContainer?: string;
  hasValue?: boolean;
};

/**
 * TODO: Adjust floating label design (active/deactivated)
 */

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, placeholder, label, classNameContainer, ...props }, ref) => {
    const active = React.useMemo(
      () => !!props.value || !!props.defaultValue,
      [props.value, props.defaultValue],
    );

    const labelId = React.useRef<string>(Math.random().toString());

    return (
      <InputFloatingLabel
        id={labelId.current}
        label={label || placeholder}
        classNameContainer={classNameContainer}
        active={active}
      >
        <input
          ref={ref}
          className={getInputStyles(cx(styles.input, className))}
          {...props}
        />

        <If condition={props.type === 'number'}>
          <div className="absolute right-1 top-1 flex flex-col items-center gap-1 text-gray-500 z-10">
            <button type="button">
              <Icon fontSize={10} icon="akar-icons:circle-chevron-up" />
            </button>

            <button type="button">
              <Icon fontSize={10} icon="akar-icons:circle-chevron-down" />
            </button>
          </div>
        </If>
      </InputFloatingLabel>
    );
  },
);

Input.defaultProps = {
  type: 'text',
} as InputProps;

export default Input;
