import _ from 'lodash';

export const compareLists = (list1: any[], list2: any[]): boolean => {
  return _.isEqual(list1, list2);

  const list1Sorted = list1.sort();
  const list2Sorted = list2.sort();

  const sList1 = JSON.stringify(list1Sorted);
  const sList2 = JSON.stringify(list2Sorted);

  return sList1 === sList2;
};

export const compareObjects = (object1: any, object2: any) => {
  return _.isEqual(object1, object2);
  const listObject1 = Object.keys(object1)
    .sort()
    .map((key) => ({ [key]: object1[key] }));
  const listObject2 = Object.keys(object2)
    .sort()
    .map((key) => ({ [key]: object2[key] }));

  return compareLists(listObject1, listObject2);
};
