import React from 'react';
import cx from 'classnames';

import { Link as LinkRouter } from 'react-router-dom';

import { Icon } from '@iconify/react';
import { Collapse } from 'react-collapse';
import If from '~/components/If';

import { useAuth } from '~/providers/AuthProvider/useAuth';

import { BASE_DOMAIN_URL } from '~/config/variables';

import styles from './styles.module.css';
import Cookies from 'js-cookie';

type MenuItem = {
  id: number;
  name: string;
  icon?: string;
  path: string;
  href?: string;
  pinned?: boolean;
  internal?: boolean;
  subItems?: MenuItem[];
};

export type SidebarProps = {
  className?: string;
  listMenu: MenuItem[];
};

type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  internal?: boolean;
};

const Link: React.FC<LinkProps> = ({ children, internal, href, ...props }) => {
  if (internal) {
    return (
      <LinkRouter to={`${BASE_DOMAIN_URL}${href}`} {...props}>
        {children}
      </LinkRouter>
    );
  }

  return (
    <a href={`${window.location.origin}${href}`} {...props}>
      {children}
    </a>
  );
};

type ListItemProps = MenuItem & {
  fixed?: boolean;
  onClick?: () => void;
  activeSidebarItem?: string;
  handleClickLink?: (path: string) => void;
  handlePin?: (e: any, id: number) => void;
};

type UserItemProps = ListItemProps & {

}

const ListItem = ({
  path,
  name,
  icon,
  href,
  pinned,
  id,
  fixed,
  onClick,
  internal,
  subItems,
  activeSidebarItem,
  handleClickLink,
  handlePin,
}: ListItemProps) => {
  return (
    <div onClick={onClick}>
      <div className="ml-2 pt-3 pb-3">
        <div
          className={cx({
            [styles.selector]: activeSidebarItem === path,
          })}
        />
        <div className={styles.item}>
          {subItems?.length ? (
            <SubItems name={name} subItems={subItems} />
          ) : (
            <Link
              role="button"
              className={cx({
                [styles.sidebarActiveItem]: activeSidebarItem === path,
                [styles.sidebarItem]: activeSidebarItem !== path,
              })}
              onClick={() => handleClickLink?.(path)}
              internal={internal}
              href={href}
            >
              <If condition={icon}>
                <Icon className="ml-3" icon={icon || ''} width={24} />
              </If>

              <span className={cx('text-sm ml-5', styles.label)}>{name}</span>

              <If condition={!fixed}>
                <button
                  className={cx(styles.pinButton)}
                  type="button"
                  onClick={(e) => handlePin?.(e, id)}
                >
                  <Icon
                    className={cx({
                      [styles.pinned]: pinned,
                      [styles.pin]: !pinned,
                    })}
                    icon="bi:pin-fill"
                    width={15}
                  />
                </button>
              </If>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

type SubItemsProps = {
  subItems: Omit<ListItemProps, 'icon'>[];
  name: string;
};

const SubItems = ({ name, subItems }: SubItemsProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <button onClick={() => setIsOpen((prevState) => !prevState)} type="button">
      <div className="flex">
        <Icon
          className={cx('ml-3')}
          icon={isOpen ? 'akar-icons:chevron-down' : 'akar-icons:chevron-right'}
          width={24}
        />
        <span className={cx('text-sm ml-5', styles.label)}>{name}</span>
      </div>

      <div className="pl-8">
        <Collapse isOpened={isOpen}>
          {subItems?.map((subItem, subItemIds) => {
            return (
              <ListItem
                key={`${subItem.id}-${subItemIds + 1}`}
                fixed
                {...subItem}
              />
            );
          })}
        </Collapse>
      </div>
    </button>
  );
};

export const Sidebar = ({ className, listMenu: initialList }: SidebarProps) => {
  const [listMenu, setListMenu] = React.useState(initialList);
  const [activeSidebarItem, setActiveSidebarItem] = React.useState('');

  const { logout } = useAuth();

  const user_name = Cookies.get('username');




  const handlePin = (e: any, id: number) => {
    e.preventDefault();
    e.stopPropagation();
    setListMenu(
      listMenu
        .map((item) => {
          if (item.id === id) {
            item.pinned = !item.pinned;
          }
          return item;
        })
        .sort((a, b) => {
          if (a.id === 1 || b.id === 1) {
            return 0;
          }
          if (a.pinned && !b.pinned) {
            return -1;
          }
          if (!a.pinned && b.pinned) {
            return 1;
          }
          if (a.id < b.id) {
            return -1;
          }
          if (a.id > b.id) {
            return 1;
          }
          return 0;
        }),
    );
  };

  return (
    <div className={cx(styles.sidebar, className)}>
      <div className={cx(styles.sidebarHead, 'pt-4 mb-3')}>MENU</div>

      {listMenu.map((item, index) => {
        return (
          <ListItem
            key={`list-item-menu-${index}-${item.id}`}
            {...item}
            fixed={item.id === 1}
            activeSidebarItem={activeSidebarItem}
            handleClickLink={setActiveSidebarItem}
            handlePin={handlePin}
          />
        );
      })}

      <ListItem
        id={14}
        name={user_name||"user"}
        href = '/sn-builder'
        icon="carbon:user-avatar"
        path="user_name"
        pinned={false}
      />

      <ListItem
        id={13}
        name="Logout"
        href="#"
        icon="ion:log-out-outline"
        path="logout"
        pinned={false}
        fixed
        onClick={() => logout()}
      />
    </div>
  );
};

export default Sidebar;
