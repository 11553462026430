import axios from 'axios';
import Cookies from 'js-cookie';

import { BASE_URL } from '~/config/variables';
import {
  REFRESH_TOKEN_KEY,
  ACCESS_TOKEN_KEY,
  SESSION_ID_KEY,
  USERNAME_KEY,
} from '~/constants/keys';

const authApi = axios.create({
  baseURL: BASE_URL,
});

export const fetchLogout = () => {
  Cookies.remove(REFRESH_TOKEN_KEY);
  Cookies.remove(ACCESS_TOKEN_KEY);
  Cookies.remove(SESSION_ID_KEY);
  Cookies.remove(USERNAME_KEY);
};

type FetchTokenResponse = {
  access: string;
  refresh: string;
};

export const fetchToken = async (
  username: string,
  password: string
): Promise<FetchTokenResponse> => {
  const formData = new FormData();

  formData.append('username', username);
  formData.append('password', password);

  const { data } = await authApi.post<FetchTokenResponse>(`/token`, formData);

  return data;
};

type FetchRefreshTokenResponse = {
  refresh: string;
  access: string;
};

export const fetchRefreshToken =
  async (): Promise<FetchRefreshTokenResponse> => {
    const { data } = await authApi.post<FetchRefreshTokenResponse>(
      `/token/refresh`,
      {
        refresh: Cookies.get(REFRESH_TOKEN_KEY),
      }
    );

    if (data.refresh || data.access) {
      Cookies.set(ACCESS_TOKEN_KEY, data.refresh || data.access);
    }

    return data;
  };

type FetchLoginResponse = {
  session_id: string;
  user_id: number;
  username: string;
};

export const fetchLogin = async (
  username: string,
  password: string
): Promise<FetchLoginResponse> => {
  const formData = new FormData();

  formData.append('username', username);
  formData.append('password', password);

  const { data } = await authApi.post<FetchLoginResponse>(
    `/snpricer_login`,
    formData
  );

  return data;
};
