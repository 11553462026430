import React from 'react';
import cx from 'classnames';

import { MultiSelect } from 'react-multi-select-component';
import { ISelectProps } from 'react-multi-select-component/dist/types/lib/interfaces';
import { If } from '~/components/If';

import styles from './styles.module.css';

export type DropdownMultiOption = {
  label: string;
  value: any;
  disabled?: boolean;
  key?: string;
};

export type DropdownMultiProps = ISelectProps & {
  options: DropdownMultiOption[];
  labelledBy?: string;
  value: DropdownMultiOption[];
  onChange?: (values: DropdownMultiOption[]) => void;
  className?: string;
  hasSelectAll?: boolean;
  placeholder?: string;
};

export const DropdownMulti = ({
  labelledBy,
  placeholder,
  className,
  ...props
}: DropdownMultiProps) => {
  const active = React.useMemo(() => !!props.value?.length, [props.value]);
  const labelId = React.useRef<string>(Math.random().toString());

  return (
    <div className={cx(styles.container, className)}>
      <MultiSelect
        {...props}
        className="text-sm"
        labelledBy={labelId.current}
      />

      <If condition={placeholder}>
        <label
          htmlFor={labelId.current}
          className={cx(styles.label, { [styles.active]: active })}
        >
          {placeholder}
        </label>
      </If>
    </div>
  );
};
