import { api } from '~/api/api';
import { ElementType } from '~/models/SNBuilderType';

import { DropdownMultiOption } from '~/components/DropdownMulti';
import { dateUtils } from '~/utils/date';
import * as dateFns from 'date-fns';
import Swal from 'sweetalert2';

import { MethodType, KeyValue } from './types';

export const removeKeysFromObjByValue = (
  obj: any,
  value: any,
  selectedKeys?: string[],
) => {
  try {
    Object.keys(obj).forEach((key) => {
      if (selectedKeys && selectedKeys.length) {
        if (selectedKeys.includes(key) && obj[key] === value) {
          delete obj[key];
        }
      } else {
        if (obj[key] === value) {
          delete obj[key];
        }
      }
    });
  } catch (error) {
    console.error('removeUndefinedKeysFromObj::', error);
  }
};

export const hasKeyInObj = (key: string, obj: any) => {
  return Object.keys(obj).includes(key);
};

export const getParsedValue = (originalValue: any) => {
  let value;
  if (originalValue === 'False') {
    value = false;
  } else if (originalValue === 'True') {
    value = true;
  } else {
    value = originalValue;
  }

  return value;
};

export const getValuesInStore = (
  keys?: string[],
  currentStore: any = {},
  mappedKeys?: KeyValue<string>,
) => {
  const objResult: any = {};

  keys?.forEach((originalKey) => {
    const objInStore: any = currentStore[originalKey];

    if (!objInStore) {
      return;
    }

    const key = mappedKeys?.hasOwnProperty(originalKey)
      ? mappedKeys[originalKey]
      : originalKey;

    switch (objInStore.typeElement as ElementType) {
      case 'card-grid-values':
        const values = objInStore.currentValue || [];

        values.forEach((data: any) => {
          if (!hasKeyInObj(data.key, objResult)) {
            objResult[data.key] = getParsedValue(data.value);
          }
        });
        break;
      case 'dropdown':
        if (!hasKeyInObj(key, objResult)) {
          objResult[key] = objInStore?.currentValue?.value;
        }
        break;
      case 'dropdown-multi':
        if (!hasKeyInObj(key, objInStore) && objInStore?.currentValue?.length) {
          const selectedItems = objInStore.currentValue
            .map((item: DropdownMultiOption) => item.value)
            .join();

          objResult[key] = selectedItems;
        }

        break;
      case 'checkbox':
        if (
          !hasKeyInObj(key, objResult) &&
          objInStore?.currentValue !== undefined
        ) {
          objResult[key] = objInStore?.currentValue;
        }
        break;

      case 'date':
        try {
          if (objInStore?.currentValue) {
            const dateParsed = dateFns.format(
              new Date(objInStore?.currentValue),
              'yyyy-MM-dd',
            );

            objResult[key] = dateParsed;
          }
        } catch (error) {
          console.error(
            'getValuesInStore::date::',
            objInStore?.currentValue,
            error,
          );
        }

        break;

      default:
        if (!hasKeyInObj(key, objResult) && !!objInStore?.currentValue) {
          objResult[key] = getParsedValue(objInStore?.currentValue);
        }
        break;
    }
  });

  if (!Object.keys(objResult).length) {
    return undefined;
  }

  return objResult;
};

export const fetchApiByMethod = (
  method: MethodType,
  url: string,
  objs: {
    queryParams?: any;
    bodyParams?: any;
  },
) => {
  try {
    if (method === 'post') {
      return api.post(url, objs.bodyParams, {
        params: objs.queryParams,
      });
    }

    return api.get(url, {
      params: objs.queryParams,
    });
  } catch (error) {
    throw error;
  }
};

export const formatDecimalPercentValue = (value: number) => {
  return `${(value * 100).toFixed(2)}%`;
};

export const getPayloadIsLoadingFromKeys = (
  keys: string[],
  isLoading: boolean,
) => {
  let payload: any = {};

  keys.forEach((key) => {
    payload[key] = { isLoading };
  });

  return payload;
};

export const parseResultToCardGridValues = (
  result: { [key: string]: string },
  removeEmptyKeys: boolean = true,
) => {
  const keys = Object.keys(result);

  const newValues = keys.map((key) => {
    const value = result[key];
    const label = key;

    if (removeEmptyKeys && value === '') {
      return false;
    }

    return { key, label, value };
  });

  return newValues.filter(Boolean);
};

export const setTitle = (title?: string) => {
  const prefix = 'Disent';

  if (!title) {
    document.title = prefix;
    return;
  }

  document.title = `${prefix} - ${title}`;
};

export const handleError = (error: any) => {
  try {
    let html;

    if (Array.isArray(error?.response?.data?.error)) {
      if (
        error?.response?.data?.error?.length &&
        typeof error?.response?.data?.error?.[0] === 'string'
      ) {
        const errorsList = [].concat(error.response.data.error);
        html = `<div>${errorsList.map(
          (errorText) => `<p>${errorText}</p>`,
        )}</div>`;
      }
    } else if (typeof error?.response?.data?.error === 'string') {
      html = error.response.data.error || error.response.data.Error.Exception;
    } else if (typeof error?.response?.data?.Error?.Exception === 'string') {
      html = error.response.data.Error.Exception;
    } else if (typeof error.Exception === 'string') {
      html = error.Exception;
    }

    if (html) {
      Swal.fire({
        title: 'Error!!!',
        icon: 'error',
        html,
        confirmButtonColor: '#000',
      });
    }
  } catch (error: any) {
    console.log('error', { ...error });
  }
};

const DEFAULT_DATE_FORMAT = 'iii dd-LLL-yy hh:mm aa';

export const formatDropdownDateItem = (
  item: any,
  valueKey: string,
  dateKey: string,
  dateFormat: string = DEFAULT_DATE_FORMAT,
) => {
  let label = '';

  try {
    label = dateUtils.format(Date.parse(item[dateKey]), dateFormat);
  } catch (e) {
    label = item[dateKey];
  }

  return {
    ...item,
    // TODO: Remove this ':::' when it gonna solved on backend
    value: `:::${item[valueKey]}`,
    label,
  };
};

type ShortcutTime = 'annual' | 'semi-annual' | 'quarterly' | 'monthly';

export const getTimeByShortcut = (
  originalDate: any,
  shortcutTime: ShortcutTime,
) => {
  const typeElement = 'date';

  try {
    switch (shortcutTime) {
      case 'annual':
        return {
          currentValue: dateUtils.addYears(originalDate, 1),
          typeElement,
        };
      case 'semi-annual':
        return {
          currentValue: dateUtils.addMonths(originalDate, 6),
          typeElement,
        };
      case 'quarterly':
        return {
          currentValue: dateUtils.addMonths(originalDate, 3),
          typeElement,
        };
      case 'monthly':
        return {
          currentValue: dateUtils.addMonths(originalDate, 1),
          typeElement,
        };
      default:
        return null;
    }
  } catch (error) {
    return null;
  }
};

export const getSamePayloadToDifferentKeys = (
  payloadRef: any,
  keys: string[],
) => {
  let payload: any = {};

  keys.forEach((key) => {
    payload[key] = payloadRef;
  });

  return payload;
};
