import React from 'react';
import ReactJson from 'react-json-view';
import constate from 'constate';
import { useSearchParams } from 'react-router-dom';

import Card from '~/components/Card';
import If from '~/components/If';

import { ScreenWidget } from '~/widgets';
import appWithSettings from '~/hocs/appSettings';
import withProvider from '~/hocs/withProvider';

import { useProviderFactory } from '~/hooks/useProviderFactory';

import initialObj from './INITIAL_OBJ.json';

const usePlot3D = () => useProviderFactory();

const [Plot3DProvider, usePlot3DState, usePlot3DEffects] = constate(
  usePlot3D,
  (value) => value.state,
  (value) => value.effects,
);

const Plot3DPage = () => {
  const [searchParams] = useSearchParams();

  const { store } = usePlot3DState();
  const { setValue } = usePlot3DEffects();

  const hasStore = !!searchParams.get('store');
  const hasJson = !!searchParams.get('json');

  return (
    <>
      <ScreenWidget
        widgetFieldProps={{
          currentStore: store,
          sendValue: (payload) => setValue(payload),
        }}
        data={initialObj as any}
      />

      <If condition={window.location.origin.includes('localhost') || hasStore}>
        <Card className="mb-3" title="Store" defaultOpened={false}>
          <ReactJson src={store} />
        </Card>
      </If>

      <If condition={window.location.origin.includes('localhost') || hasJson}>
        <Card className="mb-3" title="JSON" defaultOpened={true}>
          <ReactJson src={initialObj} />
        </Card>
      </If>
    </>
  );
};

export const Plot3D = appWithSettings({
  title: 'Volatility Surfaces',
  description: 'Select Underlying and storage date',
})(withProvider(Plot3DProvider)(Plot3DPage));
