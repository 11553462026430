import React from 'react';
import cx from 'classnames';
import { useDebouncedCallback } from 'use-debounce/lib';

import { SimpleCard } from '~/components/SimpleCard';
import { ValueWithLabel } from '~/components/ValueWithLabel';
import {
  ChildCardGridValues,
  CardGridValuesValueItem,
} from '~/models/SNBuilderType';
import { WidgetInputType } from '~/widgets/commonTypes';
import { fetchCardGridValues } from '~/api/cardGridValues';
import { Loader } from '~/components/Loader';
import { useListenedParams } from '~/hooks/useListenedParams';
import { useFnProps } from '~/hooks/useFnProps';
import { WrapperHideWidget } from '~/widgets/WrapperHideWidget';
import { getByPath } from '~/utils/path';

const HELPER_TAG = 'CardGridValuesWidget:::';

const getLabel = (key: string) =>
  key.includes('_') ? key.split('_').join(' ') : key;

export type CardGridValuesWidgetProps = {
  data: ChildCardGridValues;
  widgetFieldProps: WidgetInputType;
};

const typeElement = 'card-grid-values';

export const CardGridValuesWidget = ({
  data,
  widgetFieldProps,
}: CardGridValuesWidgetProps) => {
  const { currentStore, sendValue } = widgetFieldProps;

  const [isLoading, setIsLoading] = React.useState(false);

  const field = React.useMemo(() => data.field, [data.field]);

  const currentFieldInStore: { currentValue: CardGridValuesValueItem[] } =
    React.useMemo(
      () => currentStore.value[field.name],
      [currentStore.value, field],
    );

  const currentValue: CardGridValuesValueItem[] = React.useMemo(() => {
    if (
      !currentFieldInStore?.currentValue ||
      !currentFieldInStore?.currentValue?.length
    ) {
      return [];
    }

    return currentFieldInStore.currentValue;
  }, [currentFieldInStore]);

  const { listenedParams } = useListenedParams({
    currentStore,
    listenedKeys: data.listenedKeys,
  });

  const { executeOn } = useFnProps({
    currentStore,
    data,
    sendValue,
    executeDelay: 50,
  });

  // Fill out with empty values
  // React.useEffect(() => {
  //   if (!currentFieldInStore || !currentFieldInStore.currentValue) {
  //     const emptyValues = data.field.values?.map((item) => ({
  //       label: item.label || getLabel(item.key),
  //       key: item.key,
  //       value: item.value,
  //     }));

  //     sendValue({
  //       [field.name]: {
  //         currentValue: emptyValues,
  //         typeElement: 'card-grid-values',
  //       },
  //     });
  //   }
  // }, [data, currentFieldInStore, sendValue, field]);

  const fetchData = async (url: string, params?: any) => {
    try {
      executeOn('onStartRequest');

      setIsLoading(true);
      const response = await fetchCardGridValues(url, params);

      executeOn('onSuccessRequest', { response });

      const result = getByPath(response, data.pathData || 'result');
      const keys = Object.keys(result);

      const newValues: CardGridValuesValueItem[] = keys
        .map((key) => {
          const label = getLabel(key);
          const value = result[key];

          if (!value) {
            return false;
          }

          return {
            key,
            label,
            value,
          };
        })
        .filter(Boolean) as CardGridValuesValueItem[];

      // REMOVE this code block after solve this on backend side
      // ------
      if (response.secmaster?.ISSUER) {
        newValues.push({
          key: 'Issuer',
          value: response?.secmaster?.ISSUER,
          label: 'Issuer',
        });
      }
      // ------

      sendValue({
        [field.name]: {
          currentValue: newValues,
          typeElement,
        },
      });
    } catch (error) {
      sendValue({
        [field.name]: {
          currentValue: undefined,
          typeElement,
        },
      });
    }

    setIsLoading(false);
    executeOn('onFinishRequest');
  };

  const fetchDebounced = useDebouncedCallback(fetchData, 100);

  React.useEffect(() => {
    const keys = Object.keys(listenedParams);

    if (!!keys.length) {
      const [key] = keys;

      const params = {
        KeyName: key,
        KeyValue: listenedParams[key],
      };

      if (!params.KeyValue) {
        return;
      }

      fetchDebounced('/api/datastore/query', params);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listenedParams]);

  return (
    <WrapperHideWidget {...data} {...widgetFieldProps}>
      <SimpleCard title={field.title}>
        <div
          className={cx(
            'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2', //
            'gap-y-3 gap-x-3',
          )}
        >
          {currentValue
            .filter((item) => {
              if (data.excludedKeys?.length) {
                const keys =
                  data.excludedKeys.map((key) => key.toLowerCase()) || [];

                return !keys.includes(item.key.toLowerCase());
              }

              return item;
            })
            .map((valueItem, idx) => {
              return (
                <ValueWithLabel
                  key={`value-with-label-${valueItem.key}-${idx}`}
                  value={isLoading ? <Loader /> : valueItem?.value}
                  label={valueItem.label || valueItem.key}
                />
              );
            })}
        </div>
      </SimpleCard>
    </WrapperHideWidget>
  );
};
