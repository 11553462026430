import { FunctionType } from '~/models/SNBuilderType';

export const fnBuilder = (stringFunction: string) => {
  console.log('stringFunction:::', stringFunction);
  const fn = new Function('props', stringFunction);

  return fn;
};

export const executeFnDynamicPath = async (
  fnPath: string,
  fnName: string,
  props: any,
) => {
  try {
    const functions = await import(`../${fnPath}`);

    functions?.[fnName]?.(props);
  } catch (error) {
    console.log('executeFnDynamicPath::error::', { error });
  }
};

export const executeFn = (
  stringFunctions: FunctionType[],
  props: any,
  lifeCycle: FunctionType['lifeCycle'],
) => {
  stringFunctions
    ?.filter((item) => item.lifeCycle === lifeCycle)
    .forEach((data) => {
      if (!!data.fnPath && !!data.fnName) {
        executeFnDynamicPath(data.fnPath, data.fnName, props);
        return;
      }

      if (!data?.fn) {
        return;
      }

      try {
        const fnBuilded = fnBuilder(data.fn);

        fnBuilded(props);
      } catch (error) {
        console.error('executeFn::error::lifeCycle', lifeCycle, error);
      }
    });
};
