import React from 'react';
import Card from '~/components/Card';
import Chooser from '~/components/Chooser';
import { WidgetInputType } from '~/widgets/commonTypes';

import { ChildCard } from '~/models/SNBuilderType';
import { WrapperHideWidget } from '~/widgets/WrapperHideWidget';

type StateProps = {
  checked?: boolean;
};

export type CardWidgetProps = {
  data: ChildCard;
  widgetFieldProps: WidgetInputType;
};

export const CardWidget = ({ data, widgetFieldProps }: CardWidgetProps) => {
  return null;
  const field = React.useMemo(() => data.field, [data.field]);

  const { currentStore, sendValue } = widgetFieldProps;

  const [state, setState] = React.useState<StateProps>();

  const currentFieldInStore = React.useMemo(
    () => currentStore?.value[field.name],
    [currentStore?.value, field],
  );

  React.useEffect(() => {
    if (!currentFieldInStore || !data.withCheckbox) {
      return;
    }

    if (currentFieldInStore.checked !== state?.checked) {
      setState((prev) => ({
        ...prev,
        checked: currentFieldInStore.checked,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFieldInStore, data]);

  React.useEffect(() => {
    sendValue({
      [field.name]: {
        currentValue: field.defaultValue,
        typeElement: data.typeElement,
        checked: Boolean(field.checked),
      },
    });
  }, [field]);

  const handleCheck = (checked: boolean) => {
    sendValue({ [field.name]: { checked } });
  };

  return (
    <WrapperHideWidget {...data} {...widgetFieldProps}>
      <Card
        title={field.defaultValue}
        tooltip={data.info?.description}
        withCheckbox={data.withCheckbox}
        onCheck={handleCheck}
        checked={state?.checked}
        defaultChecked={field.checked}
        {...data.props}
      >
        {data.children?.map((child, idx) => (
          <Chooser
            widgetFieldProps={widgetFieldProps}
            key={`child-col-${idx}`}
            data={child}
          />
        ))}
      </Card>
    </WrapperHideWidget>
  );
};
