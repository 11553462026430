import { api } from '../api';

export type DropdownResponse = {
  ResponseData: {
    underlyings: { value: string; label: string }[];
  };
};

export const fetchDropdown = async (
  url: string,
  params?: any,
): Promise<DropdownResponse> => {
  const resp = await api.get<DropdownResponse>(url, { params });

  return resp.data;
};
