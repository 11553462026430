import React from 'react';

import { Input } from '~/components/Input';
import { WidgetInputType } from '~/widgets/commonTypes';
import { If } from '~/components/If';
import { Loader } from '~/components/Loader';
import { WrapperHideWidget } from '~/widgets/WrapperHideWidget';

import { useFnProps } from '~/hooks/useFnProps';
import { formatCurrency } from '~/utils/formatter';

import { ChildValue } from '~/models/SNBuilderType';

type ValueWidgetProps = {
  data: ChildValue;
} & WidgetInputType;

const typeElement = 'value';

const ValueWidget = ({ data, ...widgetFieldProps }: ValueWidgetProps) => {
  const defaultValuesLoaded = React.useRef(false);

  const { currentStore, sendValue } = widgetFieldProps;
  const field = React.useMemo(() => data.field, [data.field]);

  const currentFieldInStore = React.useMemo(
    () => currentStore.value[field.name],
    [currentStore.value, field],
  );

  const { executeOn } = useFnProps({
    currentStore,
    sendValue,
    data,
  });

  React.useEffect(() => {
    if (!!defaultValuesLoaded.current || !field.defaultValue) {
      return;
    }

    sendValue({
      [field.name]: {
        currentValue: field.defaultValue,
        typeElement,
      },
    });

    defaultValuesLoaded.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  React.useEffect(() => {
    executeOn('onMount');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    executeOn('onClick');
  };

  const handleDoubleClick = () => {
    executeOn('onDoubleClick');
  };

  const value = React.useMemo(() => {
    if (data.format === 'currency') {
      return formatCurrency(currentFieldInStore?.currentValue || '');
    }

    if (!currentFieldInStore?.currentValue) {
      return '';
    }

    if (
      currentFieldInStore?.currentValue !== undefined ||
      currentFieldInStore?.currentValue !== ''
    ) {
      return `${field.prefix || ''}${currentFieldInStore?.currentValue}${
        field.suffix || ''
      }`;
    }

    return '';
  }, [data.format, currentFieldInStore, field]);

  return (
    <WrapperHideWidget {...data} {...widgetFieldProps}>
      <div className="flex w-full items-center justify-end relative">
        <If condition={!!currentFieldInStore?.isLoading}>
          <Loader size={50} className="absolute z-10" />
        </If>
        <Input
          {...data.props}
          value={value}
          placeholder={field.placeholder}
          onClick={handleClick}
          onDoubleClick={handleDoubleClick}
          disabled
        />
      </div>
    </WrapperHideWidget>
  );
};

ValueWidget.defaultProps = {
  col: 'col-4',
  heading: 'h6',
};

export default ValueWidget;
