import { ComponentType, ReactElement } from 'react';

export const withProvider =
  (...providers: ComponentType<any>[]) =>
  (WrappedComponent: ComponentType<any>) =>
  (props: unknown): ReactElement =>
    providers.reduceRight(
      (CombinedProvider, Provider) => <Provider>{CombinedProvider}</Provider>,
      <WrappedComponent {...props} />,
    );

export default withProvider;
