import React from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

export type TitleProps = {
  title: string;
  description?: string;
  className?: string;
};

export const Title = ({ title, description, className }: TitleProps) => {
  return (
    <div className={cx(styles.title, className)}>
      <h2 className="text-base leading-5">
        <b>{title}</b>
      </h2>
      <p className="text-sm text-gray-500">{description}</p>
    </div>
  );
};
