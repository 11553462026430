export const LIST_MENU = [
  {
    id: 1,
    name: 'Home',
    icon: 'fa:home',
    href: '/sn-builder',
    path: 'dashboard',
    pinned: false,
  },
  {
    id: 3,
    name: 'Notes Builder',
    icon: 'fa:file-text',
    href: '/sn-builder',
    path: 'sn-builder',
    pinned: false,
    internal: true,
  },
  {
    id: 4,
    name: 'Issued Notes',
    icon: 'fa:code-fork',
    href: '/secondary',
    path: 'issued-notes',
    pinned: false,
    internal: true,
  },
  {
    id: 5,
    name: 'Payoff Builder',
    icon: 'fa:line-chart',
    href: '/sn-override/',
    path: 'payoff-builder',
    pinned: false,
    internal: true,
  },
  {
    id: 6,
    name: 'Scenario Reports',
    icon: 'bi:grid-3x2-gap-fill',
    href: '/scenario',
    path: 'scenario-sce',
    pinned: false,
    internal: true,
  },
  {
    id: 7,
    name: ' Underlying Optimizer',
    icon: 'bi:grip-vertical',
    href: '/tck_opt',
    path: '',
    pinned: false,
    internal: true,
  },
  {
    id: 8,
    name: 'Volatility Surfaces',
    icon: 'bi:badge-3d-fill',
    path: 'vol-surfaces',
    href: '/vol-surfaces',
    pinned: false,
    internal: true,
  },
  {
    id: 9,
    name: 'Statistics',
    icon: 'ion:trending-up-outline',
    path: 'backtest',
    href: '/statistics',
    pinned: false,
    internal: true,
  },
  
  {
    id: 16,
    name: 'Legacy Screens',
    path: 'legacy-screens',
    pinned: false,
    subItems: [
      {
        id: 16.1,
        name: 'Notes Builder',
        path: 'legacy-screens:sn-builder',
        href: '/legacy/snpricer_djrbuilder',
        pinned: false
      },
      {
        id: 16.2,
        name: 'Statistics',
        path: 'legacy-screens:statistics',
        href: '/legacy/statistics',
        pinned: false
      },
      
      
      {
        id: 16.6,
        name: 'Portfolio',

        path: 'legacy-screens:portfolio',
        href: '/legacy/Portfolio',
      },
      {
        id: 16.7,
        name: 'Table Upload',

        path: 'tableupload',
        href: '/legacy/tableupload',
        pinned: false,
      },
      {
        id: 16.8,
        name: 'Table Editor',

        path: 'legacy-screens:tableeditor',
        href: '/legacy/tableeditor',
      },
    ],
  
  },
  {
    id: 16.3,
    name: 'Legacy Market Data',
    path: 'legacy-screens:market-data',
    pinned: false,
    subItems: [
      {
        id: 16.31,
        name: 'Equity Spot Price',
        path: 'market-data:equity-spot-price',
        href: '/legacy/guigrapher/SNMarketData?Name=AAPL+US+Equity&-Category=EQP&_autocalc=true&_vertical=true&_header=Equity+Spot',
      },
      {
        id: 16.32,
        name: 'Equity Price History',
        path: 'market-data:equity-price-history',
        href: '/legacy/guigrapher/SNMarketData?Name=AAPL+US+Equity&-Category=EQH&_autocalc=true&_vertical=true&_header=Equity+History',
      },
      {
        id: 16.33,
        name: 'Equity Dividends Forecast',
        path: 'market-data:equity-dividends-forecast',
        href: '/legacy/guigrapher/SNMarketData?Name=AAPL+US+Equity&-Category=EQD&_autocalc=true&_vertical=true&_header=Equity+Dividends+Forecast',
      },
      {
        id: 16.34,
        name: 'Equity Volatility',
        path: 'market-data:equity-volatility',
        href: '/legacy/guigrapher/SNMarketData?Name=AAPL+US+Equity&-Category=EQV&_autocalc=true&_vertical=true&_header=Equity+Volatility',
      },
      {
        id: 16.36,
        name: 'Interest Rates',
        path: 'market-data:interest-rates',
        href: '/legacy/guigrapher/SNMarketData?Name=USD&-Category=IRP&_autocalc=true&_vertical=true&_header=Interest+Rate',
      },
    ],
  },
  {
    id: 16.5,
    name: 'Legacy Market Models',
    path: 'legacy-screens:market-models',
    href: '/legacy/sn-builder',
    subItems: [
      {
        id: 16.51,
        name: 'Equity Volatility Interpolator',
        path: 'market-models:equity-volatility-interpolator',
        href: '/legacy/guigrapher/EquityVolinterp?_vertical=true&_header=Equity Volatility Interpolator',
      },
      {
        id: 16.52,
        name: 'Equity Dividend Interpolator',
        path: 'market-models:equity-dividend-interpolator',
        href: '/legacy/guigrapher/SNMarketData?Name=SPX+Index&-Category=EQY&_autocalc=true&_vertical=true&_header=Equity+Dividend+Interpolator',
      },
      {
        id: 16.53,
        name: 'Rates Bucketed Curve',
        path: 'market-models:rates-rucketed-curve',
        href: '/legacy/guigrapher/SNMarketData?-Name=USD&-Category=IRZ&_autocalc=true&_vertical=true&_header=Rates+Bucketed+Curve',
      },
      {
        id: 16.54,
        name: 'Rates Zero Curve Interpolator',
        path: 'market-models:rates-zero-curve-interpolator',
        href: '/legacy/guigrapher/RatesInterpolator?-Currency=USD&_vertical=true&_header=Rates+Zero+Curve+Interpolator',
      },
      {
        id: 16.55,
        name: 'Equity/Equity Correlation',
        path: 'market-models:equity-equity-correlation',
        href: '/legacy/guigrapher/EquityOptionPricer?_vertical=true&_header=Equity+Option+Pricer',
      },
      {
        id: 16.56,
        name: 'Equity Option Pricer',
        path: 'market-models:equity-option-pricer',
        href: '/legacy/guigrapher/EquityOptionPricer?_vertical=true&_header=Equity+Option+Pricer',
      },
    ],
  },
  {
    id: 16.4,
    name: 'Legacy Pricing Models',
    path: 'legacy-screens:pricing-models',
    pinned: false,
    subItems: [
      {
        id: 16.41,
        name: 'Options Pricer',
        path: 'pricing-models:equity-option-pricer',
        href: '/legacy/guigrapher/EquityOptionPricer?_vertical=true&_header=Equity+Option+Pricer',
      },
      {
        id: 16.42,
        name: 'Options by Symbol',
        path: 'pricing-models:equity-option-pricer-by-symbol',
        href: '/legacy/guigrapher/EquityOptionPricerBySymbol?_vertical=true&_header=Equity+Option+Pricer+by+Symbol',
      },
    ],
  },
];
