import ButtonWidget from '~/widgets/ButtonWidget';
import CheckBoxWidget from '~/widgets/CheckBoxWidget';
import DatePickerWidget from '~/widgets/DatePickerWidget';
import DropdownWidget from '~/widgets/DropdownWidget';
import InformationHover from '~/widgets/InformationHover';
import InputWidget from '~/widgets/InputWidget';
import TextWidget from '~/widgets/TextWidget';
import ValueWidget from '~/widgets/ValueWidget';
import { RowWidget } from '~/widgets/RowWidget';
import { CardWidget } from '~/widgets/CardWidget';
import { ColWidget } from '~/widgets/ColWidget';
import { WidgetInputType } from '~/widgets/commonTypes';
import { PlotWidget } from '~/widgets/PlotWidget';
import { ScatterPlotWidget } from '~/widgets/ScatterPlotWidget';
import { HistogramWidget } from '~/widgets/HistogramWidget';
import { DropdownMultiWidget } from '~/widgets/DropdownMultiWidget';
import { GridWidget } from '~/widgets/GridWidget';
import { CardGridValuesWidget } from '~/widgets/CardGridValuesWidget';
import { TabWidget } from '~/widgets/TabWidget';
import { ValueWithLabelWidget } from '~/widgets/ValueWithLabelWidget';
import { TitleWidget } from '~/widgets/TitleWidget';
import { SwitchWidget } from '~/widgets/SwitchWidget';
import { ButtonBarWidget } from '~/widgets/ButtonBarWidget';
import { Plot3DWidget } from '~/widgets/Plot3DWidget';

export type TypeElement =
  | 'row'
  | 'col'
  | 'card'
  | 'template'
  | 'text'
  | 'dropdown'
  | 'dropdown-multi'
  | 'info'
  | 'input'
  | 'date'
  | 'button'
  | 'checkbox'
  | 'value'
  | 'radio'
  | 'grid'
  | 'tab'
  | 'plot'
  | 'scatterplot'
  | 'histogram'
  | 'card-grid-values'
  | 'value-with-label'
  | 'title'
  | 'switch'
  | 'button-bar'
  | 'plot-3d';

export const getComponent = ({
  name,
  data,
  widgetFieldProps,
}: {
  name: TypeElement;
  data: any;
  widgetFieldProps: WidgetInputType;
}) => {
  switch (name) {
    case 'row':
      return <RowWidget widgetFieldProps={widgetFieldProps} data={data} />;
    case 'button':
      return <ButtonWidget data={data} widgetFieldProps={widgetFieldProps} />;
    case 'card':
      return <CardWidget widgetFieldProps={widgetFieldProps} data={data} />;
    case 'checkbox':
      return <CheckBoxWidget {...widgetFieldProps} data={data} />;
    case 'col':
      return <ColWidget widgetFieldProps={widgetFieldProps} data={data} />;
    case 'date':
      return <DatePickerWidget {...widgetFieldProps} data={data} />;
    case 'dropdown':
      return <DropdownWidget {...widgetFieldProps} data={data} />;
    case 'grid':
      return <GridWidget {...widgetFieldProps} data={data} />;
    case 'dropdown-multi':
      return <DropdownMultiWidget {...widgetFieldProps} data={data} />;
    case 'info':
      return <InformationHover data={data} />;
    case 'input':
      return <InputWidget {...widgetFieldProps} data={data} />;
    case 'value':
      return <ValueWidget {...widgetFieldProps} data={data} />;
    case 'text':
      return <TextWidget data={data} />;
    case 'plot':
      return <PlotWidget {...widgetFieldProps} data={data} />;
    case 'scatterplot':
      return <ScatterPlotWidget {...widgetFieldProps} data={data} />;
    case 'histogram':
      return <HistogramWidget {...widgetFieldProps} data={data} />;
    case 'card-grid-values':
      return (
        <CardGridValuesWidget widgetFieldProps={widgetFieldProps} data={data} />
      );
    case 'tab':
      return <TabWidget data={data} widgetFieldProps={widgetFieldProps} />;
    case 'value-with-label':
      return <ValueWithLabelWidget data={data} {...widgetFieldProps} />;
    case 'title':
      return <TitleWidget data={data} {...widgetFieldProps} />;
    case 'switch':
      return <SwitchWidget data={data} {...widgetFieldProps} />;
    case 'button-bar':
      return (
        <ButtonBarWidget data={data} widgetFieldProps={widgetFieldProps} />
      );
    case 'plot-3d':
      return <Plot3DWidget data={data} widgetFieldProps={widgetFieldProps} />;
    default:
      return null;
  }
};
