import { api } from '../api';

export type SnMarketDataResponse = {
  Result: {
    cols: {
      title: string;
    }[];
    data: any[][];
  };
};

export const fetchSnMarketData = async (
  url: string,
  params?: any,
): Promise<SnMarketDataResponse> => {
  const resp = await api.get<SnMarketDataResponse>(url, { params });

  return resp.data;
};
