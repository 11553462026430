import { ButtonHTMLAttributes } from 'react';
import cx from 'classnames';
import { If } from '~/components/If';
import { Icon } from '@iconify/react';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'solid' | 'outlined' | 'ghost';
  icon?: string;
  iconSize?: number | string;
  danger?: boolean;
};

export const Button = ({
  children,
  className,
  variant,
  icon,
  iconSize,
  danger,
  ...props
}: ButtonProps) => {
  const isOutlined = variant === 'outlined';
  const isGhost = variant === 'ghost';
  const isSolid = variant === 'solid';

  const isDisabled = Boolean(props.disabled);

  return (
    <button
      className={cx(
        'font-sans text-lg', // Font
        'h-9',
        'px-4 py-1', // Spaces
        'rounded-md', // Border
        'flex items-center justify-center',

        // Solid
        { 'bg-black text-white': isSolid }, // Colors
        { 'bg-red': isSolid && !!danger },

        // Outlined
        {
          'text-black border-black border-2 border-solid': isOutlined,
        }, // Colors
        { 'text-red border-red': isOutlined && !!danger },

        // Ghost
        { 'text-black border-0': isGhost }, // Colors
        { 'text-red': isGhost && !!danger }, // Colors

        // Others
        { 'transition-opacity duration-200 hover:opacity-80': !isDisabled }, // Hover
        { 'bg-gray-300 text-gray-900 cursor-not-allowed': isDisabled }, // If disabled,
        className,
      )}
      type="button"
      {...props}
    >
      <If condition={icon}>
        <Icon className="mr-2" icon={icon as string} fontSize={iconSize} />
      </If>
      {children}
    </button>
  );
};

Button.defaultProps = {
  variant: 'solid',
  iconSize: 20,
} as ButtonProps;

export default Button;
