import cx from 'classnames';

export const getInputStyles = (className?: string) => {
  return cx(
    'w-full py-1', // Size
    'px-2', // Spaces
    'border border-solid border-gray-500 rounded-md focus-within:border-primary', // Border
    'outline-none transition-all',
    'h-9 text-base',
    'font-sans', // Font
    className,
  );
};
