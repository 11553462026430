import React from 'react';
import cx from 'classnames';

import { ChildText } from 'src/models/SNBuilderType';
import { WidgetInputType } from '~/widgets/commonTypes';
import { WrapperHideWidget } from '~/widgets/WrapperHideWidget';
import { useFnProps } from '~/hooks/useFnProps';
import { Text } from '~/components/Text';

export type TextWidgetProps = {
  data: ChildText;
} & WidgetInputType;

const TextWidget = ({ data, ...widgetFieldProps }: TextWidgetProps) => {
  const { currentStore, sendValue } = widgetFieldProps;
  const { description, style } = data;

  const field = React.useMemo(() => data.field, [data.field]);

  const currentFieldInStore = React.useMemo(
    () => currentStore.value[field.name],
    [currentStore.value, field],
  );

  const { executeOn } = useFnProps({
    currentStore,
    sendValue,
    data,
  });

  const handleClick = () => {
    executeOn('onClick');
  };

  const handleDoubleClick = () => {
    executeOn('onDoubleClick');
  };

  return (
    <WrapperHideWidget {...data} {...widgetFieldProps}>
      <Text
        style={style}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        {...data.props}
      >
        {currentFieldInStore?.currentValue || description}
      </Text>
    </WrapperHideWidget>
  );
};

TextWidget.defaultProps = {} as TextWidgetProps;

export default TextWidget;
