import React from 'react';

import constate from 'constate';

const useHeader = () => {
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');

  const handleTitle = React.useCallback(
    (newTitle: string) => setTitle(newTitle),
    [setTitle],
  );

  const handleDescription = React.useCallback(
    (newDescription: string) => setDescription(newDescription),
    [setDescription],
  );

  const setHeader = (newTitle: string, desc?: string) => {
    handleTitle(newTitle);
    if (desc) {
      handleDescription(desc);
    }
  };

  return {
    store: {
      title,
      description,
    },
    effects: {
      handleDescription,
      handleTitle,
      setHeader,
    },
  };
};

export const [HeaderProvider, useStateHeader, useEffectsHeader] = constate(
  useHeader,
  (value) => value.store,
  (value) => value.effects,
);
