import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Grid, ColumnDef, EventCellValueChanged } from '~/components/Grid';

import { ChildGrid } from '~/models/SNBuilderType';
import { WidgetInputType } from '~/widgets/commonTypes';
import {
  fetchSnMarketData,
  SnMarketDataResponse,
} from '~/api/snDataMarketData';
import { useListenedParams } from '~/hooks/useListenedParams';
import { PricerResponse } from '~/api/pricer';
import { batchRequest } from '~/api/request';
import { getByPath } from '~/utils/path';
import { useGenericRequest } from '~/hooks/useGenericRequest';
import { useFnProps } from '~/hooks/useFnProps';
import { WrapperHideWidget } from '~/widgets/WrapperHideWidget';

import Swal from 'sweetalert2';
import { API_KEY } from '~/config/variables';

const hsv2hsl = (
  h: number,
  s: number,
  v: number,
  l = v - (v * s) / 2,
  m = Math.min(l, 1 - l),
) => [h, m ? (v - l) / m : 0, l];

const HELPER_TAG = 'GridWidget::';

export type GridWidgetProps = {
  data: ChildGrid;
} & WidgetInputType;

export const GridWidget = ({ data, ...widgetFieldProps }: GridWidgetProps) => {
  const typeElement = 'grid';
  const { currentStore, sendValue } = widgetFieldProps;
  const field = React.useMemo(() => data.field, [data.field]);

  const currentFieldInStore = React.useMemo(
    () => currentStore.value[field.name],
    [currentStore.value, field],
  );

  const { executeOn } = useFnProps({
    currentStore,
    data,
    sendValue,
  });

  const onCellValueChanged: ColumnDef['onCellValueChanged'] = React.useCallback(
    (e: EventCellValueChanged) => {
      try {
        const newList = (currentFieldInStore?.currentValue || []).map(
          (row: any, idx: number) => {
            if (idx === e.node.childIndex) {
              return {
                ...row,
                [e.colDef.field]: e.data[e.colDef.field],
                hasBeenChanged: true,
              };
            }
            return row;
          },
        );

        executeOn('onCellValueChanged', { event: e, newList });

        sendValue({
          [field.name]: {
            currentValue: newList,
            typeElement,
          },
        });
      } catch (error) {
        console.error('onCellValueChanged::error::', error);
      }
    },
    [currentFieldInStore, executeOn, sendValue, field.name],
  );

  const { genericFetcher } = useGenericRequest({ currentStore });

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [columns, setColumns] = React.useState<ColumnDef[]>(
    data.staticColumns?.map((col) => ({
      ...col,
      editable: data.editableFields?.includes(col.field as string),
    })) || [],
  );

  const { listenedParams } = useListenedParams({
    currentStore,
    listenedKeys: data.listenedKeys,
  });

  const getColumns = (
    dataColumns: SnMarketDataResponse['Result']['cols'],
  ): ColumnDef[] => {
    const cols: ColumnDef[] = dataColumns.map((col) => {
      const isEditable = data.editableFields?.includes(col.title);

      return {
        headerName: col.title,
        field: col.title,
        editable: isEditable,
      };
    });

    return cols;
  };

  const getData = (dataResult: SnMarketDataResponse['Result']) => {

    const cols = dataResult.cols.map((col) => col.title);

    const rows = dataResult.data.map((row) => {
      const obj: any = {};

      row.forEach((value, idx) => {
        obj[cols[idx]] = value;
      });

      return obj;
    });

    return rows;
  };

  const fetchHandlerMarketData = async (url: string, params?: any) => {
    try {
      executeOn('onStartRequest');

      setIsLoading(true);
      const resp = await fetchSnMarketData(url, params);
      setIsLoading(false);

      if (!data.staticColumns) {
        const cols = getColumns(resp.Result.cols);

        setColumns(cols);
      }

      const dataSet = getData(resp.Result);

      sendValue({ [field.name]: { currentValue: dataSet, typeElement } });

      executeOn('onSuccessRequest', { response: { ...resp } });
    } catch (error) {
      setIsLoading(false);
      console.error('fetchHandlerMarketData::error::', error);
    }
  };

  const fetchHandlerPricer = async (url: string, params?: any) => {
    try {
      executeOn('onStartRequest');

      setIsLoading(true);
      const resp = await batchRequest<PricerResponse, any>({
        batch_type: 'Pricer',
        params
      });
      executeOn('onFinishRequest', { response: { ...resp } });
      setIsLoading(false);

      if (resp?.error?.Exception) {
        return Swal.fire('Error', resp?.error?.Exception, 'error');
      }

      if (!resp?.data) {
        return;
      }

      if (!data.staticColumns && resp.data.OptionAttribution) {
        const columnKeys = Object.keys(
          resp.data.OptionAttribution[0] || {},
        ).map((title) => ({ title }));

        const columnsList: ColumnDef[] = getColumns(columnKeys);
        setColumns(columnsList);
      }

      const rows = resp.data.OptionAttribution || [];

      sendValue({ [field.name]: { currentValue: rows, typeElement } });

      executeOn('onSuccessRequest', { response: { ...resp?.data } });
    } catch (error) {
      setIsLoading(false);
      executeOn('onFinishRequest');
      console.error('fetchHandlerPricer::error::', error);
    }
  };

  const fetchHandlerGeneric = async (url: string) => {
    try {
      executeOn('onStartRequest');

      setIsLoading(true);
      const payload = await genericFetcher({
        url,
        mappedParamsKeys: data.genericRequest?.mappedParamsKeys,
        pathParamsKeys: data.genericRequest?.pathParamsKeys,
      });
      setIsLoading(false);

      if (!payload) {
        return;
      }

      const _data = getByPath(payload, data.genericRequest?.pathData || '');

      if (!data.staticColumns) {
        const columnKeys = Object.keys(_data[0]).map((title) => ({ title }));
        const columnsList: ColumnDef[] = getColumns(columnKeys);

        setColumns(columnsList);
      }

      sendValue({ [field.name]: { currentValue: _data, typeElement } });

      executeOn('onSuccessRequest', { response: { ...payload } });
    } catch (error) {
      setIsLoading(false);
      console.log('fetchHandlerGeneric::error::', error);
    }
  };

  const fetchData = async (url: string, params?: any) => {
    switch (data.handler) {
      case 'pricer':
        fetchHandlerPricer(url, params);
        break;
      case 'market-data':
        fetchHandlerMarketData(url, params);
        break;
      case 'generic':
        fetchHandlerGeneric(url);
        break;
      default:
        break;
    }
  };

  const fetchDebounced = useDebouncedCallback(fetchData, 300);

  React.useEffect(() => {
    const hasKeys = !!Object.keys(listenedParams).length;

    if (hasKeys) {
      fetchDebounced(data.url || '', listenedParams);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.url, listenedParams]);

  const loading = React.useMemo(() => {
    return isLoading || Boolean(currentFieldInStore?.isLoading);
  }, [isLoading, currentFieldInStore]);

  const rows = currentFieldInStore?.currentValue;

  const pctValueFormatter = (param: any) => {
    if (typeof param.value === 'number') {
      return `${(param.value * 100).toFixed(2)}%`;
    } else {
      return param.value;
    }
  };

  const cols = React.useMemo(() => {
    if (!data.dynamicColumns || rows === undefined) {
      return undefined;
    }

    return Object.keys(rows[0]).map((key) => {
      const col = Object({
        field: key,
        headerName: key,
        editable: false,
        floatingFilter: false,
      });

      if (data.colorize) {
        const values = rows
          .map((i: Object) => Object.values(i))
          .flat()
          ?.filter?.((i: any) => typeof i === 'number');
        const MIN = Math.min(...values);
        const MAX = Math.max(...values);
        const ha = 5;
        const hc = 125;

        const cellStyle = (param: any) => {
          if (typeof param.value !== 'number') {
            return {};
          }

          const w = (param.value - MIN) / (MAX - MIN);
          const h = w * (hc - ha) + ha;
          const s = (-w * 0.1 + 0.6) * 0.8;
          const v = (-w * 0.23 + 1) * 1;
          const hsl = hsv2hsl(h, s, v);
          const hsl_string = `hsl(${Math.round(hsl[0])},${Math.round(
            hsl[1] * 100,
          )}%,${Math.round(hsl[2] * 100)}%)`;

          return { backgroundColor: hsl_string };
        };

        col['cellStyle'] = cellStyle;
        col['valueFormatter'] = pctValueFormatter;
      }

      return col;
    });
  }, [rows, data.dynamicColumns, data.colorize]);

  return (
    <WrapperHideWidget {...data} {...widgetFieldProps}>
      <Grid
        columnDefs={cols || columns}
        data={rows || []}
        onCellValueChanged={onCellValueChanged}
        {...data.props}
        isLoading={loading}
      />
    </WrapperHideWidget>
  );
};
