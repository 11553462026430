import React from 'react';
import If from '~/components/If';
import Tooltip from '~/components/Tooltip';
import { useConditionsToShow } from '~/hooks/useConditionsToShow';
import { useListenedKeysToDisabled } from '~/hooks/useListenedKeysToDisabled';

import type { Condition } from '~/hooks/useConditionsToShow/types';

export type WrapperHideWidgetProps = {
  currentStore: any;
  hideWhenUnchecked?: string[];
  conditionsToShow?: Condition[];
  tooltip?: any;
};

export const WrapperHideWidget: React.FC<WrapperHideWidgetProps> = ({
  currentStore,
  hideWhenUnchecked,
  conditionsToShow,
  children,
  tooltip,
}) => {
  const { disabledByKeys: hideWidget } = useListenedKeysToDisabled({
    currentStore,
    listenedKeys: hideWhenUnchecked,
    inverted: true,
  });

  const showWidget = useConditionsToShow({ currentStore, conditionsToShow });

  return (
    <If condition={!hideWidget && showWidget}>
      <Tooltip {...tooltip}>{children}</Tooltip>
    </If>
  );
};
