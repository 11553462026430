import cx from 'classnames';

import { Icon } from '@iconify/react';
import iconQuestionCircle from '@iconify-icons/fa/question-circle';

import Tooltip from '~/components/Tooltip';
import { ChildInfo } from '~/models/SNBuilderType';

export type InformationHoverProps = {
  data: ChildInfo;
};

const InformationHover = ({ data }: InformationHoverProps) => {
  return (
    <>
      <Tooltip multiline description={data.description} {...data.props}>
        <button className={cx(data.className)}>
          <Icon color="#bdbab4" icon={iconQuestionCircle} width={20} />
        </button>
      </Tooltip>
    </>
  );
};

InformationHover.defaultProps = {} as InformationHoverProps;

export default InformationHover;
