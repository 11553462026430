import React from 'react';
import cx from 'classnames';

import disentImgLoader from '~/assets/images/disent-loader.gif';

export type DisentLoaderProps = {
  className?: string;
  width?: number | string;
  height?: number | string;
};

export const DisentLoader = ({ className, ...props }: DisentLoaderProps) => {
  return (
    <img
      className={cx(className)}
      src={disentImgLoader}
      alt="Disent Loader"
      {...props}
    />
  );
};
