import { Theme } from '~/models/theme';

export const DEFAULT_THEME: Theme = {
  colors: {
    primary: '#FFBD42',
    'primary-rgb': '255, 189, 66',
    secondary: '#6c757d',
    'secondary-rgb': '108, 117, 125',
  },
};
