import React from 'react';
import cx from 'classnames';
// import DatePicker from 'react-datepicker';

// import { addYears } from 'date-fns'; // Could be used as utils functions
// import { Col } from 'react-bootstrap';

import DatePicker from '~/components/DatePicker';
import { WidgetInputType } from '~/widgets/commonTypes';
import { ChildDate } from '~/models/SNBuilderType';
import { WrapperHideWidget } from '~/widgets/WrapperHideWidget';

import { useListenedKeysToDisabled } from '~/hooks/useListenedKeysToDisabled';
import { useFnProps } from '~/hooks/useFnProps';

import styles from './styles.module.scss';

export type DatePickerWidgetProps = {
  data: ChildDate;
} & WidgetInputType;

type StateProps = {
  value?: Date | null;
  disabled?: boolean;
};

const DatePickerWidget = ({
  data,
  ...widgetFieldProps
}: DatePickerWidgetProps) => {
  const { sendValue, currentStore } = widgetFieldProps;

  const field = React.useMemo(() => data.field, [data.field]);

  const currentFieldInStore = React.useMemo(
    () => currentStore.value[field.name],
    [currentStore.value, field],
  );

  const [state, setState] = React.useState<StateProps>({
    value: null,
    disabled: field.disabledDefault,
  });

  const { executeOn } = useFnProps({
    currentStore,
    data,
    sendValue,
    setState,
    state,
  });

  const { disabledByKeys: hideWidget } = useListenedKeysToDisabled({
    currentStore,
    listenedKeys: data.hideWhenUnchecked,
    inverted: true,
  });

  React.useEffect(() => {
    if (!currentFieldInStore) {
      return;
    }

    if (currentFieldInStore.currentValue !== state.value) {
      setState((prev) => ({
        ...prev,
        value: currentFieldInStore.currentValue,
      }));
    }

    if (Boolean(currentFieldInStore.disabled) !== state.disabled) {
      setState((prev) => ({ ...prev, disabled: currentFieldInStore.disabled }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFieldInStore]);

  React.useEffect(() => {
    executeOn('onMount');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: any) => {
    const newValue = e;

    sendValue({
      [field.name]: {
        currentValue: newValue,
        typeElement: data.typeElement,
      },
    });

    executeOn('onChange', { newValue });
  };

  return (
    <WrapperHideWidget {...data} {...widgetFieldProps}>
      <DatePicker
        wrapperClassName={styles.dateInputWrapper}
        className={cx(data.className)}
        selected={state.value}
        onChange={handleChange}
        disabled={state.disabled}
        todayButton="Today"
        placeholderText={field.placeholder}
        {...data.props}
      />
    </WrapperHideWidget>
  );
};

DatePickerWidget.defaultProps = {} as DatePickerWidgetProps;

export default DatePickerWidget;
