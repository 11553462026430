import React from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';

export const Heatmap = () => {
  const [data] = React.useState([
    { a: -48, b: -26, c: 3, d: 43, e: 58, f: 36, g: 28, h: 23 },
    { a: -39, b: -20, c: 3, d: 32, e: 52, f: 34, g: 27, h: 22 },
    { a: -33, b: -17, c: 2, d: 25, e: 48, f: 33, g: 27, h: 23 },
    { a: -29, b: -14, c: 2, d: 20, e: 45, f: 32, g: 27, h: 23 },
    { a: -20, b: -10, c: 1, d: 13, e: 40, f: 31, g: 27, h: 24 },
    { a: -15, b: -7, c: 1, d: 9, e: 34, f: 28, g: 25, h: 23 },
    { a: -11, b: -5, c: 1, d: 6, e: 25, f: 27, g: 24, h: 23 },
    { a: -9, b: -4, c: 0, d: 5, e: 20, f: 25, g: 23, h: 21 },
    { a: -7, b: -3, c: 0, d: 4, e: 16, f: 23, g: 22, h: 21 },
    { a: -6, b: -3, c: 0, d: 4, e: 14, f: 21, g: 21, h: 20 },
  ]);

  const min = -48;
  const max = 56;

  return (
    <div style={{ height: 500 }} className="ag-theme-fresh">
      <AgGridReact rowData={data} singleClickEdit={false}>
        {Object.keys(data[0]).map((col) => {
          return (
            <AgGridColumn
              editable
              field={col}
              cellStyle={(param) => {
                const weight = (param.value - min) / (max - min);
                const red = (1 - weight) * 255;
                const green = weight * 255;

                return { backgroundColor: `rgb(${red}, ${green}, 0)` };
              }}
            />
          );
        })}
      </AgGridReact>
    </div>
  );
};
