import React from 'react';
import cx from 'classnames';
import { useDebouncedCallback } from 'use-debounce';

import { Plot, PlotProps } from '~/components/Plot';
import { ChildScatterPlot } from '~/models/SNBuilderType';
import If from '~/components/If';
import { Loader } from '~/components/Loader';

import { WidgetInputType } from '~/widgets/commonTypes';

import { INITIAL_STATE, StateProps } from './constants';
import { fetchPricerEventual } from '~/api/pricereventual';
import { useListenedParams } from '~/hooks/useListenedParams';
import { useFnProps } from '~/hooks/useFnProps';
import { WrapperHideWidget } from '~/widgets/WrapperHideWidget';

export type ScatterPlotWidgetProps = {
  data: ChildScatterPlot;
} & WidgetInputType;

export const ScatterPlotWidget = ({ data, ...widgetFieldProps }: ScatterPlotWidgetProps) => {
  const { currentStore, sendValue } = widgetFieldProps;
  const field = React.useMemo(() => data.field, [data.field]);

  const [loading, setLoading] = React.useState(false);

  const { listenedParams } = useListenedParams({
    currentStore,
    listenedKeys: data.listenedKeys,
  });

  const currentFieldInStore = React.useMemo(
    () => currentStore.value[field.name],
    [currentStore.value, field],
  );

  const [state, setState] = React.useState<StateProps>({
    ...INITIAL_STATE,
    url: data.url,
  });

  const { executeOn } = useFnProps({
    currentStore,
    data,
    sendValue,
    setState,
    state,
  });



  const plot_data: PlotProps['data'] = [];

  const DEFAULT_LINE_VALUES: any = {
    type: 'scatter',
    mode: 'lines',
    showlegend: true,
  };

  const fetchData = async ({ params }: { params?: any }) => {
    //clean on startrequest

    executeOn('onSuccessRequest');
    setState((prev) => (INITIAL_STATE));
    try {
      setLoading(true);
      
      
      const response = params[Object.keys(listenedParams)[0]];

      const xs: any = response["x"];
      const axisKeys: any = response["y"];
      const plotLayout: any = {}
      plotLayout["title"] = response["title"]
      console.log(axisKeys)
      if (data.horizontal_line){
        axisKeys["horizontal_line"] = []
        xs.forEach((xvalue:any) =>{
          axisKeys["horizontal_line"].push?.(data.horizontal_line)
        
      })
    };

        
      

      setState((prev) => ({ ...prev, xs, axisKeys,plotLayout }));
      setLoading(false);

      executeOn('onSuccessRequest', { response });

    } catch (error) {
      console.error('fetchData', error);
      setLoading(false);
    }
  };

  const fetchDebounced = useDebouncedCallback(fetchData, data.delay || 100);

  React.useEffect(() => {
    const hasKeys = !!Object.keys(listenedParams).length;

    if (hasKeys) {
      let params = listenedParams;

      if (data?.staticParams) {
        params = { ...params, ...data?.staticParams };
      }

      fetchDebounced({ params });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listenedParams]);

  data.axisRange?.forEach((line) => {

    let store_stuff: string[] = Object.keys(currentStore.value)
    if (store_stuff.includes(line.keyName)){

      const ys = state.axisKeys?.[line.keyName];

      if (ys?.length) {
        plot_data.push({
          x: state.xs,
          y: ys,
          ...DEFAULT_LINE_VALUES,
          ...line.customValues,
        });
      }
    }
    else{
    const ys = state.axisKeys?.[line.keyName];

    if (ys?.length) {
      plot_data.push({
        x: state.xs,
        y: ys,
        ...DEFAULT_LINE_VALUES,
        ...line.customValues,
      });
    }
  }
  });
  


  const xaxis_range = data.xAxisRange || [0, 2];
  const yaxis_range = data.yAxisRange || [0, 2];
  const range_mode = data.range_mode || "normal";

  const layout: PlotProps['layout'] = {
    // width: data.width || 470,
    height: data.height,
    margin: { l: 80, r: 20, b: 40, t: 30, pad: 7 },
    xaxis: {
      title: data.xlabel || '',
      tickformat: '',
      autorange: true,
      range: xaxis_range,
    },
    yaxis: {
      title: data.ylabel || '',
      tickformat: '',
      autorange: true,
      rangemode: range_mode,
      range: yaxis_range,
    },
    legend: { x: 1, xanchor: 'right', y: 1 },
    ...state.plotLayout
  };

  var config = {
    toImageButtonOptions: {
      format: 'jpeg', // one of png, svg, jpeg, webp
      filename: 'custom_image',
      height: 500,
      width: 700,
      scale: 1,
    },
    modeBarButtonsToRemove: [
      'zoom2d',
      'pan2d',
      'select2d',
      'lasso2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
      'resetScale2d',
      'hoverCompareCartesian',
      'hoverClosestCartesian',
      'toggleHover',
      'resetViews',
      'toggleSpikelines',
    ],
    displaylogo: false,
  };

  return (
    <WrapperHideWidget {...data} {...widgetFieldProps}>
      <div className={cx('relative flex w-full', data.className)}>
        <If condition={loading}>
          <div
            style={{ width: layout.width, height: layout.height }}
            className="absolute flex items-center justify-center z-10"
          >
            <Loader size={48} />
          </div>
        </If>

        <Plot
          style={{ ...data.style, ...data.props?.style }}
          data={plot_data}
          layout={layout}
          config={config}
          {...data.props}
        />
      </div>
    </WrapperHideWidget>
  );
};

ScatterPlotWidget.defaultProps = {} as ScatterPlotWidgetProps;

export default ScatterPlotWidget;
