import React from 'react';

import cx from 'classnames';

export type LoaderProps = {
  className?: string;
  size?: number | string;
};

export const Loader = ({ className, size }: LoaderProps) => {
  return (
    <svg
      className={cx(className)}
      version="1.1"
      id="L4"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 100 100"
      enableBackground="new 0 0 0 0"
    >
      <circle fill="#000000" stroke="none" cx="6" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.1"
        />
      </circle>
      <circle fill="#000000" stroke="none" cx="26" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.2"
        />
      </circle>
      <circle fill="#000000" stroke="none" cx="46" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.3"
        />
      </circle>
    </svg>
  );
};

Loader.defaultProps = {
  size: 26,
} as LoaderProps;
