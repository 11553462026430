import { api } from '../api';

type ResponseType = {
  result: any;
  secmaster: any;
};

export const fetchCardGridValues = async (
  url: string,
  params?: string
): Promise<ResponseType> => {
  const { data } = await api.get<ResponseType>(url, { params });

  return data;
};
