import React from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

type ClickableLabelProps = {
  title: string;
  onClick: () => void;
  id: string;
  className?: string;
};

const ClickableLabel = React.forwardRef<HTMLLabelElement, ClickableLabelProps>(
  ({ id, onClick, title, className }, ref) => {
    return (
      <label
        ref={ref}
        title={title}
        className={cx(styles.switchLabel, id, className)}
        id={id}
        onClick={onClick}
      >
        {title}
      </label>
    );
  },
);

export type ButtonBarOption = {
  label: string;
  value: string;
};

export type ButtonBarProps = {
  className?: string;
  options: ButtonBarOption[];
  onChange: (value: string) => void;
  selected?: string;
  label?: string;
};

export const ButtonBar = ({
  className,
  options,
  selected,
  onChange,
  label,
}: ButtonBarProps) => {
  const labelId = React.useRef(Math.random().toString());

  const templateColumns = React.useMemo(() => {
    // Grid based on each size word
    // const frames = options.map((item) => `${item.label.length}fr`).join(' ');

    // Grid based on length list
    const frames = options.map(() => `1fr`).join(' ');

    return frames;
  }, [options]);

  return (
    <div className={cx(styles.switchContainer, className)}>
      <label className={cx('text-gray-800')} htmlFor={labelId.current}>
        {label}
      </label>
      <div
        style={{
          gridTemplateColumns: templateColumns,
        }}
        className={cx(
          styles.switch,
          'bg-gray-100 border border-solid border-gray-700',
        )}
      >
        {options.map((item, idx, list) => {
          const active = item.value === selected;
          const isLastItem = idx === list.length - 1;

          return (
            <div
              className={cx('flex', {
                'border-r border-gray-700': !isLastItem,
                'bg-primary font-semibold': active,
              })}
              key={item.value}
            >
              <input
                id={labelId.current}
                type="radio"
                name="switch"
                checked={active}
                value={item.value}
                className={cx('hidden')}
              />
              <ClickableLabel
                onClick={() => onChange(item.value)}
                title={item.label}
                id={item.value}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
