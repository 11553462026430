import React from 'react';
import cx from 'classnames';
// import { Icon } from '@iconify/react';

import imgLogo from '~/assets/svgs/logo.svg';
import imgProfile from '~/assets/images/profile-image.png';
import { Title } from '~/components/Title';
import { useStateHeader } from '~/providers/HeaderProvider';

export type HeaderProps = {
  className?: string;
  user?: {
    firstName: string;
  };
  title: string;
  description?: string;
};

export const Header = ({ className, user }: HeaderProps) => {
  const { title, description } = useStateHeader();

  return (
    <div
      className={cx(
        'flex items-center w-full justify-between ', // Alignment
        'py-4 pl-4 pr-9', // Spaces
        'border-b border-solid border-gray-400', // Border
        className,
      )}
    >
      <Title title={title} description={description} />

      <img src={imgLogo} alt="Disent Logo" className="mr-6" />

      <div className={cx('flex items-center')}>
        {/* <Icon icon="bytesize:bell" fontSize={22} /> */}

        <div className={cx('flex items-center ml-4')}>
          <img className="mr-3" width={24} src={imgProfile} alt="Profile" />

          <div className="flex flex-col mr-10">
            <b className="mb-0">{user?.firstName || '-'}</b>

            {/* <span className="text-gray-500">Sales Administrator</span> */}
          </div>

          {/* <Icon icon="ant-design:caret-right-filled" /> */}
        </div>
      </div>
    </div>
  );
};
