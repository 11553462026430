import React from 'react';
import constate from 'constate';
import { ScreenWidget } from '~/widgets';
import appWithSettings from '~/hocs/appSettings';
import withProvider from '~/hocs/withProvider';
import { useSearchParams } from 'react-router-dom';
import { useProviderFactory } from '~/hooks/useProviderFactory';

import initialObj from './INITIAL_OBJ.json';

const useLandingPage = () => useProviderFactory();

const [LandingPageProvider, useLandingPageState, useLandingPageEffects] =
  constate(
    useLandingPage,
    (value) => value.state,
    (value) => value.effects,
  );

export const LandingPagePage = () => {
  const [searchParams] = useSearchParams();

  const { store } = useLandingPageState();
  const { setValue } = useLandingPageEffects();

  return (
    <ScreenWidget
      widgetFieldProps={{
        currentStore: store,
        sendValue: (payload) => setValue(payload),
      }}
      data={initialObj as any}
    />
  );
};

export const LandingPage = appWithSettings({
  title: 'Disent Trader',
  description: 'Backtesting, Pricing, and Risk Scenarios',
})(withProvider(LandingPageProvider)(LandingPagePage));
