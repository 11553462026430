import React from 'react';
import cx from 'classnames';
import { useDebouncedCallback } from 'use-debounce/lib';
import { fetchDropdown } from '~/api/dropdown';

import { DropdownMulti, DropdownMultiOption } from '~/components/DropdownMulti';
import { WidgetInputType } from '~/widgets/commonTypes';
import { useFnProps } from '~/hooks/useFnProps';
import { useListenedParams } from '~/hooks/useListenedParams';
import { ChildDropdownMulti } from '~/models/SNBuilderType';

import { WrapperHideWidget } from '~/widgets/WrapperHideWidget';

type StateProps = {
  disabled?: boolean;
  choices: DropdownMultiOption[];
  value: DropdownMultiOption[];
  fromClick?: boolean;
  isLoading?: boolean;
};

export type DropdownMultiWidgetProps = {
  data: ChildDropdownMulti;
} & WidgetInputType;

const customValueRenderer = (selected: any, _options: any) => {
  return selected.length
    ? selected.map(
        (
          { value, label }: { value: string; label: string },
          idx: number,
          list: any[],
        ) => {
          const [firstWordFromValue] = value.split(' ');

          if (idx === 0) {
            if (list.length === 1) {
              return label;
            }

            return `WO (${firstWordFromValue}, `;
          }

          if (list.length === idx + 1) {
            return `${firstWordFromValue})`;
          }

          return firstWordFromValue + ', ';
        },
      )
    : '';
};

export const DropdownMultiWidget = ({
  data,
  ...widgetFieldProps
}: DropdownMultiWidgetProps) => {
  const { currentStore, sendValue } = widgetFieldProps;

  const { listenedParams } = useListenedParams({
    currentStore,
    listenedKeys: data.listenedKeys,
  });

  const [state, setState] = React.useState<StateProps>({
    choices: data.choices || [],
    value: [],
  });

  const setLoading = (bool: boolean) =>
    setState((prev) => ({ ...prev, isLoading: bool }));

  const field = React.useMemo(() => data.field, [data.field]);

  const currentFieldInStore = React.useMemo(
    () => currentStore.value[field.name],
    [currentStore?.value, field],
  );

  const { executeOn } = useFnProps({
    currentStore,
    data,
    sendValue,
  });

  const handleChange = (value: DropdownMultiOption[]) => {
    sendValue({
      [field.name]: {
        currentValue: value,
        typeElement: data.typeElement,
      },
    });

    executeOn('onSelect', { newValue: value });
  };

  const fetchData = async ({ params }: { params?: any }) => {
    try {
      setLoading(true);
      const response = await fetchDropdown(data.url || '/', params);

      setState((prev) => ({
        ...prev,
        choices: response?.ResponseData?.underlyings || [],
      }));
      setLoading(false);
    } catch (error) {
      console.error('error', error);
      setLoading(false);
    }
  };

  const fetchDebounced = useDebouncedCallback(fetchData, data.delay || 500);

  React.useEffect(() => {
    if (data.url && Object.keys(listenedParams).length > 0) {
      fetchDebounced({ params: listenedParams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listenedParams, data.url]);

  React.useEffect(() => {
    executeOn('onMount');
  }, []);

  React.useEffect(() => {
    if (!currentFieldInStore) {
      return;
    }

    if (currentFieldInStore.currentValue !== state.value) {
      setState((prev) => ({
        ...prev,
        value: currentFieldInStore.currentValue || [],
      }));
    }

    if (Boolean(currentFieldInStore.disabled) !== state.disabled) {
      setState((prev) => ({ ...prev, disabled: currentFieldInStore.disabled }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFieldInStore]);

  React.useEffect(() => {
    sendValue({
      [field.name]: {
        currentValue: field.defaultValue,
        typeElement: data.typeElement,
        disabled: Boolean(field.disabledDefault),
      },
    });
  }, [field]);

  return (
    <WrapperHideWidget {...data} {...widgetFieldProps}>
      <DropdownMulti
        options={currentFieldInStore?.choices || []}
        value={currentFieldInStore?.currentValue || []}
        onChange={handleChange}
        isLoading={Boolean(currentFieldInStore?.isLoading)}
        placeholder={field.placeholder}
        className={cx(data.className)}
        onMenuToggle={(focusIn: boolean) => {
          if (focusIn) {
            executeOn('onFocus');
          }
        }}
        valueRenderer={customValueRenderer}
        hasSelectAll={false}
        {...data.props}
      />
    </WrapperHideWidget>
  );
};
