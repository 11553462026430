import React from 'react';
import cx from 'classnames';

import PlotUi, { PlotParams } from 'react-plotly.js';

import { useRefDimensions } from '~/hooks/useRefDefinitions';

// import { INITIAL_STATE, StateProps } from './constants';
// import { payoff_array } from './fakeData.json';

export type PlotProps = PlotParams;

// Change to get it color by theme
const PRIMARY_COLOR = '#FFBD42';

export const Plot = ({ className, layout, ...props }: PlotProps) => {

  const {ref, ...dimensions} = useRefDimensions({});

  const layoutProps: PlotParams['layout'] = {
    ...layout,
    autosize: true,
    colorway: [PRIMARY_COLOR],
    width: dimensions.width,
  };

  // Code to test with data

  // const [state, setState] = React.useState<StateProps>({
  //   ...INITIAL_STATE,
  // });

  // const plot_data: PlotProps['data'] = [
  //   {
  //     x: state.xs,
  //     y: state.ys,
  //     line: {
  //       width: 5,
  //     },
  //     mode: 'lines',
  //     name: 'Normal',
  //     showlegend: false,
  //   },
  // ];

  // const DEFAULT_LAYOUT: PlotProps['layout'] = {
  //   // width: data.width || 470,
  //   // height: plot_data.height,
  //   margin: { l: 80, r: 20, b: 40, t: 10, pad: 7 },
  //   xaxis: {
  //     title: 'Underlying',
  //     tickformat: ',.0%',
  //     autorange: true,
  //     // range: xaxis_range,
  //   },
  //   yaxis: {
  //     title: 'Payoff',
  //     tickformat: ',.0%',
  //     autorange: true,
  //     // range: yaxis_range,
  //   },
  //   legend: { x: 1, xanchor: 'right', y: 1 },
  // };

  // React.useState(() => {
  //   const xs: any = [];
  //   const ys: any = [];

  //   for (var idx in payoff_array) {
  //     ys.push(payoff_array[idx][0]);
  //     xs.push(payoff_array[idx][1]);
  //   }

  //   setState((prev) => ({ ...prev, xs, ys }));
  // }, []);

  return (
    <div
      style={{ width: '100%' }}
      ref={ref}
      className={cx('w-full flex flex-col', className)}
    >
      <PlotUi
        {...props}
        layout={{
          // ...DEFAULT_LAYOUT,
          ...layoutProps,
        }}
        // data={plot_data}
        className='w-full'
      />
    </div>
  );
};
