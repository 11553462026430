import { Theme } from '~/models/theme';

export const useTheme = () => {
  const createTheme = ({
    primary,
    primaryRgb,
    secondary,
    secondaryRgb,
  }: {
    primary: string;
    primaryRgb: string;
    secondary: string;
    secondaryRgb: string;
  }) => {
    return {
      '--theme-primary': primary,
      '--theme-primary-rgb': primaryRgb,
      '--theme-secondary': secondary,
      '--theme-secondary-rgb': secondaryRgb,
    };
  };

  const applyTheme = (theme: Theme) => {
    const root = document.documentElement;

    const themeKeys = createTheme({
      primary: theme.colors.primary,
      primaryRgb: theme.colors['primary-rgb'],
      secondary: theme.colors.secondary,
      secondaryRgb: theme.colors['secondary-rgb'],
    });

    type ThemeKeys = keyof typeof themeKeys;

    Object.keys(themeKeys).forEach((cssVar) => {
      root.style.setProperty(cssVar, themeKeys[cssVar as ThemeKeys]);
    });
  };

  return {
    applyTheme,
  };
};
