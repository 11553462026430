import React from 'react';
import cx from 'classnames';
import { useDebouncedCallback } from 'use-debounce';

import { ChildInput } from '~/models/SNBuilderType';
import { WidgetInputType } from '~/widgets/commonTypes';

import { Input, PercentInput } from '~/components/Input';
import { WrapperHideWidget } from '~/widgets/WrapperHideWidget';

import { useListenedKeysToDisabled } from '~/hooks/useListenedKeysToDisabled';
import { useFnProps } from '~/hooks/useFnProps';
import If from '~/components/If';

export type InputWidgetProps = {
  data: ChildInput;
} & WidgetInputType;

type StateProps = {
  value?: number | string;
  disabled?: boolean;
};

const SpanSymbol = React.memo(({ value }: { value?: string }) => {
  return (
    <Input
      value={value}
      style={{ width: '43.73px' }}
      classNameContainer="w-auto"
      className="text-center"
      disabled
    />
  );
});

const typeElement = 'input';

const InputWidget = ({ data, ...widgetFieldProps }: InputWidgetProps) => {
  const defaultValueLoaded = React.useRef(false);

  const { sendValue, currentStore } = widgetFieldProps;
  const field = React.useMemo(() => data.field, [data.field]);

  const currentFieldInStore = React.useMemo(
    () => currentStore.value[field.name],
    [currentStore.value, field],
  );

  const [state, setState] = React.useState<StateProps>({
    value: data.field.defaultValue as any,
    disabled: data.field.disabledDefault,
  });

  const { disabledByKeys } = useListenedKeysToDisabled({
    currentStore,
    listenedKeys: data.listenedCheckboxes,
    inverted: true,
  });

  const { executeOn } = useFnProps({
    currentStore,
    data,
    sendValue,
    setState,
    state,
  });

  // React.useEffect(() => {
  //   if (!currentFieldInStore) {
  //     return;
  //   }

  //   if (currentFieldInStore.currentValue !== state.value) {
  //     setState((prev) => ({
  //       ...prev,
  //       value: currentFieldInStore.currentValue,
  //     }));
  //   }

  //   if (Boolean(currentFieldInStore.disabled) !== state.disabled) {
  //     setState((prev) => ({ ...prev, disabled: currentFieldInStore.disabled }));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentFieldInStore]);

  React.useEffect(() => {
    executeOn('onMount');

    sendValue({ [field.name]: { typeElement } });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // React.useEffect(() => {
  //   if (
  //     !defaultValueLoaded.current &&
  //     sendValue &&
  //     !currentFieldInStore?.currentValue
  //   ) {
  //     sendValue({
  //       [field.name]: {
  //         currentValue: field.defaultValue,
  //         disabled: Boolean(field.disabledDefault),
  //         typeElement,
  //       },
  //     });

  //     defaultValueLoaded.current = true;
  //   }
  // }, [field, sendValue, defaultValueLoaded, typeElement, currentFieldInStore]);

  const handleChangeApply = (newValue?: string | number) => {
    sendValue({
      [field.name]: {
        currentValue: newValue,
        typeElement,
      },
    });
    executeOn('onChange', { newValue });
  };

  const handleChangeDebounced = useDebouncedCallback(handleChangeApply, 300);

  const handleChange = (e: any) => {
    const value =
      field.typeValue === 'number'
        ? parseFloat(e.target.value)
        : e.target.value;

    setState((prev) => ({ ...prev, value: value }));

    handleChangeDebounced(value);
  };

  const handleChangeNumber = (value?: number) => {
    setState((prevState) => ({ ...prevState, value }));

    handleChangeDebounced(value);
  };

  React.useEffect(() => {
    setState((prevState) => {
      if (prevState.value !== currentFieldInStore?.currentValue) {
        return { ...prevState, value: currentFieldInStore?.currentValue };
      }

      return prevState;
    });
  }, [currentFieldInStore, setState]);

  const handleClick = (e: any) => {
    executeOn('onClick');
  };

  const handleDoubleClick = (e: any) => {
    executeOn('onDoubleClick');
  };

  return (
    <WrapperHideWidget {...data} {...widgetFieldProps}>
      <div className="flex w-100">
        {field.isPercentNumber ? (
          <>
            <PercentInput
              className={cx('flex-1', data.className)}
              defaultValue={currentFieldInStore?.currentValue}
              onSetValue={handleChangeNumber}
              placeholder={field.placeholder}
              min={field.min}
              max={field.max}
              name={field.name}
              disabled={currentFieldInStore?.disabled || disabledByKeys}
              {...data.props}
            />

            <SpanSymbol value={'%'} />
          </>
        ) : (
          <>
            <Input
              className={cx('flex-1', data.className)}
              type={field.typeValue}
              step={field.step}
              min={field.min}
              max={field.max}
              placeholder={field.placeholder}
              value={state.value}
              defaultValue={currentFieldInStore?.currentValue}
              name={field.name}
              onChange={handleChange}
              disabled={currentFieldInStore?.disabled || disabledByKeys}
              onClick={handleClick}
              onDoubleClick={handleDoubleClick}
              {...data.props}
            />
            <If condition={field.spanSymbol}>
              <SpanSymbol value={field.spanSymbol} />
            </If>
          </>
        )}
      </div>
    </WrapperHideWidget>
  );
};

// InputWidget.defaultProps = {} as InputWidgetProps;

export default InputWidget;
