/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { DEFAULT_THEME } from '~/constants/theme';
import { useTheme } from '~/hooks/useTheme';

import {
  Secondary,
  SNBuilder,
  SnOverride,
  LoginPage,
  Heatmap,
  NotFound,
  Scenario,
  Plot3D,
  LandingPage,
  UnderlyingOpt,
  Portfolio,
  Statistics
} from '~/pages';

import Sidebar from '~/components/Sidebar';

import { LIST_MENU } from './constants';
import { Header } from '~/components/Header';
import { useAuth } from '~/providers/AuthProvider/useAuth';
import { DisentLoader } from '~/components/DisentLoader';
import { BASE_DOMAIN_URL } from '~/config/variables';

type PrivateRouteProps = {
  title: string;
  description?: string;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  title,
  description,
}) => {
  let { isAuthenticated, isLoading } = useAuth();
  let location = useLocation();

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated) {
    return (
      <Navigate
        to={`${BASE_DOMAIN_URL}/login?redirectTo=${BASE_DOMAIN_URL}${location.pathname}`}
        state={{ from: location }}
        replace
      />
    );
  }

  return (
    <div>
      <Header title={title} description={description} />

      <div className="flex relative">
        <Sidebar listMenu={LIST_MENU} />

        <div className="w-full py-4 pr-4 pl-20">{children}</div>
      </div>
    </div>
  );
};

const ROUTES = [
  {
    private: false,
    path: '/',
    element: <LoginPage />,
    title: 'Login',
  },
  {
    private: false,
    path: '/login',
    element: <LoginPage />,
    title: 'Login',
  },

  {
    private: true,
    path: '/secondary',
    element: <Secondary />,
    title: 'Issued Login',
  },
  {
    private: true,
    path: '/sn-builder',
    element: <SNBuilder />,
    title: 'Notes Builder',
  },
  {
    private: true,
    path: '/sn-override',
    element: <SnOverride />,
    title: 'Payoff Builder',
  },
  {
    private: true,
    path: '/heatmap',
    element: <Heatmap />,
    title: 'Heatmap',
  },
  {
    private: true,
    path: '/scenario',
    element: <Scenario />,
    title: 'Scenario',
  },
  {
    private: true,
    path: '/vol-surfaces',
    element: <Plot3D />,
    title: 'Vol Surfaces',
  },
  {
    private: true,
    path: '/portfolio',
    element: <Portfolio />,
    title: 'Portfolio Viewer',
  },
  {
    private: true,
    path: '/statistics',
    element: <Statistics />,
    title: 'Statistical Analysis',
  },

  {
    private: true,
    path: '/tck_opt',
    element: <UnderlyingOpt />,
    title: 'Underlying Optimizer',
  },
];

const AppRoutes: React.FC = () => {
  let { isLoading, checkAuth } = useAuth();
  const { applyTheme } = useTheme();

  React.useEffect(() => {
    applyTheme(DEFAULT_THEME);
    checkAuth();
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <DisentLoader width="30%" />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        {ROUTES.map((route, idx) => {
          const key = `${route.path}-${idx + 1}`;

          if (!route.private) {
            return (
              <Route
                key={key}
                {...route}
                path={`${BASE_DOMAIN_URL}${route.path}`}
              />
            );
          }

          return (
            <Route
              {...route}
              key={key}
              element={<PrivateRoute {...route}>{route.element}</PrivateRoute>}
              path={`${BASE_DOMAIN_URL}${route.path}`}
            />
          );
        })}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
