import { api } from '../api';

export type PricerEventualResponse = {
  payoff_array: { x: number; y1: number; y2?: number }[];
};

export const fetchPricerEventual = async (
  url: string,
  params?: any,
): Promise<PricerEventualResponse> => {
  const resp = await api.get<PricerEventualResponse>(url, { params });

  return resp.data;
};
