import React from 'react';
import cx from 'classnames';

import Checkbox from '~/components/Checkbox';

import { WidgetInputType } from '~/widgets/commonTypes';
import { ChildCheckbox } from '~/models/SNBuilderType';
import { useFnProps } from '~/hooks/useFnProps';
import { WrapperHideWidget } from '~/widgets/WrapperHideWidget';

export type CheckBoxWidgetProps = {
  data: ChildCheckbox;
} & WidgetInputType;

type StateProps = {
  disabled?: boolean;
  checked?: boolean;
};

const CheckBoxWidget = ({ data, ...widgetFieldProps }: CheckBoxWidgetProps) => {
  const { currentStore, sendValue } = widgetFieldProps;

  const defaultValuesLoaded = React.useRef(false);

  const field = React.useMemo(() => data.field, [data.field]);

  const currentFieldInStore = React.useMemo(
    () => currentStore.value[field.name],
    [currentStore.value, field],
  );

  const [state, setState] = React.useState<StateProps>({
    disabled: field.disabledDefault,
    checked: Boolean(field.defaultValue),
  });

  const { executeOn } = useFnProps({
    currentStore,
    state,
    setState,
    data,
    sendValue,
  });

  // If this field is changed in the store, it will be changed in the component too
  React.useEffect(() => {
    if (!currentFieldInStore) {
      return;
    }

    if (currentFieldInStore.currentValue !== state.checked) {
      setState((prev) => ({
        ...prev,
        checked: currentFieldInStore.currentValue,
      }));
    }

    if (Boolean(currentFieldInStore.disabled) !== state.disabled) {
      setState((prev) => ({ ...prev, disabled: currentFieldInStore.disabled }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFieldInStore]);

  React.useEffect(() => {
    executeOn('onMount');
  }, []);

  const handleClick = (e: any) => {
    executeOn('onClick');
  };

  const handleChange = (e: any) => {
    const { checked: newValue } = e.target;

    sendValue({
      [field.name]: {
        currentValue: newValue,
      },
    });

    executeOn('onChange', { newValue });
  };

  React.useEffect(() => {
    if (
      defaultValuesLoaded.current ||
      currentFieldInStore?.currentValue !== undefined ||
      currentFieldInStore?.currentValue !== null
    ) {
      return;
    }

    sendValue({
      [field.name]: {
        currentValue: field.defaultValue,
        typeElement: data.typeElement,
        disabled: Boolean(field.disabledDefault),
      },
    });

    defaultValuesLoaded.current = true;
  }, [
    field,
    defaultValuesLoaded,
    currentFieldInStore,
    sendValue,
    data.typeElement,
  ]);

  return (
    <WrapperHideWidget {...data} {...widgetFieldProps}>
      <Checkbox
        label={field.title}
        className={cx(data.className)}
        disabled={state.disabled}
        onClick={handleClick}
        onChange={handleChange}
        checked={!!state.disabled ? false : state.checked}
      />
    </WrapperHideWidget>
  );
};

CheckBoxWidget.defaultProps = {} as CheckBoxWidgetProps;

export default CheckBoxWidget;
