import React from 'react';
import Chooser from '~/components/Chooser';
import Row from '~/components/Row';
import { WidgetInputType } from '~/widgets/commonTypes';

import { ChildRow } from '~/models/SNBuilderType';
import { WrapperHideWidget } from '~/widgets/WrapperHideWidget';

export type RowWidgetProps = {
  data: ChildRow;
  widgetFieldProps: WidgetInputType;
};

export const RowWidget = ({ data, widgetFieldProps }: RowWidgetProps) => {
  return (
    <WrapperHideWidget {...data} {...widgetFieldProps}>
      <Row {...data?.props}>
        {data?.children?.map((child, idx) => (
          <Chooser
            widgetFieldProps={widgetFieldProps}
            key={`child-col-${idx}`}
            data={child}
          />
        ))}
      </Row>
    </WrapperHideWidget>
  );
};
