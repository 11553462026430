import React from 'react';

import cx from 'classnames';

import { Collapse } from 'react-collapse';
import { Icon } from '@iconify/react';
import iconQuestionCircle from '@iconify-icons/fa/question-circle';

import Tooltip from '~/components/Tooltip';
import { If } from '~/components/If';
import { Checkbox } from '~/components/Checkbox';

import { useModal } from '~/hooks/useModal';

type CardHeaderProps = {
  title: string;
  subTitle?: string;
  isOpened: boolean;
  onClick: () => void;
  className?: string;
  tooltip?: string;
  onCheck?: (checked: boolean) => void;
  checked?: boolean;
  defaultChecked?: boolean;
  withCheckbox?: boolean;
};

const CardHeader = ({
  title,
  subTitle,
  isOpened,
  onClick,
  className,
  tooltip,
  checked,
  onCheck,
  defaultChecked,
  withCheckbox,
  ...props
}: CardHeaderProps) => {
  return (
    <button
      className={cx(
        'flex items-center justify-between w-full', // alignment
        // 'bg-gray-100', // color
        'px-3 py-2', // spaces
        // 'rounded-t-md border border-solid border-gray-400', // border
        { 'rounded-md': !isOpened },
        // 'text-2xl', // font
        className,
      )}
      onClick={onClick}
      {...props}
    >
      <div className={cx('flex justify-center')}>
        <If condition={withCheckbox}>
          <Checkbox
            defaultChecked={defaultChecked}
            checked={checked}
            onChange={(e) => onCheck?.(e.target.checked)}
            className="mr-3"
          />
        </If>

        <div className={cx('border-l-2 border-primary pl-4')}>
          <h5>
            <b>{title}</b>
          </h5>
          <If condition={subTitle}>
            <p className="text-xs text-gray-700">{subTitle}</p>
          </If>
        </div>

        <If condition={tooltip}>
          <Tooltip content={tooltip}>
            <Icon
              role="button"
              className="text-gray-800 ml-3"
              icon={iconQuestionCircle}
              width={20}
            />
          </Tooltip>
        </If>
      </div>

      <span
        className={cx('transition-transform text-gray-700', {
          'rotate-0': !isOpened,
          'rotate-180': isOpened,
        })}
        onClick={onClick}
      >
        <Icon fontSize={18} icon={'bx:bx-chevron-right-circle'} />
      </span>
    </button>
  );
};

export type CardProps = Omit<CardHeaderProps, 'isOpened' | 'onClick'> & {
  defaultOpened?: boolean;
  withoutHeader?: boolean;
};

export const Card: React.FC<CardProps> = ({
  children,
  title,
  className,
  defaultOpened,
  onCheck,
  checked,
  defaultChecked,
  tooltip,
  subTitle,
  withCheckbox,
  withoutHeader,
}) => {
  const [isOpened, {}, {}, handleSwitch] = useModal(
    defaultOpened === undefined || defaultOpened ? true : false,
  );

  return (
    <div
      className={cx(
        'shadow-card rounded-lg', // border: ;
        'bg-white',
        className,
      )}
    >
      <If condition={!withoutHeader}>
        <CardHeader
          isOpened={isOpened}
          onClick={handleSwitch}
          title={title}
          subTitle={subTitle}
          tooltip={tooltip}
          checked={checked}
          defaultChecked={defaultChecked}
          onCheck={onCheck}
          withCheckbox={withCheckbox}
        />
      </If>
      <Collapse isOpened={isOpened}>
        <div
          className={cx('px-6 pt-4 pb-8', {
            // 'border border-solid border-gray-400 rounded-b-md': true,
          })}
        >
          {children}
        </div>
      </Collapse>
    </div>
  );
};

export default Card;
