import React from 'react';
import { api } from '~/api/api';
import { getByPath } from '~/utils/path';

const HELPER_TAG = 'useGenericRequest::';

export type UseGenericRequest = {
  currentStore: any;
};

export const useGenericRequest = ({ currentStore }: UseGenericRequest) => {
  const currentValue = React.useMemo(
    () => currentStore.value,
    [currentStore.value],
  );

  const getParams = (
    // pathKeys: 'user.address.city'
    pathKeys: string[],
    // mappedKeys: { MarketData: 'MarketData2' }
    mappedKeys?: { [key: string]: string },
  ) => {
    if (!pathKeys.length) {
      return undefined;
    }

    const valuesInStore: any = {};

    pathKeys.forEach((pathKey) => {
      const valueInStore = getByPath(currentValue, pathKey);

      const paths = pathKey.split('.');
      const key = paths[paths.length - 1];

      if (!!valueInStore && !valuesInStore.hasOwnProperty(key)) {
        valuesInStore[key] = valueInStore;
      }
    });

    const params: any = {};

    Object.keys(valuesInStore).forEach((keyFromStore) => {
      if (mappedKeys?.hasOwnProperty(keyFromStore)) {
        params[mappedKeys[keyFromStore]] =
          valuesInStore[keyFromStore].currentValue;
      } else {
        params[keyFromStore] = valuesInStore[keyFromStore].currentValue;
      }
    });

    if (!Object.keys(params).length) {
      return undefined;
    }

    return params;
  };

  const genericFetcher = async ({
    url,
    pathParamsKeys,
    mappedParamsKeys,
  }: {
    url: string;
    pathParamsKeys?: string[];
    mappedParamsKeys?: { [key: string]: string };
  }) => {
    const params = getParams(pathParamsKeys || [], mappedParamsKeys);

    try {
      const resp = await api.get(`${url}`, {
        params,
      });

      return resp.data;

      // return undefined;
    } catch (error) {
      console.error('genericFetcher::error', error);
      return null;
    }
  };

  return {
    genericFetcher,
  };
};
