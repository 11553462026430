import React from 'react';

type DisentLogoProps = {
  className?: string;
};

export const DisentLogo = ({ className }: DisentLogoProps) => {
  return (
    <svg
      height='148'
      viewBox='0 0 116 228'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M58.3111 0.225342C26.6058 0.225342 0.832794 25.9096 0.832794 57.5057C0.832794 83.5011 18.2892 105.48 42.132 112.467V227.225H71.9356V113.145C97.0841 107.036 115.789 84.4063 115.789 57.5057C115.789 25.9379 89.9881 0.225342 58.3111 0.225342ZM58.3111 85.0852C43.0403 85.0852 30.6364 72.7239 30.6364 57.5057C30.6364 42.2875 43.0403 29.9263 58.3111 29.9263C73.5819 29.9263 85.9859 42.2875 85.9859 57.5057C85.9859 72.7239 73.5535 85.0852 58.3111 85.0852Z'
        fill='white'
      />
    </svg>
  );
};
