export const getByPath = (
  object: any,
  path: string,
  defaultValue: any = null,
): any => {
  if (!Boolean(path)) {
    return defaultValue;
  }

  try {
    const parsedPath = path.split('.').filter((key) => key.length);

    const result = parsedPath.reduce((dive, key) => dive && dive[key], object);

    return result || defaultValue;
  } catch (error) {
    console.error('getByPath::path::object::', path, object);
    return defaultValue;
  }
};
