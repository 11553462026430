import React from 'react';
import cx from 'classnames';

import { WidgetInputType } from '~/widgets/commonTypes';
import { ChildRadio } from '~/models/SNBuilderType';
import { WrapperHideWidget } from '~/widgets/WrapperHideWidget';
import { useFnProps } from '~/hooks/useFnProps';

export type RadioWidgetProps = {
  data: ChildRadio;
} & WidgetInputType;

type StateProps = {
  disabled?: boolean;
  value?: boolean;
};

export const RadioWidget = ({
  data,
  ...widgetFieldProps
}: RadioWidgetProps) => {
  const { currentStore, sendValue } = widgetFieldProps;
  const field = React.useMemo(() => data.field, [data.field]);

  const currentFieldInStore = React.useMemo(
    () => currentStore.value[field.name],
    [currentStore.value, field],
  );

  const [state, setState] = React.useState<StateProps>({
    disabled: field.disabledDefault,
  });

  const { executeOn } = useFnProps({
    currentStore,
    sendValue,
    data,
  });

  React.useEffect(() => {
    if (!currentFieldInStore) {
      return;
    }

    if (Boolean(currentFieldInStore.disabled) !== state.disabled) {
      setState((prev) => ({ ...prev, disabled: currentFieldInStore.disabled }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFieldInStore]);

  React.useEffect(() => {
    executeOn('onMount');
  }, []);

  // React.useEffect(() => {
  //   sendValue({
  //     [field.name]: {
  //       currentValue: field.defaultValue,
  //       typeElement: data.typeElement,
  //       disabled: Boolean(field.disabledDefault),
  //     },
  //   });
  // }, [field]);

  const handleChange = (e: any) => {
    const newValue = field.id;

    sendValue({
      [field.name]: {
        currentValue: newValue,
      },
    });

    executeOn('onChange', { newValue });
  };

  const col = cx(data.className, 'flex align-items-center');

  return (
    <WrapperHideWidget {...data} {...widgetFieldProps}>
      <div className={col}>
        <label
          className="flex align-items-center"
          onClick={(e) => e.stopPropagation()}
          htmlFor={field.id}
        >
          <input
            type="radio"
            name={field.name}
            id={field.id}
            defaultChecked={field.defaultValue === field.id}
            value={field.id}
            onChange={handleChange}
            className="mr-2"
            disabled={state.disabled}
          />
          <span onClick={(e) => e.stopPropagation()}>{field.title}</span>
        </label>
      </div>
    </WrapperHideWidget>
  );
};

RadioWidget.defaultProps = {} as RadioWidgetProps;

export default RadioWidget;
