import cx from 'classnames';
import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import { InputFloatingLabel } from '~/components/InputFloatingLabel';

export type DatePickerProps = {} & ReactDatePickerProps;

const DatePicker = React.forwardRef<ReactDatePicker, DatePickerProps>(
  ({ className, placeholderText, ...props }, ref) => {
    const active = React.useMemo(() => !!props.selected, [props.selected]);

    const labelId = React.useRef<string>(Math.random().toString());

    return (
      <InputFloatingLabel
        id={labelId.current}
        active={active}
        label={placeholderText}
      >
        <ReactDatePicker
          ref={ref}
          className={cx(
            'border border-solid border-gray-500 rounded-md focus-within:border-primary', //border
            'text-sm', // font: ;
            'px-2', // spaces
            'h-9 outline-none ',
            className,
          )}
          todayButton="Today"
          dateFormatCalendar="MMMM"
          showYearDropdown
          peekNextMonth
          dropdownMode="select"
          {...props}
        />
      </InputFloatingLabel>
    );
  },
);

export default DatePicker;
