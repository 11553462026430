import React from 'react';
import ReactJson from 'react-json-view';
import constate from 'constate';
import { useSearchParams } from 'react-router-dom';

import Card from '~/components/Card';
import { If } from '~/components/If';
import { ScreenWidget } from '~/widgets';
import appWithSettings from '~/hocs/appSettings';

import withProvider from '~/hocs/withProvider';

import { useProviderFactory } from '~/hooks/useProviderFactory';

import initialObj from './INITIAL_OBJ.json';

const useUnderlyingOpt = () => useProviderFactory();

const [UnderlyingOptProvider, useUnderlyingOptState, useUnderlyingOptEffects] = constate(
  useUnderlyingOpt,
  (value) => value.state,
  (value) => value.effects,
);

const UnderlyingOptPage: React.FC = () => {
  const [searchParams] = useSearchParams();

  const { store } = useUnderlyingOptState();
  const { setValue } = useUnderlyingOptEffects();

  const hasStore =
    window.location.origin.includes('localhost') || !!searchParams.get('store');

    return (
    <>
      <ScreenWidget
        widgetFieldProps={{
          currentStore: store,
          sendValue: (payload) => setValue(payload),
          // @ts-ignore
          storeName: 'UnderlyingOpt',
        }}
        data={initialObj as any}
      />

      <If condition={hasStore}>
        <Card className="mb-3" title="Store" defaultOpened={false}>
          <ReactJson src={store} />
        </Card>
      </If>
    </>
  );
};

export const UnderlyingOpt = appWithSettings({
  title: 'Underlying Optimizer',
  description: '',
})(withProvider(UnderlyingOptProvider)(UnderlyingOptPage));
