import React from 'react';

type IfProps = {
  condition: unknown;
  children: any;
};

export const If = ({ condition, children }: IfProps) => {
  if (Boolean(condition)) {
    return children;
  }

  return null;
};

export default If;
