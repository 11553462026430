import React from 'react';
import { Switch } from '~/components/Switch';
import useFnProps from '~/hooks/useFnProps';
import { ChildSwitch } from '~/models/SNBuilderType';
import { WidgetInputType } from '~/widgets/commonTypes';
import { WrapperHideWidget } from '~/widgets/WrapperHideWidget';

export type SwitchWidgetProps = {
  data: ChildSwitch;
} & WidgetInputType;

const typeElement = 'switch';

export const SwitchWidget = ({
  data,
  ...widgetFieldProps
}: SwitchWidgetProps) => {
  const { currentStore, sendValue } = widgetFieldProps;

  const defaultValuesLoaded = React.useRef(false);

  const { leftField, rightField } = data;

  const hasCustomValue = React.useMemo(
    () => rightField.value !== undefined && leftField.value !== undefined,
    [leftField, rightField],
  );

  const currentLeftFieldInStore = React.useMemo(
    () => currentStore.value[leftField.name],
    [currentStore.value, leftField.name],
  );

  const currentRightFieldInStore = React.useMemo(
    () => currentStore.value[rightField.name],
    [currentStore.value, rightField.name],
  );

  const [checked, setChecked] = React.useState<boolean>(false);

  const { executeOn, getDefaultValuesByKeys } = useFnProps({
    currentStore,
    data,
    state: checked,
    setState: setChecked,
    sendValue,
  });

  const handleChange = React.useCallback(
    (value: boolean | string) => {
      let payload: any = {};

      let isChecked: boolean = false;

      if (hasCustomValue) {
        const leftValue = leftField.value;
        const rightValue = rightField.value;

        const isBoolean = typeof value === 'boolean';
        const isSameKey = leftField.name === rightField.name;

        if (isBoolean) {
          if (isSameKey) {
            payload[rightField.name] = {
              typeElement,
              currentValue: value ? rightValue : leftValue,
            };
          } else {
            payload[rightField.name] = {
              currentValue: value ? rightValue : undefined,
              typeElement,
            };
            payload[leftField.name] = {
              currentValue: value ? undefined : leftValue,
              typeElement,
            };
          }

          isChecked = Boolean(value);
        } else {
          const isTurnedOn = rightField.value === value;

          payload[rightField.name] = {
            currentValue: isTurnedOn ? rightValue : undefined,
            typeElement,
          };

          payload[leftField.name] = {
            currentValue: !isTurnedOn ? leftValue : undefined,
            typeElement,
          };

          isChecked = isTurnedOn;
        }
      } else {
        if (value) {
          payload[rightField.name] = { currentValue: true, typeElement };
          payload[leftField.name] = { currentValue: false, typeElement };
        } else {
          payload[rightField.name] = { currentValue: false, typeElement };
          payload[leftField.name] = { currentValue: true, typeElement };
        }

        isChecked = !!value;
      }

      setChecked(isChecked);
      sendValue(payload);
    },
    [leftField, rightField, sendValue, hasCustomValue],
  );

  React.useEffect(() => {
    if (
      defaultValuesLoaded.current ||
      currentLeftFieldInStore?.currentValue !== undefined ||
      currentRightFieldInStore?.currentValue !== undefined
    ) {
      return;
    }

    if (data.defaultValue === undefined) {
      return;
    }

    if (data.hideWhenUnchecked?.length) {
      let isHide = false;

      data.hideWhenUnchecked.forEach((key) => {
        if (!currentStore?.value?.[key].currentValue) {
          isHide = true;
        }
      });

      if (isHide) {
        return;
      }
    }

    defaultValuesLoaded.current = true;
    handleChange(data.defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLeftFieldInStore, currentRightFieldInStore, handleChange, data]);

  const checkChanges = React.useCallback(() => {
    if (
      !(
        currentRightFieldInStore?.currentValue !== undefined &&
        currentLeftFieldInStore?.currentValue !== undefined
      )
    ) {
      return;
    }

    if (!hasCustomValue) {
      const isChecked = Boolean(currentRightFieldInStore.currentValue);

      if (checked !== isChecked) {
        setChecked(isChecked);
      }

      return;
    }

    const isChecked = Boolean(
      currentRightFieldInStore.currentValue === rightField.value,
    );

    if (checked !== isChecked) {
      setChecked(isChecked);
    }
  }, [
    setChecked,
    checked,
    currentRightFieldInStore,
    currentLeftFieldInStore,
    hasCustomValue,
    rightField,
  ]);

  React.useEffect(() => {
    checkChanges();
  }, [currentLeftFieldInStore, currentRightFieldInStore, checkChanges]);

  React.useEffect(() => {
    executeOn('onMount');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useLayoutEffect(() => {
    return () => {
      const payloadCleanedFields = getDefaultValuesByKeys([
        rightField.name,
        leftField.name,
      ]);

      if (payloadCleanedFields) {
        sendValue(payloadCleanedFields);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightField, leftField]);

  return (
    <WrapperHideWidget {...data} {...widgetFieldProps}>
      <Switch
        labelLeft={leftField.label}
        labelRight={rightField.label}
        customLabelsLeft={leftField.customLabel}
        customLabelsRight={rightField.customLabel}
        checked={checked}
        onChange={handleChange}
      />
    </WrapperHideWidget>
  );
};
