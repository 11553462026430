import React from 'react';
import cx from 'classnames';
import { useDebouncedCallback } from 'use-debounce';

import { Dropdown, DropdownOption } from '~/components/Dropdown';

import { WidgetInputType } from '~/widgets/commonTypes';

import { ChildDropdown } from '~/models/SNBuilderType';

import { fetchDropdown } from '~/api/dropdown';
import { useListenedParams } from '~/hooks/useListenedParams';
import { useFnProps } from '~/hooks/useFnProps';
import { WrapperHideWidget } from '~/widgets/WrapperHideWidget';
import { api } from '~/api/api';
import { compareLists } from '~/utils/compare';
import { handleError } from '~/hooks/useFnProps/utils';

export type DropdownWidgetProps = {
  data: ChildDropdown;
} & WidgetInputType;

export const DropdownWidget = ({
  data,
  ...widgetFieldProps
}: DropdownWidgetProps) => {
  const defaultValueIsLoaded = React.useRef(false);
  const firstRequestDone = React.useRef(false);

  const { currentStore, sendValue } = widgetFieldProps;

  const { listenedParams } = useListenedParams({
    currentStore,
    listenedKeys: data.listenedKeys,
  });

  const field = React.useMemo(() => data.field, [data.field]);

  const currentFieldInStore = React.useMemo(
    () => currentStore.value[field.name],
    [currentStore.value, field],
  );

  const asyncConfig = React.useMemo(() => data.asyncConfig, [data.asyncConfig]);

  const updateChoicesOnStore = React.useCallback(
    (choices: DropdownOption[]) => {
      const isEqualChoices = compareLists(
        choices,
        currentFieldInStore?.choices,
      );

      if (!isEqualChoices) {
        sendValue({ [field.name]: { choices } });
      }
    },
    [sendValue, field, currentFieldInStore],
  );

  const choices = React.useMemo(() => {
    if (currentFieldInStore?.isLoading) {
      return [{ label: 'fetching...', value: 'fetching...' }];
    }

    if (data.choices) {
      updateChoicesOnStore(data.choices);

      return data.choices;
    }

    if (currentFieldInStore?.choices?.length) {
      return currentFieldInStore?.choices;
    }

    return [];
  }, [currentFieldInStore, data.choices, updateChoicesOnStore]);

  const setLoading = (bool: boolean) =>
    sendValue({ [field.name]: { isLoading: bool } });

  const { executeOn } = useFnProps({
    currentStore,
    data,
    sendValue,
  });

  const fetchData = async ({ params }: { params?: any }) => {
    try {
      executeOn('onStartRequest');

      setLoading(true);
      console.log('fetchData');
      const response = await fetchDropdown(data.url || '/', params);

      sendValue({
        [field.name]: {
          choices: response?.ResponseData?.underlyings || [],
          isLoading: false,
        },
      });

      executeOn('onSuccessRequest', { response });
    } catch (error) {
      console.error('fetchData', error);
      setLoading(false);
      handleError(error);
    }
  };

  const handleSearch = useDebouncedCallback(async (newValue: any) => {
    if (!asyncConfig) {
      return;
    }

    try {
      let params: any = {};

      if (asyncConfig.paramName) {
        params[asyncConfig.paramName] = newValue.toUpperCase?.();
      }

      if (asyncConfig.staticParams) {
        params = {
          ...params,
          ...asyncConfig.staticParams,
        };
      }

      setLoading(true);
      const { data } = await api.get(asyncConfig.url, {
        params,
      });

      const choices = data.map((item: any) => {
        return {
          value: item[asyncConfig.keyValue],
          label: item[asyncConfig.keyLabel],
        };
      });

      firstRequestDone.current = true;

      sendValue({ [field.name]: { choices, isLoading: false } });
    } catch (error) {
      sendValue({ [field.name]: { choices: [], isLoading: false } });
    }
  }, 200);

  const fetchDebounced = useDebouncedCallback(fetchData, data.delay || 500);

  React.useEffect(() => {
    if (data.url && Object.keys(listenedParams).length > 0) {
      fetchDebounced({ params: { ...listenedParams, ...data.staticParams } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listenedParams, data.url]);

  React.useEffect(() => {
    executeOn('onMount');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (defaultValueIsLoaded.current) {
      return;
    }

    if (!currentFieldInStore?.currentValue) {
      sendValue({
        [field.name]: {
          currentValue: field.defaultValue,
          typeElement: data.typeElement,
          disabled: Boolean(field.disabledDefault),
        },
      });
    } else {
      sendValue({
        [field.name]: {
          typeElement: data.typeElement,
        },
      });
    }

    defaultValueIsLoaded.current = true;
  }, [field, sendValue, defaultValueIsLoaded, currentFieldInStore, data]);

  const handleChange = (newValue: string) => {
    sendValue({
      [field.name]: {
        currentValue: newValue,
        typeElement: data.typeElement,
      },
    });

    executeOn('onSelect', { newValue });
  };

  return (
    <WrapperHideWidget {...data} {...widgetFieldProps}>
      <Dropdown
        options={choices}
        onInputChange={(newValue) => {
          if (!newValue && currentFieldInStore?.choices?.length) {
            return newValue;
          }

          if (
            !!asyncConfig?.url &&
            !!asyncConfig?.keyLabel &&
            !!asyncConfig?.keyValue
          ) {
            handleSearch(newValue);
          }

          return newValue;
        }}
        onFocus={() => {
          if (!firstRequestDone.current) {
            handleSearch('');
            firstRequestDone.current = true;
          }

          executeOn('onFocus');
        }}
        isLoading={Boolean(currentFieldInStore?.isLoading)}
        value={currentFieldInStore?.currentValue}
        placeholder={field.placeholder}
        onChange={handleChange}
        defaultValue={field.defaultValue}
        styles={data.style}
        className={cx(data.className)}
        {...data.props}
      />
    </WrapperHideWidget>
  );
};

DropdownWidget.defaultProps = {} as DropdownWidgetProps;

export default DropdownWidget;
