import React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from '~/App';

import { store, persistor } from '~/store';
// import { Head } from '~/components/Head';
// import { GtmScriptHead, GtmScriptBody } from '~/metrics';
import { AuthProvider } from './providers/AuthProvider';

// import 'bootstrap/dist/css/bootstrap.min.css';
import 'ag-grid-enterprise';

import 'react-datepicker/dist/react-datepicker.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'react-tabs/style/react-tabs.css';
import '~/styles/global.css';

// const GTM_ID = 'GTM-00000000';

ReactDOM.render(
  <React.StrictMode>
    {/* <Head>
      <GtmScriptHead gtmId={GTM_ID} />
    </Head> */}
    <AuthProvider>
      <App />
      {/* <PersistGate loading={null} persistor={persistor}>
        </PersistGate> */}
      {/* <GtmScriptBody gtmId={GTM_ID} /> */}
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
