import React from 'react'; 
import ReactJson from 'react-json-view';
import constate from 'constate';
import { ScreenWidget } from '~/widgets';
import { useSearchParams } from 'react-router-dom'; 
import appWithSettings from '~/hocs/appSettings';

import Card from '~/components/Card';
import If from '~/components/If';
import withProvider from '~/hocs/withProvider'; 
import { useProviderFactory } from '~/hooks/useProviderFactory';
import initialObj from './INITIAL_OBJ.json'; 


const usePortfolio = () => useProviderFactory(); 
const [PortfolioProvider, usePortfolioState, usePortfolioEffects] = 
  constate( 
    usePortfolio, 
    (value) => value.state, 
    (value) => value.effects, 
  ); 
export const PortfolioPage = () => { 
  const [searchParams] = useSearchParams(); 
  const { store } = usePortfolioState(); 
  const { setValue } = usePortfolioEffects();
  
  const hasStore = !!searchParams.get('store');
  return ( 
    <>
    <ScreenWidget 
      widgetFieldProps={{ 
        currentStore: store, 
        sendValue: (payload) => setValue(payload), 
      }} 
      data={initialObj as any}
      /> 

<If condition={window.location.origin.includes('localhost') || hasStore}>
        <Card className="mb-3" title="Store" defaultOpened={false}>
          <ReactJson src={store} />
        </Card>
      </If>
    </>
  ); 
}; 
export const Portfolio = appWithSettings({ 
  title: 'Portfolio Viewer', 
  description: '', 
})(withProvider(PortfolioProvider)(PortfolioPage));