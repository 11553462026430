import React from 'react';
import { useEffectsHeader } from '~/providers/HeaderProvider';

export type AppSettingsProps = {
  title: string;
  description: string;
};

function appWithSettings<P extends object>(appProps: AppSettingsProps) {
  return (Component: any) => {
    const displayName = Component.displayName || Component.name || 'Component';

    function ComponentWithSettings(props: P) {
      const { setHeader } = useEffectsHeader();

      React.useEffect(() => {
        const { title, description } = appProps;
        setHeader(title, description);

        if (appProps.title) {
          document.title = `Disent - ${title}`;
        }
      }, []);

      const componentProps = {
        ...props,
        ...appProps,
      };

      return (
        <>
          <Component {...componentProps} />
        </>
      );
    }

    ComponentWithSettings.displayName = `appWithSettings(${displayName})`;

    return ComponentWithSettings;
  };
}

export { appWithSettings };

export default appWithSettings;
