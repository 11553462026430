import React from 'react';
import Chooser from '~/components/Chooser';

import { Child } from '~/models/SNBuilderType';
import { WidgetInputType } from '~/widgets/commonTypes';
// import { DisentLoader } from '~/components/DisentLoader';

import { dateUtils } from '~/utils/date';

export type ScreenWidgetProps = {
  data: Child;
  widgetFieldProps: WidgetInputType;
};

export const ScreenWidget = ({ data, widgetFieldProps }: ScreenWidgetProps) => {
  const { sendValue } = widgetFieldProps;

  const [defaultValues, setDefaultValues] = React.useState<any>({});

  const [defaultValuesFillOut, setDefaultValuesFillOut] = React.useState(false);

  const defaultValuesDefined = React.useRef(false);

  const getAllDefaultValues = (
    dataValue: any,
    sendValue: (payload: any) => void,
  ): any => {
    let hasDefaultValue = dataValue?.field?.defaultValue !== undefined;

    if (hasDefaultValue) {
      let keyField = dataValue?.field?.name;

      let defaultValue = dataValue?.field?.defaultValue;

      switch (dataValue?.field?.defaultValue) {
        case 'today':
          defaultValue = new Date();
          break;
        case '1y':
          defaultValue = dateUtils.addYears(new Date(), 1);
          break;
        default:
          break;
      }

      if (keyField) {
        setDefaultValues((prev: any) => ({
          ...prev,
          [keyField]: {
            currentValue: defaultValue,
            typeElement: dataValue?.typeElement,
          },
        }));
      }
    }

    if (dataValue?.tabs?.length) {
      dataValue.tabs.forEach((tab: any) => {
        tab.children?.forEach((child: any) => {
          return getAllDefaultValues(child, sendValue);
        });
      });
    }

    if (dataValue?.children && dataValue?.children.length) {
      dataValue.children.forEach((child: any) => {
        return getAllDefaultValues(child, sendValue);
      });
    }

    if (defaultValuesDefined.current) {
      return;
    }
  };

  React.useLayoutEffect(() => {
    getAllDefaultValues(data, widgetFieldProps.sendValue);
  }, []);

  React.useEffect(() => {
    if (defaultValuesDefined.current) {
      return;
    }

    const setValues = (values: any) => {
      sendValue(values);

      defaultValuesDefined.current = true;
      setTimeout(() => setDefaultValuesFillOut(true), 400);
    };

    let debouncer = setTimeout(() => {
      setValues(defaultValues);
    }, 500);

    return () => {
      clearTimeout(debouncer);
    };
  }, [defaultValues, defaultValuesDefined, setDefaultValuesFillOut, sendValue]);

  if (!defaultValuesFillOut) {
    return (
      <div className="flex justify-center w-full h-96 items-center">
        {/* <DisentLoader width={250} /> */}
      </div>
    );
  }

  return (
    <div {...data?.props}>
      {data?.children?.map((child, idx) => (
        <Chooser
          widgetFieldProps={widgetFieldProps}
          key={`child-screen-${idx}`}
          data={child}
        />
      ))}
    </div>
  );
};
