import React from 'react';
import ReactJson from 'react-json-view';
import constate from 'constate';

import { useSearchParams } from 'react-router-dom';

import If from '~/components/If';
import { Card } from '~/components/Card';
import { ScreenWidget } from '~/widgets';

import objectJson from './INITIAL_OBJ.json';
import withProvider from '~/hocs/withProvider';
import appWithSettings from '~/hocs/appSettings';

import { useProviderFactory } from '~/hooks/useProviderFactory';

const useSecondary = () => useProviderFactory();

const [SecondaryProvider, useSecondaryState, useSecondaryEffects] = constate(
  useSecondary,
  (value) => value.state,
  (value) => value.effects,
);

const SecondaryPage = () => {
  const { setValue } = useSecondaryEffects();
  const { store: currentStore } = useSecondaryState();

  const [searchParams] = useSearchParams();

  const hasStore = !!searchParams.get('store');

  return (
    <div>
      <ScreenWidget
        widgetFieldProps={{
          currentStore,
          sendValue: (payload) => setValue(payload),
        }}
        data={objectJson as any}
      />

      <If condition={window.location.origin.includes('localhost') || hasStore}>
        <Card className="mb-3" title="Store" defaultOpened={false}>
          <ReactJson src={currentStore} />
        </Card>
      </If>
    </div>
  );
};

export const Secondary = appWithSettings({
  title: 'Issued Notes',
  description: '',
})(withProvider(SecondaryProvider)(SecondaryPage));
