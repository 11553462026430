import { addYears, addMonths, addDays, format } from 'date-fns';

const convertToDate = (value: string | number): Date | null => {
  try {
    if (value.toString().length !== 8) {
      return null;
    }

    const yyyy = Number(value?.toString().slice(0, 4));
    const mm = Number(value?.toString().slice(4, 6)) - 1;
    const dd = Number(value?.toString().slice(6, 8));

    const date = new Date(yyyy, mm, dd);

    return date;
  } catch (error) {
    return null;
  }
};

export const dateUtils = {
  addYears,
  addMonths,
  addDays,
  format,
  convertToDate,
};
