import React from 'react';

import { ChildPlot3D } from '~/models/SNBuilderType';
import { WidgetInputType } from '../commonTypes';
import { WrapperHideWidget } from '~/widgets/WrapperHideWidget';
import { Plot3D, parseData3DPlot } from '~/components/Plot3D';
// import { Plot3D } from '~/components/Plot3D';
import useFnProps from '~/hooks/useFnProps';
import { useListenedParams } from '~/hooks/useListenedParams';
import { api } from '~/api/api';
import { useDebouncedCallback } from 'use-debounce';
import { getByPath } from '~/utils/path';

export type Plot3DWidgetProps = {
  data: ChildPlot3D;
  widgetFieldProps: WidgetInputType;
};

export const Plot3DWidget = ({ data, widgetFieldProps }: Plot3DWidgetProps) => {
  const { currentStore, sendValue } = widgetFieldProps;
  const field = React.useMemo(() => data.field, [data.field]);

  const [loading, setLoading] = React.useState(false);

  const [state, setState] = React.useState<{ data: any[] }>({
    data: [],
  });

  const { listenedParams } = useListenedParams({
    currentStore,
    listenedKeys: data.listenedKeys,
  });

  const currentFieldInStore = React.useMemo(
    () => currentStore.value[field.name],
    [currentStore.value, field],
  );

  const { executeOn } = useFnProps({
    currentStore,
    data,
    sendValue,
  });

  const fetchData = async ({ params }: { params?: any }) => {
    executeOn('onStartRequest');

    try {
      setLoading(true);

      const response = await api.get(data.url, { params });

      const dataResponse = getByPath(response.data, data.pathData);

      // console.log('wfp', widgetFieldProps);
      let choice =
        widgetFieldProps.currentStore.value.source.currentValue.value;
      // console.log('choice', choice);
      let x = '';
      let y = '';
      let z = '';
      if (choice == 'VOLS_ORATS') {
        x = 'K';
        y = 'T_DATE';
        z = 'IV';
      } else {
        x = 'MNY';
        // y = 'DT';
        y = 'T';
        z = 'IV';
      }

      const dataValues = parseData3DPlot(dataResponse, x, y, z);

      // console.log(dataValues);

      // console.log('DATALOG', dataValues);
      setState({ data: dataValues });

      setLoading(false);

      executeOn('onSuccessRequest', { response });
    } catch (error) {
      console.error('fetchData', error);
      setLoading(false);
      setState({ data: [] });
    }
  };

  const fetchDebounced = useDebouncedCallback(fetchData, data.delay || 100);

  React.useEffect(() => {
    const hasKeys = !!Object.keys(listenedParams).length;
    // let obs = 'Observation';
    //FIX THIS to multiple choices
    // const userDataChoice = Object.values(listenedParams).includes(obs)

    if (hasKeys) {
      let params = { ...listenedParams };

      // if (userDataChoice) {
      //   params = { ...params } ;
      // }
      if (data?.staticParams) {
        params = { ...params, ...data?.staticParams };
      }

      fetchDebounced({ params });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listenedParams]);

  React.useEffect(() => {
    executeOn('onMount');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WrapperHideWidget {...data} {...widgetFieldProps}>
      <Plot3D isLoading={loading} data={state.data} {...data.props} />
    </WrapperHideWidget>
  );
};
