import React from 'react';

type UseModalResult = [boolean, () => void, () => void, () => void];

export const useModal = (defaultValue?: boolean): UseModalResult => {
  const [modalIsOpened, setModalIsOpened] = React.useState<boolean>(
    Boolean(defaultValue),
  );

  const handleOpen = React.useCallback(
    () => setModalIsOpened(true),
    [setModalIsOpened],
  );

  const handleClose = React.useCallback(
    () => setModalIsOpened(false),
    [setModalIsOpened],
  );

  const handleSwitch = React.useCallback(
    () => setModalIsOpened((prev) => !prev),
    [setModalIsOpened],
  );

  return [modalIsOpened, handleClose, handleOpen, handleSwitch];
};
