import React from 'react';
import cx from 'classnames';

import { If } from '~/components/If';

type HeaderProps = {
  title: string;
};

const Header = ({ title }: HeaderProps) => {
  return (
    <>
      <div
        className={cx(
          'py-4 px-5', // Spaces
        )}
      >
        <h4 className="text-xl text-gray-900">{title}</h4>
      </div>

      <hr className="border-gray-400" />
    </>
  );
};

export type SimpleCardProps = {
  title?: string;
  className?: string;
};

export const SimpleCard: React.FC<SimpleCardProps> = ({
  children,
  title,
  className,
}) => {
  return (
    <div
      className={cx(
        'shadow-card rounded-lg', // Borders
        className,
      )}
    >
      <If condition={title}>
        <Header title={title as string} />
      </If>
      <div
        className={cx(
          'py-4 px-5', // Spaces
        )}
      >
        {children}
      </div>
    </div>
  );
};
