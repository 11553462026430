import React, { TextareaHTMLAttributes } from 'react';

import cx from 'classnames';

export type TextProps = TextareaHTMLAttributes<HTMLParagraphElement> & {
  size?: 'sm' | 'md' | 'lg';
  tag?: string;
  className?: string;
  bold?: boolean;
};

export const Text: React.FC<TextProps> = ({
  children,
  size,
  tag,
  className,
  bold,
  ...props
}) => {
  const Tag: any = tag || 'span';

  return (
    <Tag
      className={cx(
        {
          'text-sm': size === 'sm',
          'text-base': size === 'md',
          'text-lg': size === 'lg',
          'font-bold': bold,
        },
        className, //
      )}
      {...props}
    >
      {children}
    </Tag>
  );
};

Text.defaultProps = {
  tag: 'span',
} as TextProps;
