import React from 'react';
import { ButtonBar } from '~/components/ButtonBar';
import { TypeElement } from '~/components/Chooser/getComponent';
import useFnProps from '~/hooks/useFnProps';
import { ChildButtonBar } from '~/models/SNBuilderType';
import { WidgetInputType } from '~/widgets/commonTypes';
import { WrapperHideWidget } from '~/widgets/WrapperHideWidget';

export type ButtonBarWidgetProps = {
  data: ChildButtonBar;
  widgetFieldProps: WidgetInputType;
};

const typeElement = 'button-bar';

export const ButtonBarWidget = ({
  data,
  widgetFieldProps,
}: ButtonBarWidgetProps) => {
  const defaultValueIsLoaded = React.useRef(false);
  const { currentStore, sendValue } = widgetFieldProps;
  const field = React.useMemo(() => data.field, [data.field]);

  const currentFieldInStore: {
    currentValue?: string;
    typeElement: TypeElement;
  } = React.useMemo(
    () => currentStore.value[field.name],
    [currentStore.value, field],
  );

  const { executeOn } = useFnProps({
    currentStore,
    sendValue,
    data,
  });

  React.useEffect(() => {
    executeOn('onMount');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (currentFieldInStore?.currentValue !== undefined) {
      defaultValueIsLoaded.current = true;
      return;
    }

    if (defaultValueIsLoaded.current || !field.defaultValue) {
      return;
    }

    executeOn('onChange', { newValue: field.defaultValue });

    sendValue({
      [field.name]: {
        currentValue: field.defaultValue,
        typeElement,
      },
    });

    defaultValueIsLoaded.current = true;
  }, [field, defaultValueIsLoaded, currentFieldInStore, sendValue, executeOn]);

  const handleChange = (newValue: string) => {
    sendValue({ [field.name]: { currentValue: newValue, typeElement } });

    executeOn('onChange', { newValue });
  };

  return (
    <WrapperHideWidget {...widgetFieldProps} {...data}>
      <ButtonBar
        options={data.choices}
        onChange={handleChange}
        selected={currentFieldInStore?.currentValue}
        label={field.label}
        {...data.props}
      />
    </WrapperHideWidget>
  );
};
