import React from 'react';
import { ChildTab } from '~/models/SNBuilderType';
import { WidgetInputType } from '~/widgets/commonTypes';

import { Tabs, TabsProps } from '~/components/Tabs';
import Chooser from '~/components/Chooser';
import { WrapperHideWidget } from '~/widgets/WrapperHideWidget';

export type TabWidgetProps = {
  data: ChildTab;
  widgetFieldProps: WidgetInputType;
};

export const TabWidget = ({ data, widgetFieldProps }: TabWidgetProps) => {
  const [selectedId, setSelectedId] = React.useState('');

  const selectedIndex = React.useMemo(
    () => data.tabs.findIndex((tab) => tab.id === selectedId),
    [selectedId, data],
  );

  const { sendValue, currentStore } = widgetFieldProps;

  const field = React.useMemo(() => data.field, [data.field]);

  const currentFieldInStore = React.useMemo(
    () => currentStore.value[field.name],
    [currentStore.value, field],
  );

  const tabs: TabsProps['tabs'] = React.useMemo(() => {
    return data.tabs.map((tab) => ({
      title: tab.title,
      id: tab.id,
      isLoading: Boolean(currentFieldInStore?.[tab.id]?.isLoading),
      content: (
        <>
          {tab.children.map((child, idx) => (
            <Chooser
              key={`child-tab-${idx}`}
              widgetFieldProps={widgetFieldProps}
              data={child}
            />
          ))}
        </>
      ),
    })) as TabsProps['tabs'];
  }, [data.tabs, widgetFieldProps, currentFieldInStore]);

  const handleChangeTab = (tabIndex: number = 0) => {
    const id = data.tabs[tabIndex].id;

    setSelectedId(id);
    sendValue({
      [field.name]: { currentValue: id },
    });
  };

  React.useEffect(() => {
    if (!currentFieldInStore) {
      return;
    }

    if (currentFieldInStore.currentValue !== selectedId) {
      setSelectedId(currentFieldInStore.currentValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFieldInStore]);

  React.useEffect(() => {
    sendValue({ [field.name]: { currentValue: data.tabs[0].id } });
  }, []);

  return (
    <WrapperHideWidget {...data} {...widgetFieldProps}>
      <Tabs
        tabs={tabs}
        onSelect={(index) => handleChangeTab(index)}
        selectedIndex={selectedIndex}
        {...data.props}
      />
    </WrapperHideWidget>
  );
};
