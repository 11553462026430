import React from 'react';

export type UseListenedKeysToDisabledProps = {
  currentStore: {
    value: any;
  };
  listenedKeys?: string[];
  inverted?: boolean;
};

export const useListenedKeysToDisabled = ({
  currentStore,
  listenedKeys,
  inverted,
}: UseListenedKeysToDisabledProps): { disabledByKeys: boolean } => {
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const currentValue = React.useMemo(
    () => currentStore.value,
    [currentStore.value],
  );

  React.useEffect(() => {
    const booleanReference = !inverted;

    if (!listenedKeys?.length) {
      setDisabled((prev) => {
        const newValue = booleanReference;

        if (prev !== newValue) {
          return newValue;
        }

        return prev;
      });

      return;
    }

    const keysInStore = (currentValue ? Object.keys(currentValue) : []).filter(
      (key) => listenedKeys?.includes(key),
    );

    const objectsOnStore = keysInStore.map((key) => currentValue[key]);

    const isDisabledOnStore = !!objectsOnStore
      .filter((item) => !!item)
      .filter(
        (obj) =>
          obj?.checked === booleanReference ||
          obj?.currentValue === booleanReference,
      ).length;

    setDisabled((prev) => {
      const newValue = Boolean(isDisabledOnStore);

      if (prev !== newValue) {
        return newValue;
      }

      return prev;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue, listenedKeys, inverted]);

  // console.log('disabledByKeys::', disabled);

  return { disabledByKeys: disabled };
};
