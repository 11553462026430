import React from 'react';
import { Child } from '~/models/SNBuilderType';
import { WidgetInputType } from '~/widgets/commonTypes';

import { getComponent } from './getComponent';

export type ChooserProps = {
  data: {
    typeElement: Child['typeElement'];
  } & any;
  widgetFieldProps: WidgetInputType;
};

const Chooser: React.FC<ChooserProps> = ({ data, widgetFieldProps }) => {
  return getComponent({
    name: data.typeElement,
    data,
    widgetFieldProps,
  });
};

export default Chooser;
