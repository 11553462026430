import React, { InputHTMLAttributes } from 'react';
import cx from 'classnames';

import { Icon } from '@iconify/react';
import { If } from '~/components/If';

import { InputFloatingLabel } from '~/components/InputFloatingLabel';

import { getInputStyles } from './utils';

import styles from './styles.module.css';

export type PercentInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  classNameContainer?: string;
  onSetValue: (value: number | undefined) => void;
};

export const PercentInput = React.forwardRef<
  HTMLInputElement,
  PercentInputProps
>(
  (
    {
      className,
      placeholder,
      label,
      classNameContainer,
      defaultValue,
      onChange,
      onSetValue,
      ...props
    },
    ref,
  ) => {
    const [input, setInput] = React.useState<any>();
    const labelId = React.useRef<string>(Math.random().toString());

    const active = React.useMemo(
      () => !!props.value || !!defaultValue,
      [props.value, defaultValue],
    );

    React.useEffect(() => {
      if (!defaultValue) {
        setInput('');
      } else {
        setInput(Number((Number(defaultValue) * 100).toFixed(2)));
      }
    }, [defaultValue, setInput]);

    return (
      <InputFloatingLabel
        id={labelId.current}
        label={label || placeholder}
        classNameContainer={classNameContainer}
        active={active}
      >
        <input
          ref={ref}
          className={getInputStyles(cx(styles.input, className))}
          {...props}
          value={input}
          onChange={(e) => {
            const { value } = e.target;

            setInput(value);

            onSetValue(
              Number(
                (
                  Number(value.endsWith('.') ? value.replace('.', '') : value) /
                  100
                ).toFixed(4),
              ),
            );
          }}
          // value={value}
          // defaultValue={defaultValue}
        />

        {/* <If condition={!!placeholder || !!label}>
          <label
            className={cx({ [styles.active]: active || !!props.value })}
            htmlFor={labelId.current}
          >
            {placeholder || label}
          </label>
        </If> */}

        <If condition={props.type === 'number'}>
          <div className="absolute right-1 top-1 flex flex-col items-center gap-1 text-gray-500">
            <button type="button">
              <Icon fontSize={12} icon="akar-icons:circle-chevron-up" />
            </button>

            <button type="button">
              <Icon fontSize={12} icon="akar-icons:circle-chevron-down" />
            </button>
          </div>
        </If>
      </InputFloatingLabel>
    );
  },
);

PercentInput.defaultProps = {
  type: 'number',
} as PercentInputProps;

export default PercentInput;
