import React from 'react';
import cx from 'classnames';

import SwitchUi, { ReactSwitchProps } from 'react-switch';
import { If } from '~/components/If';
import { DEFAULT_THEME } from '~/constants/theme';

export type CustomSwitchLabel = {
  labelOn: string;
  labelOff: string;
};

export type SwitchProps = ReactSwitchProps & {
  labelLeft?: string;
  customLabelsLeft?: CustomSwitchLabel;
  labelRight?: string;
  customLabelsRight?: CustomSwitchLabel;
  classNameContainer?: string;
};

const LABEL_SELECT_STYLE = 'text-base font-medium';
const LABEL_NOT_SELECT_STYLE = 'text-base font-normal';

export const Switch = ({
  labelLeft,
  customLabelsLeft,
  customLabelsRight,
  labelRight,
  ...props
}: SwitchProps) => {
  return (
    <div className={cx('flex items-center gap-2')}>
      <If condition={labelLeft || customLabelsLeft}>
        <span
          className={cx('text-xs', {
            [LABEL_SELECT_STYLE]: !props.checked,
            [LABEL_NOT_SELECT_STYLE]: props.checked,
          })}
          role="button"
          onClick={(e: any) => props.onChange?.(false, e, labelLeft as string)}
        >
          {customLabelsLeft ? (
            <>
              {props.checked
                ? customLabelsLeft.labelOff
                : customLabelsLeft.labelOn}
            </>
          ) : (
            labelLeft
          )}
        </span>
      </If>

      <SwitchUi
        onColor={DEFAULT_THEME.colors.primary}
        offColor="#cecece"
        onHandleColor="#000000"
        offHandleColor="#000000"
        checkedIcon={false}
        uncheckedIcon={false}
        height={18}
        width={50}
        draggable
        {...props}
      />

      <If condition={labelRight || customLabelsRight}>
        <span
          className={cx('text-xs text-right', {
            [LABEL_NOT_SELECT_STYLE]: !props.checked,
            [LABEL_SELECT_STYLE]: props.checked,
          })}
          role="button"
          onClick={(e: any) => props.onChange?.(true, e, labelLeft as string)}
        >
          {customLabelsRight ? (
            <>
              {!props.checked
                ? customLabelsRight.labelOff
                : customLabelsRight.labelOn}
            </>
          ) : (
            labelRight
          )}
        </span>
      </If>
    </div>
  );
};
