import React from 'react';

import { ChildTitle } from 'src/models/SNBuilderType';
import { WidgetInputType } from '~/widgets/commonTypes';
import { WrapperHideWidget } from '~/widgets/WrapperHideWidget';
import { useFnProps } from '~/hooks/useFnProps';
import { Title } from '~/components/Title';

export type TitleWidgetProps = {
  data: ChildTitle;
} & WidgetInputType;

export const TitleWidget = ({
  data,
  ...widgetFieldProps
}: TitleWidgetProps) => {
  const { currentStore, sendValue } = widgetFieldProps;
  const { description, style } = data;

  const field = React.useMemo(() => data.field, [data.field]);

  const currentFieldInStore = React.useMemo(
    () => currentStore.value[field.name],
    [currentStore.value, field],
  );

  const { executeOn } = useFnProps({
    currentStore,
    sendValue,
    data,
  });

  const handleClick = () => {
    executeOn('onClick');
  };

  const handleDoubleClick = () => {
    executeOn('onDoubleClick');
  };

  return (
    <WrapperHideWidget {...data} {...widgetFieldProps}>
      <Title
        style={style}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        {...data.props}
        title={currentFieldInStore?.currentValue || field.defaultValue}
        description={currentFieldInStore?.description || description}
      />
    </WrapperHideWidget>
  );
};

TitleWidget.defaultProps = {} as TitleWidgetProps;
