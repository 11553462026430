import React, { InputHTMLAttributes } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

export type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, children, className, ...props }, ref) => {
    return (
      <label className={cx('flex items-center gap-2 text-xs', className)}>
        <input
          ref={ref}
          className={cx(styles.checkbox, 'text-white')}
          type="checkbox"
          {...props}
        />
        {children || label}
      </label>
    );
  },
);

// Checkbox.defaultProps = {} as CheckboxProps;

export default Checkbox;
