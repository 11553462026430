import axios from 'axios';
import Cookies from 'js-cookie';

import { BASE_URL, API_KEY } from '~/config/variables';
import {
  ACCESS_TOKEN_KEY,
  CSRF_TOKEN_KEY,
  SESSION_ID_KEY,
} from '~/constants/keys';
import { fetchRefreshToken } from './auth/auth';

export const api = axios.create({
  baseURL: BASE_URL, //'http://localhost:5000',
  withCredentials: true,

  headers: {
    Authorization: `Api-Key ${API_KEY}`,
    'Content-Type': 'application/json; char-set=utf-8',
    Accept: 'application/json',
    Cookie: `csrftoken=${Cookies.get(CSRF_TOKEN_KEY)}; sessionid=${Cookies.get(
      SESSION_ID_KEY
    )}`,
  },
});

// api.interceptors.request.use((config) => {
//   if (Cookies.get(ACCESS_TOKEN_KEY)) {
//     config.headers.Authorization = `Bearer ${Cookies.get(ACCESS_TOKEN_KEY)}`;
//   }

//   return config;
// });

api.interceptors.response.use(undefined, async (error) => {
  const originaRequest = error.config;

  if (error.response?.status === 401 && !originaRequest._retry) {
    originaRequest._retry = true;

    const { access } = await fetchRefreshToken();

    if (!access) {
      return Promise.reject(error);
    }

    Cookies.set(ACCESS_TOKEN_KEY, access);

    axios.defaults.headers.common['Authorization'] = `Bearer ${access}`;

    return api(originaRequest);
  }

  return Promise.reject(error);
});
